
import React, { useState, useEffect } from "react";
import sideGridRightImage from "../assets/images/sideGridRight.webp";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import search from "../assets/jsons/Telugu/search_json";


// Import your JSON data


const SearchUI = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("q");
    if (query) {
      setSearchTerm(query);
      fetchResults(query);
    }
  }, [location.search]);

  const fetchResults = async (query) => {
    setLoading(true);
    try {
      // Use imported data directly (this would replace the API call)
      const data = search
      setResults(data.results || []); // Adjust based on your JSON structure
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredResults = results.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ fontFamily: "Arial, sans-serif", backgroundColor: "rgb(255, 230, 208)" }}>
      <Header />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* Left Side Image */}
        <div
          className="d-none d-lg-block"
          style={{
            background: `url(${sideGridRightImage})`,
            backgroundRepeat: "repeat-y",
            width: "100px",
            transform: "rotateY(180deg)",
          }}
        ></div>

        {/* Center Content */}
        <div style={{ width: "100%", padding: "20px" }}>
          {/* Search Bar */}
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: "60%",
                padding: "10px",
                borderRadius: "5px 0 0 5px",
                border: "1px solid #ccc",
                outline: "none",
              }}
            />
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#6A9B6F",
                color: "white",
                border: "none",
                borderRadius: "0 5px 5px 0",
                cursor: "pointer",
              }}
            >
              Search
            </button>
          </div>

          {/* Search Results */}
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div style={{ backgroundColor: "#FFDDAA", padding: "10px", borderRadius: "5px" }}>
              <p style={{ fontSize: "12px", margin: "0 0 10px" }}>
                About {filteredResults.length} results (0.12 seconds)
              </p>
              {filteredResults.map((result, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "15px",
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                  }}
                >
                  <a
                    href={result.url}
                    style={{ fontWeight: "bold", color: "#9B1C1F", textDecoration: "none" }}
                  >
                    {result.title}
                  </a>
                  <p style={{ fontSize: "12px", color: "#6A6A6A" }}>{result.description}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Right Side Image */}
        <div
          className="d-none d-lg-block"
          style={{
            background: `url(${sideGridRightImage})`,
            backgroundRepeat: "repeat-y",
            width: "100px",
          }}
        ></div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchUI;

