import React, { useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import festivaldetailsData from '../../assets/jsons/Telugu/festivalDetails';

const Pandugavisistatha = () => {
  // Filtered categories
  const filteredCategories = [
    'dasaradescription',
    'vinayakavratam',
    'Mahasivaratri',
    'Sankranthi',
    'Holi',
    'Krishnastami',
    'Deepavali',
    'Rathayatra',
  ].reduce((acc, key) => {
    if (festivaldetailsData[key]) {
      acc[key] = festivaldetailsData[key];
    }
    return acc;
  }, {});

  // Initialize with the first category from filteredCategories
  const defaultCategoryKey = Object.keys(filteredCategories)[0];
  const [activeCategory, setActiveCategory] = useState(filteredCategories[defaultCategoryKey]);

  const handleToggle = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const renderCatIDList = (category) => {
    if (Array.isArray(category)) {
      return category.map((item, index) => (
        <div key={index}>
          <div>{item.title}</div>
          <div>{item.measure}</div>
        </div>
      ));
    } else if (category && category.CatID) {
      return category.CatID.map((item, index) => (
        <div key={index}>
          <div>{item.title}</div>
          <div>{item.measure}</div>
        </div>
      ));
    }
    return null;
  };

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3">
            <div className="row">
              <div id="accordion">
                {Object.keys(filteredCategories).map((key, index) => (
                  <div
                    key={index}
                    id={`heading${index}`}
                    onClick={() => handleToggle(filteredCategories[key])}
                    className="card-header card mb-3"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: activeCategory === filteredCategories[key] ? '#B35C0C' : '#754213'
                    }}
                  >
                    <h5 className="mb-0">
                      <button
                        className="btn btn"
                        aria-expanded={activeCategory === filteredCategories[key]}
                        aria-controls={`collapse${index}`}
                        style={{ color: '#FFFFFF' }}
                      >
                        {Array.isArray(filteredCategories[key]) ? key : filteredCategories[key].title}
                      </button>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3">
            {Object.keys(filteredCategories).map((key, index) => (
              <div
                key={index}
                id={`collapse${index}`}
                className={`collapse ${activeCategory === filteredCategories[key] ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-parent="#accordion"
              >
                <div className="card-body rightPanel">
                  <h5>{Array.isArray(filteredCategories[key]) ? key : filteredCategories[key].title}</h5>
                  <ul>{renderCatIDList(filteredCategories[key])}</ul>
                  <br />
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pandugavisistatha;


// import React, { useState } from 'react';
// import Header from '../header';
// import Navbar from '../navbar';
// import Footer from '../footer';
// import festivaldetailsData from '../../assets/jsons/Telugu/festivalDetails';

// const Pandugavisistatha = () => {
//   // Filtered categories
//   const filteredCategories = [
//     'దసరా వివరణ',
//     'vinayakavratam',
//     'Mahasivaratri',
//     'Sankranthi',
//     'Holi',
//     'Krishnastami',
//     'Deepavali',
//     'Rathayatra',
//   ].reduce((acc, key) => {
//     if (festivaldetailsData[key]) {
//       acc[key] = festivaldetailsData[key];
//     }
//     return acc;
//   }, {});

//   // Initialize with the first category from filteredCategories
//   const defaultCategoryKey = Object.keys(filteredCategories)[0];
//   const [activeCategory, setActiveCategory] = useState(filteredCategories[defaultCategoryKey]);

//   const handleToggle = (category) => {
//     setActiveCategory(activeCategory === category ? null : category);
//   };

//   const renderCatIDList = (category) => {
//     if (Array.isArray(category)) {
//       return category.map((item, index) => (
//         <div key={index}>
//           <div>{item.title}</div>
//         </div>
//       ));
//     } else if (category && Array.isArray(category.CatID)) {
//       return category.CatID.map((item, index) => (
//         <div key={index}>
//           <div>{item.title}</div>
//         </div>
//       ));
//     }
//     return null;
//   };
//   const [leftSticky, setLeftSticky] = useState(true);
//   const [rightSticky, setRightSticky] = useState(true);

//   return (
//     <div>
//       <Header />
//       <Navbar />
//       <div className="container-fluid bgColor">
//         <div className="row">
//           <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
//           <div
//             className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
//             onMouseEnter={() => {
//               setLeftSticky(false);
//               setRightSticky(true);
//             }}
          
//           >
//             <div className="row">
//               <div id="accordion"
//                 style={{
//                   overflowY: leftSticky ? 'scroll' : 'auto',
//                   height: '80vh',
//                   position: leftSticky ? 'sticky' : 'sticky',
//                   top: 0,
//                 }}>
//                 {Object.keys(filteredCategories).map((key, index) => (
//                   <div
//                     key={index}
//                     id={`heading${index}`}
//                     onClick={() => handleToggle(filteredCategories[key])}
//                     className="card-header card mb-3"
//                     style={{
//                       color: "#FFFFFF",
//                       backgroundColor: activeCategory === filteredCategories[key] ? '#B35C0C' : '#754213'
//                     }}
//                   >
//                     <h5 className="mb-0">
//                       <button
//                         className="btn btn"
//                         aria-expanded={activeCategory === filteredCategories[key]}
//                         aria-controls={`collapse${index}`}
//                         style={{ color: '#FFFFFF' }}
//                       >
//                         {Array.isArray(filteredCategories[key]) ? key : filteredCategories[key].title}
//                       </button>
//                     </h5>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//           <div
//             className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
//             onMouseEnter={() => {
//               setLeftSticky(true);
//               setRightSticky(false);
//             }}
          
//           >
//             {Object.keys(filteredCategories).map((key, index) => (
//               <div
//               style={{
//                 overflowY: rightSticky ? 'scroll' : 'auto',
//                 height: '80vh',
//                 position: rightSticky ? 'sticky' : 'sticky',
//                 top: 0,
//             }}
//                 key={index}
//                 id={`collapse${index}`}
//                 className={`collapse ${activeCategory === filteredCategories[key] ? 'show' : ''}`}
//                 aria-labelledby={`heading${index}`}
//                 data-parent="#accordion"
//               >
//                 <div className="card-body rightPanel">
//                   <h5>{Array.isArray(filteredCategories[key]) ? key : filteredCategories[key].title}</h5>
//                   <ul>{renderCatIDList(filteredCategories[key])}</ul>
//                   <br />
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Pandugavisistatha;
