import React, { useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Thirdayathra = () => {
  const [activeKey, setActiveKey] = useState(0);

  const handleToggle = (index) => {
    setActiveKey(activeKey === index ? null : index);
  };
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage" ></div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
         
        >
            <div className="row">
              <div id="accordion"  style={{
            overflowY: leftSticky ? 'scroll' : 'auto',
            height: '80vh',
            position: leftSticky ? 'sticky' : 'sticky',
            top: 0,
          }}>
                {ItaramuluData.therdayatra.map((section, index) => (
                  <div id={`heading${index}`} onClick={() => handleToggle(index)} className="card-header card mb-3"
                    style={{ color: "#FFFFFF", backgroundColor: activeKey === index ? '#B35C0C' : '#754213' }}
                  >

                    <h5 className="mb-0">
                      <div
                        className="btn btn"
                        onClick={() => handleToggle(index)}
                        aria-expanded={activeKey === index}
                        aria-controls={`collapse${index}`}
                        style={{ color: '#FFFFFF' }}
                      >
                        {section.title}

                      </div>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{
            overflowY: rightSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: rightSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
            {ItaramuluData.therdayatra.map((section, index) => (
              activeKey === index && (
                <div
                  id={`collapse${index}`}
                  className={`collapse ${activeKey === index ? 'show' : ''}`}
                  aria-labelledby={`heading${index}`}
                  data-parent="#accordion"
                  key={`collapse-content-${index}`}
                >
                  <div className="card-body rightPanel pb-5">
                    <h5>{section.title}</h5>
                    {section.CatID.map((content, idx) => (
                      <ul key={idx}>{content.title}</ul>
                    ))}
                  </div>
                </div>
              )
            ))}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage" ></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Thirdayathra