import React, { useEffect, useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import sideGridRightImage from '../../assets/images/sideGridRight.webp';
import ItaramuluData from '../../assets/jsons/ItaramuluData';
import { LocalData } from '../../assets/jsons/localdata';

import Right from "../../assets/images/rightside.webp";
import Left from "../../assets/images/leftside.webp";
import { Row } from 'react-bootstrap';
const getFormattedDate = (monthIndex, year) => {
  const months = ["జనవరి", "ఫిబ్రవరి", "మార్చి", "ఏప్రిల్", "మే", "జూన్", "జూలై", "ఆగస్టు", "సెప్టెంబర్", "అక్టోబర్", "నవంబర్", "డిసెంబర్"];
  return `${months[monthIndex]} ${year}`;
};

const Grahalu = () => {
  const today = new Date();
  const [currentMonthIndex, setCurrentMonthIndex] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const incrementMonth = () => {
    setCurrentMonthIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex === 12) {
        setCurrentYear((prevYear) => prevYear < 2025 ? prevYear + 1 : 2024);
        return 0;
      }
      return newIndex;
    });
  };

  const decrementMonth = () => {
    setCurrentMonthIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex === -1) {
        setCurrentYear((prevYear) => prevYear > 2024 ? prevYear - 1 : 2025);
        return 11;
      }
      return newIndex;
    });
  };

  const getCurrentData = () => {
    const yearIndex = currentYear - 2024;
    if (yearIndex >= 0 && yearIndex < LocalData.GrahamUpdated.length) {
      const monthYearData = LocalData.GrahamUpdated[yearIndex];
      return monthYearData[currentMonthIndex % monthYearData.length]?.data || {};
    }
    return {};
  };
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(true);
  const [isIncrementDisabled, setIsIncrementDisabled] = useState(false);
  useEffect(() => {
    // Disable or enable navigation buttons based on the current month and year
    setIsDecrementDisabled(currentMonthIndex === 0 && currentYear === 2024);
    setIsIncrementDisabled(currentMonthIndex === 11 && currentYear === 2025);
  }, [currentMonthIndex, currentYear]);

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  return (
    <>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
              transform: 'rotateY(180deg)',
            }}
          >
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3"
                 onMouseEnter={() => {
                  setLeftSticky(false);
                  setRightSticky(true);
              }}
              style={{
                  overflowY: leftSticky ? 'hidden' : 'auto',
                  height: '80vh',
                  position: leftSticky ? 'sticky' : 'static',
                  top: 0,
              }}
          >
            {/* <div className="row">
             
               <div className="col-lg-3 col-md-2" style={{ position: "relative", textAlign: "center" }}>
                <img
                  src={Left}
                  className={`img-fluid ${isDecrementDisabled ? "disabled" : ""
                  }`}
                style={
                  isDecrementDisabled
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : {}
                }
                  onClick={!isDecrementDisabled ? decrementMonth : null}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "60%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Previous
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <h2 style={{ marginLeft: "200px", color: "#6B3300"  }}>
                  {getFormattedDate(currentMonthIndex, currentYear)}
                </h2>
              </div>
          
               <div className="col-lg-3 col-md-2" style={{ position: "relative", textAlign: "center" }}>
                <img
                  src={Right}
                  onClick={!isIncrementDisabled ? incrementMonth : null}
                  className={`img-fluid ${isIncrementDisabled ? "disabled" : ""
                    }`}
               
                style={
                  isIncrementDisabled
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : {}
                }
                />
                <div
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Next
                </div>
              </div>
              </div> */}
                <Row className="d-flex align-items-center justify-content-between">
              {/* <div
                className="col-lg-3 col-md-2 col-4 text-center"
                style={{ position: "relative" }}
              >
                <img src={Left}  style={
                  isDecrementDisabled
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : {}
                }
                  onClick={!isDecrementDisabled ? decrementMonth : null} className='PreviousImg'/>
                <div
                className='PreviousText'
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "60%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Previous
                </div>
              </div> */}
                <div
  className="col-lg-3 col-md-2 col-4 text-center"
  style={{ position: "relative" }}
>
  <img
    src={Left}
    onClick={!isDecrementDisabled ? decrementMonth : null}
    className="PreviousImg"
    alt="PreviousImg"
    style={
      isDecrementDisabled
        ? { opacity: 0.5, cursor: "not-allowed" }
        : {}
    }
  />
  <div
    className="PreviousText"
    onClick={!isDecrementDisabled ? decrementMonth : null} // Add onClick here
    style={{
      position: "absolute",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      color: "white",
      fontWeight: "bold",
      opacity: isDecrementDisabled ? 0.5 : 1, // Add opacity here
      cursor: isDecrementDisabled ? "not-allowed" : "pointer", // Add cursor style here
    }}
  >
    Previous
  </div>
</div>

              <div
                className="col-lg-6 col-md-6 col-4 text-center Maindate"
              
              >
                <h2  className='datesection' style={{ color: "#6B3300", margin: "0" }}>
               <span> {getFormattedDate(currentMonthIndex, currentYear)}</span>
                </h2>
              </div>
{/* 
              <div
                className="col-lg-3 col-md-2 col-4 text-center"
                style={{ position: "relative" }}
              >
                <img src={Right} onClick={!isIncrementDisabled ? incrementMonth : null}
                 
                style={
                  isIncrementDisabled
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : {}
                }
                className='PreviousImg'
                />
                <div
                  className='PreviousText'
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Next
                </div>
              </div> */}
              <div
  className="col-lg-3 col-md-2 col-4 text-center"
  style={{ position: "relative" }}
>
  {/* Image for Next */}
  <img
    src={Right}
    onClick={!isIncrementDisabled ? incrementMonth : null}
    alt="PreviousImg"
    style={
      isIncrementDisabled
        ? { opacity: 0.5, cursor: "not-allowed" }
        : {}
    }
    className="PreviousImg"
  />

  {/* Text for Next */}
  <div
    className="PreviousText"
    onClick={!isIncrementDisabled ? incrementMonth : null} // Add onClick here
    style={{
      position: "absolute",
      top: "50%",
      left: "40%",
      transform: "translate(-50%, -50%)",
      color: "white",
      fontWeight: "bold",
      opacity: isIncrementDisabled ? 0.5 : 1, // Match the text opacity with the image
      cursor: isIncrementDisabled ? "not-allowed" : "pointer", // Match the cursor with the image
    }}
  >
    Next
  </div>
</div>

            </Row>
              <div className='row'>  
                <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 p-3'></div>
                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3'>
                <div>
                <div className="card-body rightPanel">
                  {/* <h1>{getFormattedDate(currentMonthIndex, currentYear)}</h1>
                <button onClick={decrementMonth}>Previous Month</button>
                <button onClick={incrementMonth}>Next Month</button> */}
                  <h5>{getFormattedDate(currentMonthIndex, currentYear)}</h5>
                  <ul>
                
                    <div>అమావాస్య: {getCurrentData().Amavasya}</div>
                    <div>పౌర్ణమి: {getCurrentData().Purnima}</div>
                    <div>చతుర్ధి: {getCurrentData().Chaturthi}</div>
                    <div>ప్రదోషము: {getCurrentData().Pradosham}</div>
                    {/* <div>షష్ఠి: {getCurrentData().sasti}</div> */}
                    {/* <div>చవితి: {getCurrentData().Chavi}</div> */}
                    <div>ఏకాదశి: {getCurrentData().Ekadasi}</div>
                    {/* <div>మాస శివరాత్రి: {getCurrentData().Masa}</div> */}
                    <div style={{wordWrap:'break-word'}}>మంచి రోజులు: {getCurrentData().Good}</div>
                    <div>అష్టమి: {getCurrentData().Astami}</div>
                    <div>నవమి: {getCurrentData().Navami}</div>
                   
                  </ul>
                  <br></br>
                </div>
              </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12 p-3'></div>
              </div>
           
          
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Grahalu
