

// import React, { useEffect } from "react";

// const MultiAdsDisplay = () => {
//   useEffect(() => {
//     // Dynamically add the AdSense script if not already present
//     const existingScript = document.getElementById("adsense-script");
//     if (!existingScript) {
//       const script = document.createElement("script");
//       script.id = "adsense-script";
//       script.src =
//         "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6213965974675068";
//       script.async = true;
//       script.crossOrigin = "anonymous";
//       document.body.appendChild(script);
//     }

//     // Add the meta tag dynamically if not already present
//     const existingMeta = document.querySelector(
//       'meta[name="google-adsense-account"]'
//     );
//     if (!existingMeta) {
//       const meta = document.createElement("meta");
//       meta.name = "google-adsense-account";
//       meta.content = "ca-pub-6213965974675068";
//       document.head.appendChild(meta);
//     }
//   }, []);

//   return (
//     <div>
//       {/* <p>Ads will appear here once approved and configured.</p> */}
//     </div>
//   );
// };

// export default MultiAdsDisplay;


import React, { useEffect } from "react";

const MultiAdsDisplay = () => {
  useEffect(() => {
    // Function to load Google CMP script dynamically
    const loadCMPScript = () => {
      const existingCMPScript = document.getElementById("cmp-script");
      if (!existingCMPScript) {
        const cmpScript = document.createElement("script");
        cmpScript.id = "cmp-script";
        cmpScript.src = "https://fundingchoicesmessages.google.com/i/pub-6213965974675068?ers=1";
        cmpScript.async = true;
        cmpScript.onload = () => {
          window.__tcfapi = window.__tcfapi || function () {};
          window.__tcfapi("addEventListener", 2, (tcData, success) => {
            if (success && tcData.eventStatus === "useractioncomplete") {
              if (tcData.tcString) {
                loadAdSenseScript(); // Load ads after consent
              }
            }
          });
        };
        document.head.appendChild(cmpScript);
      }
    };

    // Function to load AdSense script dynamically
    const loadAdSenseScript = () => {
      const existingScript = document.getElementById("adsense-script");
      if (!existingScript) {
        const script = document.createElement("script");
        script.id = "adsense-script";
        script.src =
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6213965974675068";
        script.async = true;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);
      }

      // Add meta tag dynamically if not already present
      const existingMeta = document.querySelector(
        'meta[name="google-adsense-account"]'
      );
      if (!existingMeta) {
        const meta = document.createElement("meta");
        meta.name = "google-adsense-account";
        meta.content = "ca-pub-6213965974675068";
        document.head.appendChild(meta);
      }         
    };

    // Load CMP script when component mounts
    loadCMPScript();
  }, []);

  return (
    <div>
      {/* <p>Ads will appear here once approved and configured.</p> */}
    </div>
  );
};

export default MultiAdsDisplay;







