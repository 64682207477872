import React, { useState, useEffect } from 'react';
import slokas from '../../assets/jsons/Telugu/gitaslokas';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';

const Bhagavathgita = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isIntroOpen, setIsIntroOpen] = useState(false);
  const [openSlokamIndex, setOpenSlokamIndex] = useState(null);
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (slokas.length > 0 && slokas[0].CatID.length > 0) {
      const firstCategory = slokas[0].CatID[0];
      setSelectedCategory(firstCategory);
      setActiveKey(0);
    }
  }, []);

  const handleTitleClick = (category, index) => {
    if (category === selectedCategory) {
      setSelectedCategory(null);
      setActiveKey(null);
    } else {
      setSelectedCategory(category);
      setActiveKey(index);
    }
    setIsIntroOpen(false);
    setOpenSlokamIndex(null);
  };

  const handleIntroClick = () => {
    setIsIntroOpen(!isIntroOpen);
  };

  const handleSlokamClick = (index) => {
    setOpenSlokamIndex(index === openSlokamIndex ? null : index);
  };
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
     
          >
            <div className='row'>
              <div id="accordion"       style={{
              overflowY: leftSticky ? 'scroll' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'sticky',
              top: 0,
            }}>
                {slokas.map((sloka, catIndex) => (
                  <div key={catIndex}>
                    {sloka.CatID.map((category, index) => (
                      <div
                        key={index}
                        className="card-header card mb-3"
                        onClick={() => handleTitleClick(category, index)}
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: activeKey === index ? '#B35C0C' : '#754213', // Active and inactive background colors
                        }}
                      >
                        <h5 className="mb-0">
                          <div className="btn btn" style={{ cursor: 'pointer', color: "#FFFFFF", }}>
                            {category.title}
                          </div>
                        </h5>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            // style={{
            //   overflowY: rightSticky ? 'hidden' : 'auto',
            //   height: '80vh',
            //   position: rightSticky ? 'sticky' : 'static',
            //   top: 0,
            // }}
          >
            {selectedCategory && (
              <div 
              style={{
                overflowY: rightSticky ? 'scroll' : 'auto',
                height: '80vh',
                position: rightSticky ? 'sticky' : 'sticky',
                top: 0,
            }}
              className="">
                <div className="card-body rightPanel">
                  <h5>{selectedCategory.title}</h5>
                  {/* <img src={selectedCategory.Image} alt={selectedCategory.title} /> */}
                  {/* <img src={selectedCategory.PreviewImage} alt="Preview" /> */}
                  <h5 onClick={handleIntroClick} style={{ cursor: 'pointer' }}>
                    {selectedCategory.introTitle}
                  </h5>
                </div>
                {isIntroOpen && (
                  <div>
                    {selectedCategory.introduction.map((intro, iIndex) => (
                      <ul key={iIndex}>{intro.para}</ul>
                    ))}
                  </div>
                )}
                {/* Slokams */}
                {selectedCategory.subtitle.map((subtitle, sIndex) => (
                  <div 
                 
                   key={sIndex} className="card-body rightPanel">
                    <h5 onClick={() => handleSlokamClick(sIndex)} style={{ cursor: 'pointer' }}>
                      {subtitle.slokam}
                    </h5>
                    {openSlokamIndex === sIndex && (
                      <div>
                        <ul>{subtitle.bhavam}</ul>
                        {subtitle.description.map((desc, dIndex) => (
                          <>
                            <ul key={dIndex}>{desc.des}</ul>
                            <br></br>
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Bhagavathgita;
