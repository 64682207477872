const search = {
    "results": [
      {
        "title": "తెలుగు పంచాంగం, మౌంటెన్ వ్యూ కోసం తెలుగు పంచాంగం(Telugu Panchangam, Telugu Panchanga for Mountain View)",
        "description": "ఈ పేజీ 2025 జనవరి 19 నాటికి మౌంటెన్ వ్యూ, కాలిఫోర్నియా, యునైటెడ్ స్టేట్స్ కోసం వివరణాత్మక తెలుగు పంచాంగాన్ని అందిస్తుంది. తెలుగు పంచాంగం, ఇది తెలుగు... అని కూడా పిలవబడుతుంది.",
        "url": "/Panchangam"
      },
      {
        "title": "తీద్ర యాత్ర (Thidra Yatra)",
        "description": "తీద్ర యాత్ర యొక్క ఆచారాలు మరియు పద్ధతులను, దాని సాంస్కృతిక ప్రాముఖ్యత మరియు ఇది ఎలా జరుపుకుంటారో అన్వేషించండి.",
        "url": "/Thidra-Yatra"
      },
      {
        "title": "శ్లోకాలు (Slokalu)",
        "description": "ఈ పేజీ లో తెలుగు లోని వివిధ శ్లోకాలు మరియు వాటి అర్థాలు ఉన్నాయి. ఇవి చెప్పేందుకు కూడా వివరణాత్మక మార్గదర్శకతను అందిస్తుంది.",
        "url": "/Slokalu"
      },
      {
        "title": "ముహూర్తాలు (Muhurthalu)",
        "description": "తెలుగు క్యాలెండర్ లో వివిధ ఈవెంట్ల కోసం ముహూర్తాలు (ఆశ్యమైన సమయాలు) కనుగొనండి.",
        "url": "/Muhurthalu"
      },
      {
        "title": "పండుగాప్రముఖ్యత (Pandugapramukyata)",
        "description": "తెలుగు ఉత్సవాలలో పండుగాప్రముఖ్యత యొక్క సాంస్కృతిక ప్రాముఖ్యత మరియు దాని పాత్ర గురించి తెలుసుకోండి.",
        "url": "/Pandugapramukyata"
      },
      {
        "title": "అష్టోత్తరాలు (Ashtotharalu)",
        "description": "అష్టోత్తరాలు లో 108 పేర్లు మరియు వాటి ఆధ్యాత్మిక ప్రాముఖ్యతను కనుగొనండి.",
        "url": "/Ashtotharalu"
      },
      {
        "title": "వివాహ నక్షత్రాలు (Vivaha Nakshatralu)",
        "description": "తెలుగు పెళ్లిళ్ల మరియు ఆచారాలలో వివాహ నక్షత్రాల ప్రాముఖ్యతను అర్థం చేసుకోండి.",
        "url": "/Vivaha-Nakshatralu"
      },
      {
        "title": "శ్రీ కృష్ణ ఆస్ట్రోత్తరాలు (Sri Krishna Astrotharalu)",
        "description": "శ్రీ కృష్ణ యొక్క జ్యోతిష్య ప్రాముఖ్యత మరియు ఆయన ప్రভাবం మీద వివరణాత్మక మార్గదర్శకత.",
        "url": "/Sri-Krishna-Astrotharalu"
      },
      {
        "title": "రామాయణం (Ramayanam)",
        "description": "ప్రాచీన భారతీయ మహాకావ్యం రామాయణం, దాని కథ, పాత్రలు మరియు పాఠాలు గురించి అన్వేషించండి.",
        "url": "/Ramayanam"
      },
      {
        "title": "మహాభారతం (Mahabharatam)",
        "description": "మహాభారతం గురించి విస్తృత అవలోకనం, దాని పాఠాలు, బోధనలు మరియు ముఖ్యమైన సంఘటనలు.",
        "url": "/Mahabharatam"
      },
      {
        "title": "గీతా శారాంశం (Geetha Saramsam)",
        "description": "భగవద్గీత యొక్క సంక్షిప్త బోధనలు మరియు ఆధ్యాత్మిక జ్ఞానం.",
        "url": "/Geetha-Saramsam"
      },
      {
        "title": "సంఖ్య శాస్త్రం (Sankya Shastram)",
        "description": "ప్రాచీన శాస్త్రంలో ఒక ప్రధాన పాఠశాల అయిన సంఖ్య శాస్త్రం యొక్క గణిత మరియు తత్వశాస్త్ర దృక్కోణాలను తెలుసుకోండి.",
        "url": "/Sankya-Shastram"
      },
      {
        "title": "పండుగలు (Pandugaluu)",
        "description": "వివిధ ప్రాంతాలలో జరిపే పండుగలు మరియు వాటి ఆచారాలు గురించి తెలుసుకోండి.",
        "url": "/Pandugaluu"
      },
        {
          "title": "క్యాలెండర్ (Calendar)",
          "description": "తెలుగు క్యాలెండర్ మరియు ముఖ్యమైన తేదీలు.",
          "url": "/Calendar"
        },
        {
          "title": "రాశి ఫలాలు (Rasi-Phalalu)",
          "description": "రోజువారీ మరియు వార్షిక రాశి ఫలాలు.",
          "url": "/Rasi-Phalalu"
        },
        {
          "title": "ఉపవాసం (Upavasam)",
          "description": "ఉపవాసం యొక్క ప్రాముఖ్యత మరియు ఆచారాలు.",
          "url": "/Upavasam"
        },
        {
          "title": "ఆధ్యాత్మిక శక్తి (Athyadhimika-shakthi)",
          "description": "ఆధ్యాత్మిక శక్తి యొక్క రహస్యాలు మరియు ఉపదేశాలు.",
          "url": "/Athyadhimika-shakthi"
        },
        {
          "title": "తీర్థ యాత్ర (Thidra-Yatra)",
          "description": "ప్రధాన పుణ్యక్షేత్రాలు మరియు తీర్థయాత్రల సమాచారం.",
          "url": "/Thidra-Yatra"
        },
        {
          "title": "శోత్రాలు (Shotramlu)",
          "description": "వివిధ శాస్త్ర శోత్రాలు మరియు మంత్రాలు.",
          "url": "/Shotramlu"
        },
        {
          "title": "అష్టోత్తరాలు (Ashtotharalu)",
          "description": "దేవతల అష్టోత్తర శతనామావళి.",
          "url": "/Ashtotharalu"
        },
        {
          "title": "శ్లోకాలు (Slokalu)",
          "description": "పండితులు చెప్పిన శ్లోకాలు మరియు వాటి అర్థాలు.",
          "url": "/Slokalu"
        },
        {
          "title": "ముహూర్తాలు (Muhurthalu)",
          "description": "వివాహం మరియు ఇతర శుభకార్యాల ముహూర్తాలు.",
          "url": "/Muhurthalu"
        },
        {
          "title": "వివాహ నక్షత్రాలు (Vivaha-Nakshatralu)",
          "description": "వివాహాలకు అనుకూలమైన నక్షత్రాలు మరియు తేదీలు.",
          "url": "/Vivaha-Nakshatralu"
        },
        {
          "title": "ధర్మ సందేహాలు (Dharmasandehalu)",
          "description": "ధర్మ సంబంధిత ప్రశ్నలు మరియు సమాధానాలు.",
          "url": "/Dharmasandehalu"
        },
        {
          "title": "హిందూ ధర్మశాస్త్రం (Hindu-Darmashastram)",
          "description": "హిందూ ధర్మశాస్త్రంలో ముఖ్యమైన అంశాలు.",
          "url": "/Hindu-Darmashastram"
        },
        {
          "title": "పండుగల ప్రాముఖ్యత (Pandugapramukyata)",
          "description": "తెలుగు పండుగల ప్రత్యేకత మరియు సంప్రదాయాలు.",
          "url": "/Pandugapramukyata"
        },
        {
          "title": "మాస విశిష్టత (Masa-Vishistatha)",
          "description": "ప్రతి మాసం యొక్క ప్రాముఖ్యత మరియు సంప్రదాయాలు.",
          "url": "/Masa-Vishistatha"
        },
        {
          "title": "సృష్టి రహస్యాలు (Srusti-Rahasyalu)",
          "description": "సృష్టి మరియు ప్రకృతి సంబంధిత రహస్యాలు.",
          "url": "/Srusti-Rahasyalu"
        },
        {
          "title": "జీవన శైలి (Jeevanashaili)",
          "description": "ఆరోగ్యకరమైన జీవన విధానాలు మరియు సూచనలు.",
          "url": "/Jeevanashaili"
        },
        {
          "title": "సంఖ్యా శాస్త్రం (Sankya-Shastram)",
          "description": "సంఖ్యల ప్రాముఖ్యత మరియు వాటి ఫలితాలు.",
          "url": "/Sankya-Shastram"
        },
        {
          "title": "గ్రహాలు (Grahalu)",
          "description": "గ్రహాల ప్రాముఖ్యత మరియు జ్యోతిష్య శాస్త్రం.",
          "url": "/Grahalu"
        },
        {
          "title": "భవిష్యవాణి (Bhavishyavani)",
          "description": "భవిష్యవాణి వివరాలు మరియు జ్యోతిష్య రీత్యా విశ్లేషణ.",
          "url": "/Bhavishyavani"
        },
        {
          "title": "వాస్తు చిట్కాలు (Vasthu-Chitkalu)",
          "description": "వాస్తు శాస్త్రం చిట్కాలు మరియు మార్గదర్శకాలు.",
          "url": "/Vasthu-Chitkalu"
        },
        {
          "title": "బల్లి శాస్త్రం (Balli-Shastram)",
          "description": "బల్లి సంబంధిత శాస్త్రం మరియు నమ్మకాలు.",
          "url": "/Balli-Shastram"
        },
        {
          "title": "పిల్లి శాస్త్రం (Pillishastram)",
          "description": "పిల్లి శాస్త్రం మరియు జ్యోతిష్య విశ్లేషణ.",
          "url": "/Pillishastram"
        },
        {
          "title": "విలువైన సమాచారం (Villuvina-Samacharam)",
          "description": "జీవితానికి విలువైన సమాచారం మరియు సూచనలు.",
          "url": "/Villuvina-Samacharam"
        },
        {
          "title": "సంస్కృతం (Samskrutham)",
          "description": "సంస్కృత భాష మరియు సంస్కృతి సంబంధిత అంశాలు.",
          "url": "/Samskrutham"
        },
        {
          "title": "సామెతలు (Samethalu)",
          "description": "తెలుగు సామెతలు మరియు వాటి అర్థాలు.",
          "url": "/Samethalu"
        },
        {
          "title": "రాష్ట్రాల భాషలు (Rastrala-Bashalu)",
          "description": "భారతదేశంలో భాషల ప్రాముఖ్యత మరియు విశిష్టత.",
          "url": "/Rastrala-Bashalu"
        },
        {
          "title": "నిత్య సత్యాలు (Nithya-Sathyalu)",
          "description": "ప్రతిరోజు పాటించాల్సిన నైతిక సత్యాలు.",
          "url": "/Nithya-Sathyalu"
        },
        {
          "title": "నిథి పద్యాలు (Nithi-Padhyalu)",
          "description": "నైతికత సంబంధిత పద్యాలు మరియు సందేశాలు.",
          "url": "/Nithi-Padhyalu"
        },
        {
          "title": "గ్రహణం (Grahanam)",
          "description": "గ్రహణాలు మరియు వాటి ప్రాముఖ్యత.",
          "url": "/Grahanam"
        },
        {
          "title": "తెలుగు సంస్కృతం (Telugu-Samskrutham)",
          "description": "తెలుగు సంస్కృతం సంబంధిత అంశాలు.",
          "url": "/Telugu-Samskrutham"
        },
        {
          "title": "తెలుగు పంచాంగం (Telugu-Panchangam)",
          "description": "తెలుగు పంచాంగ వివరాలు మరియు శుభ తేదీలు.",
          "url": "/Telugu-Panchangam"
        },
 
        {
          "title": "శ్రీ కృష్ణ అష్టోత్తరాలు (Sri-Krishna-Astrotharalu)",
          "description": "శ్రీ కృష్ణుడు సంబంధిత అష్టోత్తర శతనామాలు.",
          "url": "/Sri-Krishna-Astrotharalu"
        },
        {
          "title": "శ్రీ కృష్ణాష్టకం (Sri-Krishnastakam)",
          "description": "శ్రీ కృష్ణాష్టకం మరియు ఆధ్యాత్మిక జ్ఞానం.",
          "url": "/Sri-Krishnastakam"
        },
        {
          "title": "భక్తి సుఖ్తులు (Bhakthi-Sukthulu)",
          "description": "భక్తి సంబంధిత సుఖ్తులు మరియు శ్లోకాలు.",
          "url": "/Bhakthi-Sukthulu"
        },
        {
          "title": "వస్తువులు (Vasthavalu)",
          "description": "జీవితానికి సంబంధించిన అవసరమైన వస్తువులు.",
          "url": "/Vasthavalu"
        },
      
      ]
      
    
  };
  
  export default search;
  
  
