// utils.js
export const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
                {part}
            </span>
        ) : (
            part
        )
    );
};
