const geetasaaramsam = [
  {
    title: "గతమంతా మంచే జరిగింది.\nవర్తమానం కూడా మంచే జరుగుతుంది.\nభవిష్యత్తు కూడా మంచే జరుగుతుంది."
  },
  {
    title: <span className="highlight">నువ్వు ఏం పోగొట్టుకున్నావని అంతలా దు:ఖిస్తున్నావు?</span>
  },
  {
    title: "నువ్వు పోగొట్టుకొన్న దానిని నువ్వు సృష్టించలేదు.\nనువ్వు అనుభవిస్తున్నదంతా ఇహలోక సంపదే.\nనువ్వు అనుభవించే ఈ భోగం నిన్న వేరొకరి సొత్తు.\nనేడు నీదిగా అయ్యింది.\nరేపు వేరొకరి స్వంతం అవుతుంది.\nమార్పు అనేది ప్రకృతి సహజధర్మం."
  },
  {
    title: "భగవద్గీత గురించి ఎన్నో వ్యాఖ్యానాలున్నాయి. ఎందరో పండితులు, సామాన్యులు, ఔత్సాహికులు కూడా అర్ధాలు, అంతరార్ధాలు, సందేశాలు, విశేషాలు వివరించారు. కనుక భగవద్గీత సారం అన్నవిషయం ఇది వ్రాసేవారికి అర్ధమయినంత, తోచినంత అని గ్రహించాలి"
  },
  {
    title: "కర్తవ్య విమూఢుడైన అర్జునుడికి జ్ఞానం బోధించి, కర్తవ్యంవైపు నడిపించడం అనేది గీత లక్ష్యం అని సందర్భానుసారంగా అనుకోవచ్చును. అయితే అర్జునుడు ఒక పట్టాన ఈ విషయాన్ని అంగీకరించక ప్రశ్నిస్తూ ఉంటాడు. శిష్యునిపై వాత్సల్యంతో శ్రీకృష్ణుడు అతనికి నిగూఢమైన, వేరెవరికీ తెలియని అనేక విషయాలు బోధిస్తాడు."
  },
  {
    title: "ఆత్మ నిత్య సత్యమైనది, చావు లే నిది. మృత్యువు వారిని శరీరాల నుండి వేరుచేస్తుందే కానీ ఆత్మను చంపదు. సత్యమైన జ్ఞానము ఆత్మ జ్ఞానమే అంటే తనను తాను తెలుసుకోవడమే, తనలోని అంతరాత్మ గురించి తెలుసుకోవడమే. అభ్యాస వైరాగ్యముల ద్వారా యోగి, వస్తు ప్రపంచాన్ని వదలి సర్వోత్కృష్టమైన పరబ్రహ్మాన్ని చేరగలడు. భక్తి, కర్మ, ధ్యాన, జ్ఞాన మార్గాలలో భగవంతుని చేరవచ్చును"
  },
  {
    title: "మనిషి కర్మ చేయకుండా ఉండడం సాధ్యం కాదు. అయితే కర్మలవలన దోషాలు కూడా తప్పవు. సత్పురుషుల ద్వారా జ్ఞానాన్ని సంపాదించి, సత్కర్మలు ఆచరించాలి. కర్మలపై ప్రతిఫలాన్ని ఆశించరాదు. అన్ని కర్మల ఫలాన్ని భగవంతునకు ధారపోయాలి."
  },
  {
    title: "కృష్ణుడే పరబ్రహ్మము. సృష్టిలోని సకలము భగవంతుని అంశతోనే ఉన్నాయి. అన్ని పూజల, యజ్ఞాల ఫలాలు ఆ దేవదేవునకే చెందుతాయి. బ్రహ్మ తత్వాన్ని తెలుసుకోవడానికి శ్రీకృష్ణుడు అర్జునునకు తాత్కాలికముగా దివ్య దృష్టిని ప్రసాదించాడు. అనంతము, తేజోమయము, సర్వవ్యాప్తము, కాల స్వరూపము అయిన ఆ శ్రీకృష్ణుని విశ్వ రూపాన్ని చూసి అర్జునుడు తరించాడు."
  },
  {
    title: "ప్రకృతిలో సకల జీవాలు సత్వరజస్తమోగుణాలచే నిండి ఉన్నాయి. భగవంతునకు శరణాగతుడైనవాడికి ఈ గుణాల బంధంనుండి విముక్తి లభిస్తుంది."
  }
];
export default geetasaaramsam;
