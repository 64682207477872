import React, { useState, useEffect } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import sideGridRightImage from '../../assets/images/sideGridRight.webp';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Upavasam = () => {
  const monthsInTelugu = ['జనవరి', 'ఫిబ్రవరి', 'మార్చి', 'ఏప్రిల్', 'మే', 'జూన్', 'జూలై', 'ఆగస్టు', 'సెప్టెంబర్', 'అక్టోబర్', 'నవంబర్', 'డిసెంబర్'];

  const [selectedYear, setSelectedYear] = useState('2025');
  const [selectedMonth, setSelectedMonth] = useState(monthsInTelugu[0]); // Default to first month

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  const yearIndex = selectedYear === '2024' ? 0 : selectedYear === '2025' ? 1 : null;
  const monthIndex = monthsInTelugu.indexOf(selectedMonth);
console.log(yearIndex,"yearIndex")
  const filteredData = yearIndex !== null && monthIndex !== -1
    ? ItaramuluData.upavasamDates[yearIndex][monthIndex]
    : null;
    
    const [leftSticky, setLeftSticky] = useState(true);
    const [rightSticky, setRightSticky] = useState(true);
  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
            <select
              id="years"
              value={selectedYear}
              onChange={handleYearChange}
              className="custom-select"
              style={{height:'40px'}}
            >
              {/* <option value="2024">2024</option> */}
              <option value="2025">2025</option>
            </select>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
         
        >
            <div className="row">
              <div id="accordion"  style={{
            overflowY: leftSticky ? 'Scroll' : 'auto',
            height: '80vh',
            position: leftSticky ? 'sticky' : 'sticky',
            top: 0,
          }}>
                {monthsInTelugu.map((month, index) => (
                  <div
                    key={index}
                    className="card-header card mb-3"
                    onClick={() => handleMonthClick(month)}
                    style={{
                      backgroundColor: selectedMonth === month ? '#B35C0C' : '#754213'
                    }}
                  >
                    <h5 className="mb-0">
                      <button
                        className="btn btn"
                        style={{ color: '#FFFFFF' }}
                      >
                        {month}
                      </button>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{
            overflowY: rightSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: rightSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
            {filteredData ? (
              <div className="card-body rightPanel">
                  <h5>{selectedMonth} {selectedYear}</h5> 
                <ul><strong>అమావాస్య:</strong> {filteredData.Amavas}</ul>
                <ul><strong>చతుర్ధి:</strong> {filteredData.Chatur}</ul>
                <ul><strong>ఏకాదశి:</strong> {filteredData.Ekadas}</ul>
                <ul><strong>పౌర్ణమి:</strong> {filteredData.Purnim}</ul>
                <ul><strong>ప్రదోషము:</strong> {filteredData.Prados}</ul>
                <ul><strong>షష్ఠి:</strong> {filteredData.Shast}</ul>
                <ul><strong>చవితి:</strong> {filteredData.Chavith}</ul>
                <ul><strong>మాస శివరాత్రి:</strong> {filteredData.MasaShivarat}</ul>
                <br></br>
              </div>
            ) : (
              <p>No data available for {selectedMonth} {selectedYear}</p>
            )}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Upavasam;

