import Dhanushu from '../images/rashiIcons/dhanushu.webp';
import Vrushabha from "../images/rashiIcons/vrushabha.webp";
import Midhuna from "../images/rashiIcons/midhuna.webp";
import Karkata from "../images/rashiIcons/karkata.webp";
import Simha from "../images/rashiIcons/simha.webp";
import Kanya from "../images/rashiIcons/kanya.webp";
import Tula from "../images/rashiIcons/tula.webp";
import Vrushika from "../images/rashiIcons/vrushika.webp";
import Makara from "../images/rashiIcons/makara.webp";
import Kumbha from "../images/rashiIcons/kumbha.webp";
import Meena from "../images/rashiIcons/meena.webp";
import RashiIcons from "../images/rashiIcons/image.webp";

export const RasiPhalaluData = {
  "RashiUpdated": [
    [
      {
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో కూడా మిశ్రమ ఫలితాలు ఎదుర్కొంటారు. సొంత మనుష్యులతో విరోధములు మరియు గృహంలో అనవసర ఖర్చులు కొనసాగును. ప్రయత్నాలలో ఆటంకములు తీవ్ర నిరాశ ను ఏర్పరచును. ప్రయాసతో కూడిన ప్రయాణములు చేయవలసి వస్తుంది. జూదం వలన నష్టం పొందుతారు. జీర్ణ వ్యవస్థకు సంబందించిన సమస్యలు బాధిస్తాయి. 18 వ తేదీ తదుపరి కొంత అనుకూలత. నూతన ఆదాయ మార్గముల కొరకు చేయు ప్రయత్నాలు ఫలిస్తాయి. మాసాంతంలో కొద్దిపాటి సౌఖ్యం అనుభవిస్తారు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో మాసంలో దీర్గకాళిక అనారోగ్యం తో బాధ పడుతున్న వారికి శరీర ఆరోగ్యం బాగుండును. మనసు ఉల్లాసంగా ఉండును. విందు వినోదాలలో పాల్గోనేదురు. ఈ మాసంలో 10, 14, 15 తేదీలలో ప్రయాణాలలో జాగ్రత్త అవసరం. ద్వితియ వారంలో సంతాన సంబంధ అనారోగ్యం బాధించును. ఇష్టమైన వారికి సంబంధించిన అశుభ వార్త వినుటకు అవకాశం ఉన్నది. ఈ మాసంలో తృతీయ వారం నుండి అనగా 21 వ తేదీ తదుపరి కార్య సిద్ధి లభించును. ప్రయత్న విజయములు ప్రాప్తించును. దూర స్నేహితుల కలయిక వలన ఆనందం ఏర్పడుతుంది. నెల మొత్తం ధనాదాయం సామాన్యం. నూతన దంపతుల సంతాన ప్రయత్నములు కూడా లాభించును.",
          Path: Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "8",
        },

        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో అతి కష్టం మీద నిరుద్యోగులకు ఆశించిన ఉద్యోగం లభించును. ధనాదాయం బాగుండును. రాజకీయంగా పై స్థానాలలో ఉన్న వ్యక్తులతో ఉన్న పరిచయాల వలన లాభములు ఏర్పడును. వృత్తి వ్యాపారములు చేయు వారికి సామాన్య ఫలితాలు ఎదురగును. తృతీయ వారంలో గృహంలో ఆకస్మిక శుభకార్యములు నిర్వహించెదరు. బంధు సమాగమం వలన లాభపడతారు. మాసాంతంలో వ్యక్తిగత జీవితానికి సంబందించిన ఒక శుభవార్త మరియు స్త్రీ మూలక లాభము పొందుతారు. ఈ మాసంలో పునర్వసు నక్షత్ర జాతకుల వివాహ ప్రయత్నములు ఫలించును.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో ధన ఆదాయం ఆశించిన స్థాయిలో ఉండదు. మాసం మొత్తం సామాన్య ఫలితాలు ఏర్పడును. అవివాహితులకు వివాహ ప్రయత్నాలలో కష్టం మీద సఫలత లభిస్తుంది. అతిగా ఆలోచించడం వలన చక్కటి అవకాశములను కోల్పోవుదురు. ఈ మాసంలో వృత్తి వ్యాపారాదులు సామాన్యంగా కొనసాగును. ఈ మాసంలో 9,13,17,23 మరియు 29 తేదీలు మంచివి కావు.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో నలుగురిలో మీకు ప్రత్యేకమైన గుర్తింపు లభిస్తుంది. ఉద్యోగ జీవనంలో వారికి పదోన్నతి లభిస్తుంది. వ్యాపారాదులలో ఆశించిన స్థాయిలో లాభములు పొందుతారు. కుటుంబములో మీ మాటకు విలువ పెరుగును. సంకల్ప సిద్ధి ఏర్పడును. ద్వితియ వారంలో విహార యాత్రలలో పాల్గొంటారు. సంతోషకర సమయం. ఈ మాసంలో 16 తేదీ నుండి నిలకడైన జీవనం లభిస్తుంది. ఉద్యోగ మార్పిడి ప్రయత్నాలకు ఇది మంచి కాలం. జీవిత భాగస్వామితో సోఖ్యం. ఉన్నత వర్గం వారితో స్నేహ సంభందాలు ఏర్పడును. తన్మూలక లాభములు పొందుతారు.",
          Path: Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "14",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో జరుగుతూ ఉన్న పనులలో అఖస్మిక విఘ్నములు ఎదురవుతాయి. ఈ మాసమంతా సామాన్య ఫలితాలు పొందుతారు. పితృ సంబంధ విషయాల వలన మానసిక చికాకులు ఎదుర్కొంటారు. వృత్తి జీవనం వారికి, వ్యాపారస్థులకు సామాన్య ఆదాయం లభిస్తుంది. ఉద్యోగ జీవనంలోని వారికి ఉద్యోగ మార్పిడికి మార్గములు లభిస్తాయి. వివాహ ప్రయత్నములలో ఏర్పడిన స్తంభన తొలగును. విద్యార్ధులకు పోటీ పరీక్షలలో ప్రతికూలమైన వాతావరణం ఎదురవుతుంది. రైతులకు పంట దిగుబడి ఆశించిన విధంగా ఉండదు.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "5",
        },

        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాస ప్రారంభంలో సొంత మనుష్యులతో వివాదములు నెలకొంటాయి. ఆకస్మిక అనారోగ్య సూచన ఉన్నది. చర్మ సంభండ దోషములు ఇబ్బంది పెట్టును. తలచిన కార్యములు అటంకములను ఎదుర్కొనా చివరికి పూర్తి అగును. వృధా వ్యయములు తరచుగా ఏర్పడును. 16వ తేదీ నుండి పరిస్థితి అనుకులించును. విద్యార్ధులకు, విదేశీ విద్య కొరకు ప్రయత్నించు వారికి తృతీయ వారం నుండి మంచి అనుకూల కాలం ప్రారంభం అగును. కార్యమార్గంలో చిన్న చిన్న ఆటంకములు ఉన్నప్పటికీ వ్యవహార విజయం ఉండును. మాసాంతంలో ఆనందమయ సమయం అనుభవిస్తారు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "8",
        },

        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం సంతోషకరమైన ఫలితాలను కలిగించును. జీవన మార్గంలో సుఖ ప్రాప్తి లభించును. ఈ మాసంలో ఆశించిన ధనం లభించును. ప్రత్యర్ధులపై విజయం మరియు సకల కార్య విజయాలు పొందుతారు. సంసార సంబంధమైన సంతోషాలు పరి పూర్ణంగా పొందగలుగుతారు. దీర్ఘకాలికంగా పరిష్కారం అవ్వని వారసత్వ సంబంధ సమస్యలు పరిష్కారం పొందును. ప్రభుత్వ సంబంధ వ్యవహారములు లాభించును. ఈ మాసంలో 2, 6, 8, 11, 21, 26 తేదీలు కార్యానుకులత కలిగించును.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },

        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో మొదటి వారం అంత అనుకూల ఫలితాలు ఇవ్వదు. ఆ తదుపరి నూతన ఆదాయ మార్గములు లభిస్తాయి. వ్యాపారములో అభివృద్ధి ఆశించిన విధంగా ఉంటుంది. పిత్రు వర్గీయులతో విరోధములు చికాకు కలుగ చేస్తాయి. తృతీయ వారంలో ఊహించని రీతిలో ఒక ఆకస్మిక కష్టం ఎదురగును. అనుకోని ఖర్చులు పెట్టవలసి వస్తుంది. వివాహ ప్రయత్నములలో ఆశాభంగమునకు గురి అవుతారు. ఈ మాసంలో 5,7,25,29 తేదీలు అనుకూలమైనవి కావు. ఈ రోజులలో ఋణములు తీసుకొనుట, ఇతర ఆర్ధిక పరమైన విషయాలలో పాల్గొనుట మంచిది కాదు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "5",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో గృహంలో చికాకులు తొలగును. ధనాదాయం బాగుండును. వృత్తి వ్యాపారములలో చక్కటి లాభములు ఆర్జిస్తారు. శుభకరమైన ఫలితాలు మరియు కార్య సిద్ధి పొందగలుగుతారు. బహుమతులు ప్రాప్తించును. ప్రయాణ సౌఖ్యం. తృతీయ వారంలో అపకీర్తి, శరీరక రుగ్మతలు బాదించును. ఈ మాసంలో ధనానికి లోటు రాదు. చివరి వారం సామాన్య ఫలితాలు ఎదురగును.",
          Path: Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "14",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో విలువైన ఆభరణములు కొనుగోలుచేయుదురు. వ్యక్తిగత జీవితంలో శుభం జరుగును. మొదట ప్రయత్న విఘ్నములు ఉన్నప్పటికీ అంతిమంగా విజయం పొందుదురు. వాహన, భూ, వస్త్ర సౌఖ్యం ఏర్పడును. వ్యాపారములు లాభించును. వంశ పెద్దల సహకారం వలన కుటుంబ కలహాలు తొలగును. గృహంలో వేడుకలు జరుగును. అన్నివిధములా ఈ మాసం అనుకూలమైన కాలం.",
          Path: Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "14",
        },

        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో తగాదాల వలన కోర్టుకేసులు లేదా పోలిసుల జోక్యం వలన చికాకులు. వ్యాపార వ్యవహారాలు సామాన్యం. అవసరాలకు సరిపడు ధనాదాయం. మిత్రుల తోడ్పాటు లభించును. ప్రయానములక ఆరోగ్య భంగములు. జీవిత భాగస్వామి కొరకు ధనం వ్యయం చేయుదురు. అతిగా ఆలోచించడం వలన నష్టపోవు సంఘటనలు. వ్యక్తిగత జీవనంలో సౌఖ్యం. సంతాన ప్రయత్నాలు ఫలప్రదం.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "5",
        },


      },
      {
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసం కొంచం అనుకూల ఫలితాలు ఏర్పరచును. కుటుంబ పరమైన వేధింపులు తగ్గుతాయి. వ్యవహార నష్టములు తగ్గుముఖం పడుతాయి. నూతన వ్యాపారములు ప్రారంభించుటకు ఈ మాసం అనుకూలమైన కాలం. ఉద్యోగ జీవనంలో మార్పులకు కూడా ఈ మాసం అనుకూలమైనది. వ్యయం అదుపులోకి వస్తుంది. కాలి నరములకు సంబంధించిన ఆరోగ్య సమస్యలు ఇబ్బందులు పెడతాయి. ఈ మాసంలో తృతీయ మరియు చతుర్ధ వారములు పితృ వర్గీయులకు మంచిది కాదు. ఈ మాసంలో 11 నుండి 16 వరకూ నూతన కార్య ప్రారంభములకు అనుకూలమైన రోజులు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో వృత్తి, ఉద్యోగ, వ్యాపార వ్యవహారాలలో సామాన్య ఫలితాలు ఎదురగును. ధనాదాయం కూడా సామాన్యం. ద్వితీయ మరియు తృతీయ వారాలలో అవివాహితుల కు వివాహ సంబంధ సంతోషాలు ఎదురగును. నూతన వాహన సౌఖ్యం ఏర్పరచు కొనగలరు. తృతీయ వారం నుండి తలపెట్టిన పనులందు కార్య విఘ్నతలు ఎదురగును. ప్రతీ పనికి శ్రమించవలెను. ఉద్యోగస్తులకు కూడా చికాకులు ఎదురగును. స్త్రీలకు ఈ మాసంలో అంతగా అదృష్టం కలసిరాదు. ముఖ్యంగా 19, 20, 29 తేదీలలో అధికంగా సమస్యలు ఎదురగును. మాసాంతంలో కుటుంబ పెద్దవారితో వ్యవహార చిక్కులు ఎదురగును.",
          Path: Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "8",
        },

        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో గౌరవ హోదాలు పెరుగును. ఆశించిన విధంగా నూతన పదవుల ప్రాప్తించును. చిరు ఉద్యోగులకు జీవన స్థిరత్వం లభిస్తుంది. కుటుంబ సంతోషములు ఏర్పడుతాయి. సొంత గృహ నిర్మాణ సంబంధ లాభములు పొందుతారు. విదేశీ సంబంధ స్థాన చలనత ఏర్పడుతుంది. గృహంలో ఖరీదైన నూతన వస్తువులను అమర్చుకుంటారు. ఈ మాసంలో 22వ తేదీ తదుపరి ప్రారంభించు నూతన వ్యాపారములు లాభించును. మాసాంతంలో ఉద్యోగ భవిష్యత్ గురించిన ఆందోళన పూర్తిగా తొలగుతుంది. ఈ మాసంలో 1,6,10,19 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో జీవిత భాగస్వామి సంబంధిత ధన లాభములు ఏర్పడును. స్వార్జిత పరంగా కూడా ఆర్ధికంగా చక్కటి సౌఖ్యం అనుభవిస్తారు. పుష్యమి నక్షత్ర జాతకులకు ఈ మాసంలో స్టాక్ మార్కెట్ ట్రేడింగ్ కలసి వస్తుంది. మానసిక ఉల్లాసం అనుభవిస్తారు. తృతీయ వారం పూర్తి అగు వరకూ అనుకూలంగా ఉంటుంది. చివరి వారం నుండి ఉద్యోగ జీవనంలో ఒత్తిడి అధికం అవుతుంది. సమయా భావం వలన ఇబ్బంది పడతారు. ఉద్యోగంలో తోటివారి సహకారం లభించదు. మీ నుండి సహాయం పొందిన వారు కూడా మీకు సహకారం అందించకుండా ఉండుట మిమ్మల్ని బాధిస్తుంది.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో అన్నివిధాలా ఆదాయం బాగుండును. ఈ మాసం విదేశీ ప్రయత్నములు, వివాహ ప్రయత్నములు, సంతాన ప్రయత్నములకు చక్కటి అనువైన కాలం. మాస మధ్యమంలో ఆకస్మిక ప్రయాణములు చేయవలసి వస్తుంది. పితృ వర్గీయులకు ఈ మాసం అశుభం కలుగ చేయు సూచన ఉన్నది. ఈ మాసంలో 20,21,22 తేదీలలో మిత్ర సంబంధ వ్యవహారములలో నష్టం ఏర్పడును. చివరి వారంలో శారీరక శ్రమ అధికం అగుట వలన అలసట అనుభవిస్తారు. నరముల సంబందిత సమస్యలు బయట పడు అవకాశం ఉన్నది. మానసిక విచారం ఎదుర్కొందురు.",
          Path: Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "14",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసం గృహ సంబంధ నిర్మానములకు, ఋణ ప్రయత్నములకు మంచిది. ద్వితియ వారంలో ప్రభుత్వ సన్మానం లేదా ప్రభుత్వ సంబంధ ప్రయోజనం పొందుతారు. కార్య సిద్ధి యోగం ఉన్నది. దూర ప్రాంత ప్రయాణములు అనుకున్న రీతిలో ముగుస్తాయి. తృతీయ వారంలో స్వల్ప కాలిక అనారోగ్య సమస్యలు బాదిస్తాయి. శస్త్ర చికిత్స అవసరం ఉండదు. చివరి వారంలో సామాన్య ఫలితాలు ఎదురవుతాయి. కళత్రముతో చికాకులు కొనసాగును.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "5",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. షేర్ మార్కెట్లో పెట్టుబడులు పెట్టిన వారికి నష్టములు ఎదురగును. ద్వితియ వారంలో దూరప్రాంతము నుండి ఒక అశుభ వార్త వినవలసి వస్తుంది. గృహ వాతావరణం అశాంతిని కలిగి ఉండును. మాస మధ్యలో ఖర్చులు అధికంగా ఉండును. కోర్టు వ్యవహరములు చిరాకు కలుగ చేయును. చివరి వారంలో ఒంటరితనం బాధించును. కళాకారులకు ప్రోత్సాహకరమైన కాలం. విద్యార్దులు శ్రమించవలెను. అన్యకార్యములపై శ్రద్ధ తగ్గించ వలెను. ఈ మాసంలో 2,6,7,15,29 తేదీలు అనుకూలమైనవి కావు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "8",
        },

        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో కూడా చక్కటి అనుకూలమైన ఫలితాలు ఏర్పడును. ప్రధమ మరియు ద్వితీయ వారాలు అన్ని విధములా సౌకర్యవంతమైన జీవనాన్ని ప్రసాదించును. తృతీయ వారంలో మాత్రం సుఖ వ్యాధుల వలన బాధ పొందు సూచన ఉన్నది. ఈ మాసం అంతా ధనాదాయం బాగుండును. ఆర్ధిక పరమైన సంకల్పములు నెరవేరును. బంధు వర్గం వారితో మాత్రం జాగ్రత్త అవసరం. మొత్తం మీద ఈ మాసం ఉత్సాహపూరిత సమయం.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో సమస్యలు కొంత తగ్గుముఖం పడతాయి. తగాదాలలో మీ వాదనలకు విలువ లభిస్తుంది. బంధువుల కలయిక వలన సంబంధాలు మెరుగుపడును. ఎంతో కాలంగా వాయిదా పడుతున్న పనులు పూర్తి అగును. జీవిత భాగస్వామి తో సమస్యలు తగ్గును. 22 వ తేదీ తదుపరి సంతోషకర వార్తలు వింటారు. జీవనంలో కోరుకున్న విధంగా ఉన్నతి లభిస్తుంది. ఈ మాసంలో ఆదాయంలో పెరుగుదల పొందుతారు. సువర్ణ పెట్టుబడులు చేస్తారు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "5",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో మానసిక ఒత్తిడి అధికం అవుతుంది. కానీ ఆదాయం బాగుండును. రావలసిన ధనం చేతికి వచ్చును. ద్వితియ వారంలో వాహన ప్రమాద సూచన. తృతీయ వారం ధన వ్యయం, ఆరోగ్య విషయాలలో అననుకూలత, ఆశాభంగములు వంటి సమస్యలు బాదించును. 24, 25, 26 తేదీలలో ఉద్రేకం వలన నష్టం కొని తెచ్చుకుంటారు.",
          Path: Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "14",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ఆదాయం సామాన్యం. తలపెట్టిన కార్యములందు అవకాశములు చేజారిపోవును. నూతన ఆదాయ మార్గముల కొరకు శ్రమించవలెను. పురుష సంతానం వలన సమస్యలు ఎదురగును. వృత్తి నైపుణ్యాలు పెంచుకోవాల్సిన అవసరం ఏర్పడును. తగాదాల వలన అశాంతి అనుభవిస్తారు. ఇచ్చిపుచ్చుకొను వ్యవహారములలో జాగ్రత్త వహించవలెను. స్త్రీలకూ ఆరోగ్య సమస్యలు ఉన్నవి.",
          Path: Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "14",
        },

        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో కూడా ఏలినాటి శని ప్రభావం అధికం. వైవాహిక జీవనంలో అననుకూల ఫలితాలు. ద్వితీయ తృతీయ వారాలలో కొద్దిపాటి ఆరోగ్య సమస్యలు. భవిష్యత్ ప్రణాళికలు రచించుటకు లేదా పెద్ద నిర్ణయాలు తీసుకొనుటకు 21 వ తేదీ తదుపరి కొంత అనుకూలమైన కాలం. ఈ మాసంలో 9,10,11 తేదీలలో ప్రమాదం లేదా వాహనముల వలన సమస్యలు.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "5",
        },
      },
      {
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో శరీర ఆరోగ్య సంబంధ విషయాలలో అనుకూలత ఏర్పడును. శత్రుత్వములు తొలగును. మానశిక శాంతి లభించును. వృత్తిలో ప్రోత్సాహకర వాతావరణం ఉంటుంది. ద్వితియ వారం, తృతీయ వారములలో జీవితంలో చక్కటి పురోగత లభిస్తుంది. కోర్టు తీర్పులు అనుకూలం గా లభించును. నాలుగవ వారం నుండి చేపట్టిన కార్యములందు స్తబ్ధత. శ్రమ అధికం. ప్రయత్న పూర్వక విజయాలు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. ధనాదాయం కొంత తగ్గును. గృహ సంతోషములు సామాన్యం. ధన వ్యయం కొంత అదుపు తప్పుతుంది. కుటుంబంలో నూతన వ్యక్తుల చేరికలు ఏర్పడటానికి అవకాశం ఉన్నది. తలవంపులు ఎదుర్కోను సంఘటనలు ఉన్నవి. ఇతరులతో మాట్లాడు సందర్భాలలో మాట జాగ్రత్త అవసరం. ఈ మాసంలో 17, 18 తేదీలలో అనవసర తగాదాలు ఎదుర్కొందురు. నాలుగవ వారంలో మానసిక ప్రసాంతత మరియు కార్య సిద్ధి లభించును. ఈ మాసంలో 3, 11, 20 తేదీలు నిరుద్యోగుల ఉద్యోగ ప్రయత్నములకు మంచిది. ఆశించిన ఉద్యోగ ప్రాప్తి లభించును.",
          Path: Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "8",
        },

        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం ప్రధమ వారంలో మిత్రులతోనూ, అధికారులతోనూ విభేదములు ఎదురగును. ద్వితియ వారంలో మిశ్రమ ఫలితాలు పొందుతారు. తృతీయ వారంలో సంతాన సంబంధ విషయాల్లో సౌఖ్యత అనుభవిస్తారు. కుటుంబ వాతావరణంలో ఆనందాలు ఏర్పడుతాయి. భాత్రు వర్గం వారితో తగాదాలలో విజయం పొందుతారు. ఈ మాసంలో 20 వ తేదీ నుండి ఆర్ధిక పరమైన విషయాలలో అనుకూల ఫలితాలు పొందుదురు. ఆకస్మిక ధన లాభాములకు అవకాశం ఉన్నది.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో చక్కటి ప్రోత్సాహకరమైన వాతావరణం ఉంటుంది. అన్ని విధములా లాభ పడతారు. కెరీర్ పరంగా ఆశించిన స్థాయిలో అభివృద్దిని పొందుదురు. వంశ గౌరవం పెరుగుతుంది. నూతన వ్యాపార ప్రయత్నాలు విజయవంతం అవుతాయి. గౌరవ ప్రదమైన జీవనం లభిస్తుంది. దగ్గరి బంధువులకు సంబంధించిన ఒక అశుభ వార్త వినవలసి వస్తుంది. మొత్తం మీద ఈ మాసంలో ధనాదాయం కొంత వృద్ది చెందుతుంది. ప్రత్యర్ధులపై మీదే పై చేయి అవుతుంది.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },

        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో కూడా ఆరోగ్య సమస్యలు కొనసాగును. ఆశించిన స్థాయిలో కుటుంబం నుండి సహకారం లభించదు. ప్రభుత్వ ఉద్యోగులకు ఆశించని స్థాన చలనం ఏర్పడుతుంది. పిల్లలలో మానసిక చాంచల్యత ఆందోళన కలుగ చేస్తుంది. ఈ మాసంలో 17, 18 తేదీలలో ప్రయాణాలు చేయుట వాయిదా వేసుకొనుట మంచిది. ద్విచక్ర వాహన దారులకు వాహన ప్రమాద సూచన ఉన్నది. 20 వ తేదీ తదుపరి వృత్తి ఉద్యోగ వ్యపారాదులలో జయం లభిస్తుంది. సౌకర్య వంతమైన జీవనం అనుభవిస్తారు. మాసాంతానికి అన్నివిధములా పరిస్థితులు అనుకూలంగా ఉండును.",
          Path: Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "14",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో పరిస్థితులు అనుకూలంగా ఉండును. భూ లాభం పొందుతారు. అదృష్టం కలసివస్తుంది. వ్యపారాదులలో అధికమైన ధనప్రాప్తి లబిస్తుంది. సుఖసంతోషాలు సంపూర్ణంగా ప్రాప్తిస్తాయి. విందులు, వేడుకలలో పాల్గోనేదురు. వస్త్ర లాభం ఉన్నది. జీవిత భాగస్వామి తో మనస్పర్ధలు తొలగును. ఈ మాసంలో 16, 17, 18, 19 తేదీలు నూతన వ్యాపార వ్యవహారములకు మంచివి. అయితే లోహ సంబంధ వ్యాపారములు ఈ నెలలో మొదలు పెట్టుట మంచిది కాదు. ఈ మాసంలో చేయు ఇతర పెట్టుబడులు లాభించును.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "5",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో కూడా కొద్దిపాటి కష్టములు ఎదురగును. ఒక పర్యాయం తిరుమల శ్రీ వెంకటేశ్వర స్వామి వారి దర్శనం చేసుకొనుట మంచిది. ఆరోగ్య మందగమనం ఆందోళన కలిగించును. తృతీయ వారం నుండి ఆహార అలవాట్లు, వ్యసనాల పట్ల జాగ్రత్త అవసరం. ఈ మాసంలో 23,24, 27,29 తేదీలలో నూతన కార్యములు, వివాహము కొరకు చేయు ప్రయత్నములు లాభించును.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "8",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం మిశ్రమ ఫలితాలను కలిగించును. కారణం తెలియని మానసిక భయం ఏర్పడు సూచన. మనసున తొందరపాటుతనం వలన మీ బాధ్యతలు సక్రమంగా నిర్వర్తించ లేరు. పై అధికారుల వలన ఒత్తిడి అధికం అగును. ద్వితియ వారంలో సంతాన సంబంధ ప్రయత్నాలు ఫలించును. కుటుంబ ఖర్చులు కూడా అధికమగును. ఈ మాసంలో 14, 15 తేదీలలో చేయు ప్రయానములందు జాగ్రత్త వహించవలెను.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడతాయి. ఆత్మీయులకు సంబంధించిన ఒక విషయం ఆందోళన కలుగచేస్తుంది. ఆదాయం బాగుండును. ధన సమస్యలు తొలగును. ద్వితియ మరియు తృతీయ వారాలలో ఉత్తమ ఫలితాలు పొందుతారు. కుటుంబ జీవన ప్రమాణాలు పెంచగలుగుతారు. జీవిత భాగస్వామి తోడ్పాటు సంపూర్ణంగా లభించుట వలన మానసిక ప్రశాంతత ఏర్పడుతుంది. పుత్ర పౌత్రాభివృద్ధి అనుభవిస్తారు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "5",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో బాగుండును. ధనాదాయం పెరుగును. వృత్తి ఉద్యోగ వ్యపారాదులలో ప్రోత్సాహం. పై అధికారుల వలన కొద్దిగా అశాంతి. కీళ్ళ సంబంధమైన అనారోగ్యం బాదించును. కానీ ప్రమాదం ఉండదు. వ్యాపార విస్తరణకు ఈ మాసం మంచి సమయం. వ్యవహారములు సానుకూలంగా పూర్తి అగును. గృహంలో ఆకస్మిక శుభకార్యములు. ఆధిపత్య పోరు కొంత బాధించును.",
          Path: Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "14",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం పెరుగును. కుటుంబములో స్త్రీల అనారోగ్యం కొనసాగును. స్థిరాస్తి ఋణములు కొంతవరకూ తీరును. సోదర వర్గం వారికి ఖ్యాతి పెంపొందుతుంది. ప్రయాణ మూలక ఆరోగ్య భంగములు ఎదురగును. ఆహార విషయంగా సమస్యలు పొందుతారు. ఈ మాసంలో ఆశించిన ఉద్యోగ అవకాశములు లభించును. అన్య స్త్రీ సంబధాలు ఇబ్బందులను కలిగించును. ఈ మాసంలో 26, 27 తేదీలలో అవాంచిత తగాదాలు ఎదుర్కొందురు.",
          Path: Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "14",
        },

        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసం ఆశించిన శుభ ఫలితాలు కలుగచేయును. వ్యాపార, ఉద్యోగ వ్యవహారాలలో విజయం. పై అధికారుల వలన మన్ననలు. నూతన కార్యములను ప్రారంభించవచ్చు. అవకాశములు సద్వినియోగం చేసుకుందురు. కుటుంబ వాతావరణం అనుకూలంగా ఉండును. శ్రమకు తగిన గుర్తింపు. జీవన విధానాలలో పురోగతి. ఖర్చులు తగ్గును. కాలం కలసివచ్చును.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "5",
        },
      },
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. జీవిత భాగస్వామి మూలాన స్థిరాస్తి లేదా సువర్ణ లాభం లభిస్తుంది. ఊహించని విధంగా ఒక తగాదా వలన చికాకులు ఎదుర్కొంటారు. వృత్తి వ్యాపారముల వలన సామాన్య ధన సంపాదన. గృహంలో కుటుంబ సభ్యుల మధ్య సంఘర్షణాపూర్వక వాతావరణం ఏర్పడి ఉండుట మిమ్మల్ని బాధిస్తుంది. 10వ తేదీ తదుపరి నిరుద్యోగుల ఉద్యోగ ప్రయత్నాలు ఫలించును. తృతీయ వారంలో ఉద్యోగ జీవనంలో ఒత్తిడి , శ్రమ అధికం అవుతాయి. మాసాంతంలో వ్యసనాల వలన వృధా వ్యయం ఎదుర్కొంటారు. నిర్లక్ష్యం వలన కార్యవిఘ్నత బాదిస్తుంది. ప్రభుత్వ రంగ కాంట్రాక్టులు చేయు వారికి ఆర్ధిక వ్యవహారములలో అననుకూలత. ఈ మాసంలో 3, 6, , 24 , 25, 26 తేదీలు అనుకూలమైనవి కావు.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసం మిశ్రమ ఫలితాలను కలుగచేయును. ధనాదాయం బాగుండును. కానీ మానసిక ప్రశాంతత కోరవడును. మనోధైర్యాన్ని కోల్పోవు సంఘటనలు ఎదురగు సూచన. జీవిత భాగస్వామి తోడ్పాటు వలన కొంత ఉత్సాహం లభిస్తుంది. ద్వితీయ వారంలో యువకులు అప్రయత్నంగా కొన్ని సమస్యలను కొని తెచ్చుకొందురు. ఉద్యోగులకు శారీరక శ్రమ పెరుగును. ఈ మాసంలో అతి ధైర్యంతో ఆలోచనలు చేయకుండా ఉండుట మంచిది. కుటుంబ సభ్యుల మధ్య నమ్మకం పెరుగునట్టు వ్యవహరించుట అవసరమగును. చివరి వారంలో శైవ సంబంధ పుణ్యక్షేత్ర సందర్శన చేయుట మంచిది.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో ప్రధమ వారంలో చక్కటి శుభ ఫలితాలు ఏర్పడతాయి. తలపెట్టిన నూతన ప్రయత్నాలు తప్పక విజయవంతం అగును. వ్యాపార విస్తరణకు మిత్రులు కలిసి వస్తారు. అవసరమగు మూలధనం సమకుర్చూకొగలరు. ఉద్యోగ జీవనంలో మంచి ప్రోత్సాహకర సమయం ఏర్పడుతుంది. ధనాదాయం బాగుండును. నూతన ఆదాయ మార్గాలు లభిస్తాయి. 17, 18, 19 తేదీలలో గతకాలపు ఋణ బాధలు తొలగును. నూతన లక్ష్యాలను నిర్దేసించుకుంటారు. చివరి వారంలో సామజిక కార్యక్రమాలలో పాల్గొంటారు. మొత్తం మీద ఈ మాసంలో శుభ ఫలితాలు ఏర్పడును. వ్యక్తిగత జీవనంలో మానసిక సంతోషం, కోరుకున్న విధంగా తృప్తి లభించును.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం కొంత వరకు తగ్గును. ఉద్యోగ జీవనంలో స్థానచలనం ఏర్పడును. విదేశీ సంబంధ నివాస ప్రయత్నాలు చేయువారికి ఈ మాసం అనుకూలమైన కాలం. వాహనాల వలన ధనవ్యయం అధికంగా ఏర్పడును. ఖర్చు విషయంలో జాగ్రత్త అవసరం. మానసిక చికాకులు బాధించు సూచన. విద్యార్ధులు శ్రమించవలెను. వ్యాపార వర్గం వారు 14 తేదీ తదుపరి లాభాపడుదురు. ఉద్యోగ ప్రయత్నాలు చేయువారికి స్థిరమైన ఆలోచనా విధానం అవసరం. మిత్రుల వలన సమస్యలు ఏర్పడును. కుటుంబ సభ్యుల సహాయం అవసరమగు సంఘటనలు ఎదురగును. ఈ మాసంలో 5, 9, 13 తేదీలు అనుకూలమైనవి కావు.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో తలపెట్టిన ప్రతీ కార్యం, ఆలోచన లాభించును. గతకాలపు సమస్యలు తొలగును. కార్య సిద్ధి, ఆర్ధిక అభివృద్ధి ఏర్పడును. వృధా ధనవ్యయం తగ్గును. చేతిపై ధనం నిలుచును. శారీరక స్వస్థత ఏర్పడును. గృహ సంబంధ లేదా భూ సంబంధ స్థిరాస్థి లాభం ఏర్పడును. వివాదాలు తొలగిపోవును. అరుదైన అవకాశములు లభించును. శుభవార్తలు వింటారు. సాంస్కృతిక అభిరుచులు మీకు చక్కటి గుర్తింపును ఏర్పరచును. 24 వ తేదీ నుండి 29 వ తేదీ మధ్య కాలంలో సింహ రాశికి చెందిన స్త్రీలకు గర్భ లేదా ఉదర సంబంధ సమస్యలున్నాయి.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో పరిస్థితులు క్రమంగా మెరుగవును. ధనాదాయం క్రమంగా పెరుగును. వృత్తి ఉద్యోగ వ్యాపారాదులలో ప్రోత్సాహం లభించును. ద్వితీయ వారంలో ఉద్యోగ జీవనంలో ఆశించిన మార్పులు ఏర్పడును. పై అధికారుల వలన లాభం ఉంటుంది. స్త్రీలకు కీళ్ళ సంబంధమైన అనారోగ్య సమస్యలు ఇబ్బందులు కలుగ చేయును. ఈ మాసం భాగస్వామ్య వ్యాపార విస్తరణకు మంచి సమయం. కుటుంబ విషయ సంబంధ ప్రయాణాలు ఏర్పడతాయి మరియు లాభకరంగా పూర్తి అగును. చివరి వారంలో వివాహ సంబంధ అనుకూలత ఉన్నది. గృహంలో ఆకస్మిక శుభకార్యములు నిర్వర్తిస్తారు.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో పరిస్థితులు అనుకూలంగానే ఉంటాయి. అపరిష్కృతంగా ఉన్న తగాదాలు పరిష్కారమగును. ధనాదాయం ఆశించినంత ఉండును. నూతన ఉద్యోగ ప్రయత్నాలు కష్టం మీద విజయవంతం అగును. కుటుంబంలో మీ మాటకు విలువ పెరుగుతుంది. నూతన వ్యాపార ప్రయత్నాలు విజయవంతం అవుతాయి. ముఖ్యంగా వాహన సంబంధ వ్యాపారాలు చేయువారికి బాగా కలసి వచ్చును. ఈ మాసంలో 9 వ తేదీ నుండి 17 వ తేదీ మధ్య కొద్దిపాటి వృధా ధన వ్యయం మరియూ అనారోగ్య సూచన. 19, 20, 21 తేదీలు వివాహ సంబంధ ప్రయత్నాలకు అనుకూలం. 27 నుండి మాసాంతం వరకూ ఉద్యోగ జీవనంలో శ్రమ అధికమగును. ఒత్తిడులు ఎదుర్కొందురు.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో కార్య నిర్వహణలో అందరికన్నా ముందు ఉండి ప్రతిభతో ఉన్నత అధికారులను ఆకర్షించగలరు. వాయిదా పడుతున్న పనులు పూర్తీ అగును. జీవిత భాగస్వామి వలన ధన ప్రాప్తి లేదా స్థిరాస్తి లాభం ఏర్పడును. నూతన వాహన యోగం ఉన్నది. తలపెట్టిన పనులు ఆశించిన విధంగా పూర్తీ అగును. అన్ని రంగాల వారికి ఈ మాసం కొంత ఆశాజనకంగానే ఉండును. పట్టుదల వదిలి ఆలోచించడం మంచిది. వ్యాపార రంగం వారు ఆశించిన ధనాదాయం పొందుదురు. విద్యార్ధులకు ఉన్నత విద్యా అవకాశములు లభించును.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ధనాదాయం బాగుండును. వ్యాపారాలు ప్రోత్సాహకరంగా ముందుకు సాగును. నూతన వాహన సౌఖ్యం ఏర్పడును. సంతాన ప్రయత్నాలు ఈ మాసంలో విజయవంతం అగును. ఉద్యోగ జీవులకు సాధారణ ఫలితాలు. సమయానుకూలత చూసుకొని ఉన్నత అధికారులతో సంప్రదింపులు చేయవచ్చు. మిత్ర వర్గం సలహాల వలన మేలు జరుగుతుంది. శ్రమ తగ్గుతుంది. ఆర్ధిక వ్యయం అదుపులోకి తెచ్చుకొంటారు. 17, 18, 19, 20 తేదీలలో ఒక అశుభ వార్త వినడానికి సూచనలు కలవు. 23 వ తేదీ తదుపరి నూతన గృహ నిర్మాణ ప్రయత్నాలకు అనువైన కాలం. మహిళలకు ఆరోగ్యం సహకరిస్తుంది.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. జాతకులకు దీర్ఘకాలిక ఆరోగ్య సమస్యలు మరింతగా ఇబ్బంది కలిగించును. కుటుంబ సభ్యుల నుండి ఆశించిన సహకారం ఉండదు. జీవిత భాగస్వామి సంబంధిత బంధు వర్గం వారితో విరోధములు ప్రారంభం అగును. గృహ సంబంధ స్థాన చలన ప్రయత్నాలు ద్వితీయ వారంలో ఫలించును. ధనిష్ఠ జన్మ నక్షత్ర జాతకులు మానసిక చాంచల్యత వలన చక్కటి అవకాశములను కోల్పోవుదురు. ఈ మాసంలో 18, 19 తేదీలలో వాహన ప్రమాద సూచన ఉన్నది. 22 వ తేదీ తదుపరి వృత్తి ఉద్యోగ వ్యపారాదులలో జయం ఏర్పడును. కార్యాలయంలో కోరుకున్న సౌకర్యములు లభించును. చివరి వారంలో అన్ని విధములా పరిస్థితులు అనుకూలంగా ఉండును.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో ఆరోగ్య మందగమనం ఆందోళన కలిగించును. తృతీయ వారం నుండి ఆహార అలవాట్లు , వ్యసనాల పట్ల జాగ్రత్త అవసరం. ఈ మాసంలో ధనాదాయం సామాన్యం. జీవన విధానంలో మార్పులు ఏర్పడును. కుటుంబ వ్యవహారాలు చికాకులు కలిగించును. కుటుంబ సభ్యులు మీ సలహాలు పాటించరు. నూతన గృహ నిర్మాణ విషయాలలో ఎదుర్కొంటున్న ఇబ్బందులు పరిష్కారం అగును. నూతన ఉద్యోగ అవకాశములు లభించును. స్థిరచిత్తంతో పనిచేయుదురు. కీర్తి లభిస్తుంది. సాహసోపేత నిర్ణయాలు తీసుకొంటారు. 22, 23, 24 తేదీలలో వృధా వ్యయం పొందుతారు.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో పితృ వర్గానికి చెందిన ఆత్మీయులకు సంబంధించిన ఒక అశుభ వార్త వినడానికి సూచనలు ఉన్నాయి. ధన ఆదాయం అవసరాలకు తగిన విధంగా ఉండును. స్త్రీలకు ఆరోగ్య సమస్యలు తొలగును. ద్వితియ మరియు తృతీయ వారాలలో ఉత్తమ ఆశించిన ఫలితాలు ఏర్పడును. కుటుంబ జీవన ప్రమాణాలు పెరుగును. సమాజంలో నూతన హోదా ను ఏర్పాటుచేయుదురు. జీవిత భాగస్వామి నుండి సంతాన విషయాలలో తోడ్పాటు లభించును. ఉద్యోగ వ్యాపార వ్యవహారాలు ఆశించిన రీతిలో అనుకూలంగా కొనసాగును. చివరి వారంలో చక్కటి మానసిక ప్రశాంతత లభించును. ఈ మాసంలో 24, 25, 26 తేదీలలో యుక్త వయస్కులైన పుత్ర సంతానానికి ఆరోగ్య సమస్యలు.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
      },
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. మీ ప్రమేయం లేకుండా అవమానములు, అపవాదులు భరించవలసి వచ్చును. ధనాదాయం సామాన్యం. రాజకీయంగా ఆసక్తి కలిగివున్న వారికి ఆశించిన పదవులు, ఉన్నత హోదా పొందుటకు ఇది అనువైన కాలం. విదేశీ సంబంధ స్థిర నివాసం కొరకు చేయు ప్రయత్నాలు ద్వితీయ వారంలో ఫలించును. తృతీయ వారంలో నిద్రలేమి సంబంధిత ఆరోగ్య సమస్యల వలన సమస్యలు ఎదుర్కొంటారు. మాసాంతంలో మీ ప్రతిభకు తగిన ప్రోత్సాహం లభిస్తుంది. నూతన గృహ నిర్మాణ సంబంధ పనులు అనుకున్న విధంగా ముందుకు సాగుతాయి. ఉద్యోగస్తులకు స్థానచలనం ఏర్పడును. ఈ మాసంలో 8 , 10 , 14, 17 తేదీలు అనుకూలమైనవి కావు. పేర్కొన్న తేదీలలో ప్రయాణాలు చేయుట అంత లాభించదు.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో ధన సంబంధ విషయాలు ప్రతికూలంగా ఉండును. ఉద్యోగ జీవనములో తీవ్రమైన ఒత్తిడి ఏర్పడు సూచన. ఆరోగ్య పరంగా ముత్ర సంబంధ సమస్యలు, శిరోబాధ బాధించు సూచన. మొత్తం మీద ఈ మాసంలో ధనాదాయం కొంత తగ్గును. చేపట్టిన పనులు , నూతన ఆరంభములు ప్రతిఘటనలతో కొనసాగును. ఇతరుల సహాయ సహకారాలకోసం ఎదురుచుడవలసి వస్తుంది. ఈ మాసంలో సహనంతో వ్యవహరించుట అన్ని విధాల మంచిది. ఈ మాసంలో 16, 20, 23, 29 తేదీలలో ఆర్ధిక - ఆరోగ్య విషయాలందు జాగ్రత్తగా ఉండుట మంచిది.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో ఆశించిన విధంగా నూతన జీవన యోగాలు ప్రారంభం అవుతాయి. ఈ మాసంలో ధనాదాయం సామాన్యం. ఉద్యోగ జీవనంలో ఆశించిన ఉన్నతి లేదా వేతన పెరుగుదల లభిస్తుంది. విద్యార్ధులకు చక్కటి సాంకేతిక విద్యలో ప్రవేశం లభించును. 9, 10, 11, 12 తేదీలలో వివాహ సంబంధ లేదా సంతాన సంబంధ శుభవార్తలు వింటారు. పరోపకారం వలన ఇతరుల మన్ననలు పొందుతారు. వృత్తి జీవనంలో కోరుకున్న గుర్తింపు లభిస్తుంది. నూతన స్నేహ సంబంధాల వలన కాలం ఉల్లాసంగా గడుస్తుంది. కుటుంబ సభ్యుల వినోద కార్యక్రమాలకై ధనం ఖర్చు అగును.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసం కళారంగం లోని వారికి, మీడియా రంగంలో పనిచేయు వారికి చక్కటి ఫలితాలు కలుగచేయును. ఆర్ధికంగా లాభించును. వివాహ ప్రయత్నాలు సులువుగా విజయం పొందును. వైవాహిక జీవనంలో సంతోషకరమైన సంఘటనలు ఏర్పడును. పుణ్యక్షేత్ర సందర్శన సూచనలు కలవు. ఈ మాసంలో ధనాదాయం సామాన్యం. వారసత్వ సంబంధ సమస్యలు తగ్గుముఖం పట్టును. 12 నుండి 19 వ తేదీ మధ్య కాలం ఉద్యోగ జీవనంలో ఒడిదుడుకులు లేదా ఆకస్మిక ఉద్యోగ నష్టం ఎదురగు సూచన ఉన్నది. 20వ తేదీ తదుపరి చేపట్టిన పనులు నిదానంగా పూర్తీ అగును. తీసుకొనే నిర్ణయాలలో ఆవేశ పడకుండా ఉండటం అవసరం. ఈ మాసంలో కాలి నరములకు సంబంధించిన ఆరోగ్య సమస్యలకు అవకాశం ఉన్నది. మొత్తం మీద ఈ మాసంలో వ్యాపార రంగంలోని వారికి సగటు ఆదాయం తగ్గును.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో ప్రధమ వారంలో మిత్రులతోనూ , అధికారులతోనూ విభేదములు ఏర్పడును. ద్వితియ వారంలో మిశ్రమ ఫలితాలు ఉంటాయి. ధనాదాయం కొంత తగ్గును. తృతీయ వారంలో సంతానం వలన సౌఖ్యత ఏర్పడును. కుటుంబ వాతావరణంలో ఆనందకర సంఘటనలు ఉన్నాయి. క్రీడా రంగంలోని వారికి చక్కటి విజయములు లభించును. 20 వ తేదీ తదుపరి ఆర్ధిక పరమైన విషయాలలో కొంత అననుకూలత ప్రారంభమగు సూచనలు ఉన్నాయి. పుబ్బ నక్షత్ర జాతకులు ఈ మాసంలో ఆరోగ్య విషయంలో జాగ్రత్తగా ఉండవలెను.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో ఆకస్మిక అనారోగ్య సమస్యల వలన ఒత్తిడి ఎదుర్కుంటారు. ధనాదాయం ఆశించిన విధంగా బాగుండును. అవసరములకు రావలసిన ధనం చేతికి వచ్చును. 12 వ తేదీ నుండి 16 వ తేదీ మధ్య కాలంలో ప్రయనాలందు జాగ్రత్తగా ఉండాలి. ఈ కాలంలో వాహన ప్రమాద సూచన ఉన్నది. తృతీయ వారం సామాన్య ఫలితాలను కలుగ చేయును. చివరి వారంలో అధిక ధన వ్యయం మరియు సొంత ఆరోగ్య విషయాలలో చికాకులను కలుగచేయును. ఉద్యోగ ప్రయత్నాలలో ఆశా భంగములు ఎదుర్కొంటారు. చివరి నిమిషంలో అవకాశములు కోల్పోతారు. 24, 25, 26 తేదీలలో ఉద్రేక సంభాషణల వలన ఒక నష్టం ఎదుర్కొందురు. సంభాషణల్లో ఉపయోగించు పదాల పట్ల జాగ్రత్త అవసరం.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసం ప్రధమ అర్ధ భాగంలో వైవాహిక జీవనంలో పట్టుదల, ఉద్రిక్తతలు ఎదుర్కొందురు. భూ సంబంధ కొనుగోలు ప్రయత్నాలు లాభించును. ధనాదాయం సామాన్యం. వివాహ మరియు సంతాన ప్రయత్నాలు విఫలమగును. ఉన్నత అధికారులను ప్రతిభతో ఆకట్టుకుంటారు. గత మాసపు ఉద్యోగ జీవన ఒడిదుడుకులు తొలగును. పారిశ్రామిక వర్గాలకు ఉత్సాహవంతంగా ఉండును. నిరుద్యోగులు మరింత దీక్షా , పట్టుదలను ప్రదర్శించాలి. చివరి వారంలో ఆత్మీయులతో ఉన్న వివాదాలు సర్దుబాటు చేసుకొందురు. ఉద్యోగులు అనుకున్న లక్ష్యాలను కష్టం మీద చివరి నిమిషానికి పూర్తీ చేయగలుగుతారు. స్నేహితుల సహకారం లభించును. రాజకీయ రంగంలోని వారికి ఊహించని అవకాశములు లభించును. ఈ మాసంలో దూర ప్రాంత ప్రయాణాలు లాభించును.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },

        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో నూతన ఆలోచనలు ఎలాంటి ప్రతి ఘటనలు లేకుండా అమలు చేయగలరు. వృత్తి వ్యాపారాలు ఆశించిన విధంగా నడుచును. అవివాహితుల వివాహ ప్రయత్నాలు లాభించును. బాగా తెలిసిన వ్యక్తుల వలన చక్కటి సంబంధాలు కుదురును. ఉద్యోగులకు దూర ప్రాంత ధనాదాయం ప్రాప్తించును. స్పెక్యులేషన్ లాభించును. పని చేయు కార్యాలయంలో కపట స్వభావులు ఎదురగుదురు. కుటుంబ సమస్యల తీర్పులలో నిర్ణయాత్మకంగా ప్రధాన పాత్ర వహిస్తారు. నూతన ఒప్పందాలు లభిస్తాయి. ఈ మాసంలో స్థాన చలన అవకాశములు లేవు. మిత్ర వర్గంలో గౌరవ ప్రతిష్టలు పెరుగును. ఈ మాసంలో 17, 18 తేదీలు మినహా మిగిలిన రోజులు అనుకూలంగానే ఉండును.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో వ్యాపార విస్తరణ ప్రయత్నాలు , నూతన ఉద్యోగ జీవన ప్రయత్నాలు విజయవంతం అగును. గృహంలో హోమాది పూజా కార్యక్రమాలు నిర్వహించదేరు. కుటుంబానికి పూర్వ వైభవం తెస్తారు. కుటుంబానికి ధనధాన్య లాభాలు ఉన్నాయి. వ్యక్తిగత జాతకంలో శని గ్రహం బలంగా ఉన్నవారికి రాజకీయ రంగ ప్రవేశానికి ఈ మాసం అనువైన కాలం. అధికారుల ఒత్తిడి తగ్గును. 8 సంవత్సరాలలోపు వయస్సు ఉన్న చిన్న పిల్లలు ఒక ఆపద నుండి బయటపడతారు. శత్రుత్వాలు తొలగి కోర్టు వివాదాలు పరిష్కారం అవుతాయి. నిత్య జీవన విధానంలో ఆశించిన మార్పులు చేసుకోగలరు. నూతన ఆరోగ్య పద్దతులు అలవాటు చేసుకొంటారు. మొత్తం మీద ఈ మాసంలో ధనాదాయం ఆశించినంతగా ఉండును.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో అన్ని విధాలా ఆదాయం బాగుండును. వ్యాపార విస్తరణకు, నూతన ఆలోచనలు అమలు చేయుటకు ఈ మాసం కలసి వచ్చును. విదేశీ ప్రయత్నములు చేయుటకు, వివాహ ప్రయత్నములు - సంతాన ప్రయత్నములకు ఈ మాసం చక్కటి అనువైన కాలం. ఈ మాసంలో 3 వ తేదీ నుండి 15 వ తేదీ మధ్య కాలంలో ఆకస్మిక ప్రయాణములు ఏర్పడును. సోదర సోదరి వర్గీయులకు సంబందించిన ఇబ్బందుల వలన మానసిక చికాకులు. 19,20,21 తేదీలలో మిత్ర సంబంధ వ్యవహారములలో గౌరవ నష్టం. అపవాదులు బాధించును. చివరి వారంలో శారీరక శ్రమ అధికం అగును. నరముల సంబంధిత సమస్యలు ఇబ్బంది కలిగించును.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో కూడా అనారోగ్య సమస్యలు ఇబ్బందులు కలిగించును. ధన ఆదాయం సామాన్యం. ప్రధమ వారంలో విమర్శలు ఎదుర్కొందురు. శుభకార్య సంబంధ విషయాల వలన వ్యయం అంచనాలను మించుతుంది. ఖర్చుపై నియంత్రణ అవసరం. వ్యాపార వర్గం వారు ఆకస్మిక ధననష్టం కలుగకుండా జాగ్రత్తగా ఉండాలి. ఉద్యోగ జీవనంలో స్థాన చలన ప్రయత్నాలు విజయవంతం అగును. లక్ష్యాలను చక్కగా పూర్తీ చేయగలరు. సంతానానికి ఉత్తమ జీవితం లభిస్తుంది. స్త్రీలకు భోగభాగ్యాలు ఉన్నాయి. సోదర వర్గం వలన లాభం. కళత్ర విషయాలు కొంత ప్రతికూలత ఎదుర్కొనును.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో వివాహ జీవనంలో ఎదుర్కొంటున్న గత కాలపు సమస్యలు తగ్గును. వ్యక్తిగత జీవన పరిస్థితులు కూడా మెరుగుపడును. కోర్టు తగాదలలో అనుకూలత ఏర్పడును. గృహంలో బంధు వర్గం కలయికల వలన సంబంధాలు మెరుగుపడును. వాయిదా పడుతున్న పనులు పూర్తి చేయగలుగుతారు. ఆర్ధికంగా వ్యయం ఎక్కువ అగుటకు సూచనలు ఉన్నాయి. 22 వ తేదీ తదుపరి నూతన ఉద్యోగ ప్రయత్నాలలో సంతోషకర వార్తలు వింటారు. వ్యాపార వర్గం వారికి ఆదాయంలో పెరుగుదల ఏర్పడును. సంతాన ప్రయత్నాలు సఫలమగుటకు దైవ ఆశీస్సులు అవసరం.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
      },
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో కూడా మిశ్రమ ఫలితాలు ఎదురగును. వ్యక్త్రిగత జీవనంలో అవిధేయత వలన సమస్యలు పొందుతారు. ద్వితియ తృతీయ వారములలో ఉద్యోగ మరియు వ్యాపారములలో ఆఖస్మిక ఇబ్బందులు ఎదుర్కొంటారు. శ్రమకు తగిన ఫలితం లభించదు. అతిగా ఆశ పడుట వలన మానసికంగా ఇబ్బంది ఎదుర్కొంటారు. చివరి వారంలో ఉన్నత హోదాలో ఉన్నవారితో పరిచయాలు ఏర్పడును. వివాహ ప్రయత్నాలలో అడ్డంకులు. ఆర్ధిక పరంగా కొంత వృద్ది కనిపిస్తుంది. వ్యవసాయ సంభందిత రంగంలోని వారికి తీవ్ర వ్యయం ఏర్పడును. విద్యార్ధులకు ఈ మాసం అంత మంచి కాలం కాదు. విద్యావిఘ్నములను ఎదుర్కోను సూచన. మాసం మొత్తం మీద ధనాదాయం సామాన్యం.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో ధన సంబంధమైన సమస్యలు కొద్దిగా తగ్గును. కాని నూతన వ్యవహారాలు, వివాహ సంబంధ ప్రయత్నాలు మాత్రం సమస్యలు ఎదుర్కొనును. జీవిత భాగస్వామి బంధువులతో శతృత్వం ఏర్పడు సూచన ఉన్నది. వ్యక్తిగత జీవనంలో కొద్దిపాటి సమస్యలు. కుటుంబ బంధాలపై నమ్మకం దెబ్బతినును. చేపట్టిన పనులను మధ్యలోనే వడిచిపెట్టుదురు. వ్యతిరేక ఫలితాల వలన ఆత్మవిశ్వాసం కొరవడుతుంది. కుల వృత్తులపై ఆధార పడిన వారికి తీవ్ర సమస్యలు. ఋణ బాధలు. తృతియ , చతుర్ధ వారములలో సంతాన సంబంధ శుభ వార్త. మొత్తం మీద ఈ మాసం అంత అనుకూలమైనది కాదు.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో కుటుంబ వివాదాలు ఉన్నా ఆర్ధిక లాభాలు ఉన్నాయి. ప్రభుత్వ ఉద్యోగ ప్రయత్నాలు చేస్తున్న వారికి శుభవార్త. ఐటి రంగంలో పని చేస్తున్న వారికి నూతన ప్రాజెక్ట్లు లభిస్తాయి. నూతన అధికారాలు పొందుతారు. వాహన యోగం ఉన్నది. గృహంలో కుటుంబ సభ్యులతో సామరస్యంతో వ్యవహరించాలి. విదేశీ జీవన ప్రయత్నాలలో సమస్యలు ఎదురగును. తృతియ వారంలో వృధా ప్రయాణాలు చేస్తారు. సమయ పాలనలో విఫలమవుతారు. శారీరక శ్రమ వలన అశాంతి కలుగును. చివరి వారంలో మీ చేతిపై పుణ్యకార్యములు జరుగును. శుభ కార్యములకు ధనం వ్యయమగును. స్త్రీలకు స్థిరాస్తి సంబంధ లాభం ఏర్పడును. గురువుల ఆశీస్సులు లభించును. ఈ మాసంలో 2, 17, 23, 24 తేదీలలో పితృ వర్గీయులకు ఆరోగ్య సమస్యలు.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో సంతాన ప్రయత్నాలలో కొంత కాలంగా ఏర్పడుతున్న అడ్డంకులు తొలగును. పుత్ర సంతాన సౌఖ్యమునకు సూచనలు కలవు. నూతన ఆలోచనలు కార్య రూపం దాల్చును. మిత్ర వర్గంతో ఉన్న వివాదములు తొలగును. స్వ శక్తితో గౌరవ ప్రధమైన జీవనాన్ని ఏర్పరచుకొందురు. ఉద్యోగ జీవనంలో అభీష్టాలు సిద్ధించును. నూతన అధికారములు లభించును. ఈ మాసం వ్యాపార వర్గం వారికి మంచి ప్రోత్సాహకరమైన కాలం. ఈ మాసంలో 12 నుండి 16 వ తేదీల మధ్య కాలంలో వ్యక్తిగత జీవనమునకు సంబంధించిన ఒక శుభ పరిణామం ఏర్పడును. ఈ మాసంలో ధనాదాయం బాగుండును. స్థిరాస్తి సంబంధ వ్యవహారాలు కలసివచ్చును. 24 తేదీ తదుపరి కుటుంబ సభ్యుల ఆరోగ్య సమస్యల వలన అశాంతి, ధనవ్యయం ఎదురగు సూచన.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో జీవనంలో గౌరవ హోదాలు పెరుగును. నిరుద్యోగులకు ఉద్యోగ ప్రాప్తి లభించును. ధనాదాయంలో చక్కటి స్థిరత్వం పొందుతారు. కుటుంబ సంతోషములు ఉన్నాయి. ఆశించిన విధంగా స్థానచలనం పొందుతారు. అధునాతన వస్తువులను అమర్చుకోగలుగుతారు. ద్వితీయ వారం తదుపరి నూతన వ్యాపారములు ప్రారంభించుటకు అనుకూలత లభించును. మాసాంతంలో సంతాన భవిష్యత్ గురించిన ఆందోళన ఏర్పడు సూచన.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో ధనాదాయం బాగుండును. వ్యక్తిగత జీవితంలో శుభకరమైన ఫలితాలు ఏర్పడును. నూతన వ్యాపార ప్రారంభకులకు కార్య సిద్ధి లభిస్తుంది. స్త్రీలు ఆశ్చర్యకరమైన బహుమతులు పొందుతారు. ప్రధమ, ద్వితీయ వారములలో తలపెట్టిన ప్రయాణాలు సౌఖర్య్యవంతంగా పుర్తి అగును. తృతీయ వారంలో అఖస్మిక శారీరక రుగ్మతలు చికాకులను కలుగచేయును. జీవిత భాగస్వామి తరపు బంధువులతో అనిశ్చితి ఏర్పడును. వారి నుండి కృతజ్ఞతను ఆశించ వద్దు. చివరి వారం సామాన్య ఫలితాలను కలుగచేయును. మొత్తం మీద ఈ మాసంలో ధనానికి లోటు ఏర్పడదు.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో నూతన గృహ నిర్మాణ ప్రయత్నాలు ప్రారంభిస్తారు. వ్యక్తిగత జీవన విషయాలలో సౌఖ్యం ఉన్నది. దీర్ఘకాలిక ఆరోగ్య సమస్యలనుండి ఉపశమనం లభించును. చేపట్టిన పనులు పూర్తి చేయగలుగుతారు. సామజిక కార్యక్రమాలలో మీ శ్రమకు చక్కటి గుర్తింపు లభిస్తుంది. గౌరవింప బడతారు. స్థాన చలన ప్రయత్నాలు , ఉద్యోగ మార్పిడి ప్రయత్నాలు లాభించును. నిరుద్యోకులకు ద్వితీయ, తృతీయ వారములలో మంచి అవకాశములు లభించును. రాజకీయ పలుకుబడి పనికి వచ్చును. పేరు - ప్రతిష్టలు సంపాదించుకొందురు. స్త్రీలకు నూతన వస్తు - ఆభరణాలు ప్రాప్తించును. మొత్తం మీద ఈ మాసంలో ధనాదాయం బాగుండును. వ్యాపార వ్యవహారములు ఆశించిన విధంగా కొనసాగును.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో ప్రారంభ మూడు వారాలు గత మాసంలో వలె శుభ ఫలితాలు పొందుదురు. ఆశించిన ధనాదాయం చేతికి వచ్చును. కోర్టు వివాదాలు, తగవులు పరిష్కారం అగును. మనసులో ఉన్న ఒక బలమైన కోరిక నెరవేరును. అన్ని విధములా కుటుంబ సహకారం లభించును. ఆలోచనా విధానం ఉన్నతంగా ఉంటుంది. నూతన భాద్యతలను తీసుకొందురు. ఆర్ధిక లక్ష్యాలను చివరి నిమిషంలో పూర్తీ చేయగలుగుతారు. చివరి వారంలో ఖర్చులు విపరీతమగును. చేతిలో ధనం నిలుచుట కష్టం. ముఖ్యంగా కుటుంబ సభ్యుల వలన వ్యయం అధికం. ప్రైవేటు ఉద్యోగులకు పనులు అస్తవ్యస్తం అవుతాయి. 23 వ తేదీ తదుపరి వేడుకలకు , విందు వినోదాలకు దూరంగా ఉండుట మంచిది. మాసాంతంలో పెద్దలతో వాగ్వివాదాలకు అవకాశం ఉన్నది.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో కూడా అనుకూల ఫలితాలు కొనసాగును. బంధు వర్గం సహకారం వలన చక్కటి వివాహ సంబంధాలు లభించును. వ్యక్త్రిగత వైవాహిక జీవనంలో కూడా అపార్ధాలు తొలగును. సౌఖ్యం ఏర్పడును. విందు వినోదాలలో పాల్గొంటారు. విలువైన వస్తువులు లభిస్తాయి. నూతన ప్రణాళికలు సిద్ధం చేసుకొందురు. ఉద్యోగ జీవనంలో ఆశించిన మార్పులు పొందుతారు. నూతన కాంట్రాక్టులు , విదేశే జీవన అవకాశాలు లభిస్తాయి. నూతన పరిచయాలు నిరుత్సాహ పరుచును. సేవా రంగంలోని వారు లక్ష్యాలను చేరుకొందురు. మొహమాటం వలన నష్టపోదురు. ఈ మాసంలో ప్రయాణాలు కలసిరావు. అవాంతరాలను ఏర్పరచును.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో ఉద్యోగ జీవనంలో ఆశించిన విధంగా పై అధికారుల గుర్తింపు లభిస్తుంది. ప్రమోషన్లకు అవకాశములు ఉన్నాయి. ఉద్యోగ జీవనంలో ఉన్నతి - నూతన భాద్యతలు లభిస్తాయి. వ్యాపారాదులలో ఆశించిన స్థాయిలో లాభములు కొనసాగును. కుటుంబములో మీ మాట విలువ పెరుగును. విద్యార్ధులకు సంకల్ప సిద్ధి ఏర్పడును. తృతీయ వారంలో విహార యాత్రలు, విందు - వినోదాల వలన సంతోషంగా సమయం గడచును. జీవిత భాగస్వామితో వ్యక్తిగత జీవనంలో సోఖ్యం. బాగా ఎదిగిన సంతానం వలన ఆర్ధిక లేదా వాహన ప్రయాణ సౌఖ్యం అనుభవిస్తారు. కుటుంబ భాద్యతలను తీసుకొంటారు. స్థిరాస్తి తగాదాలలో రాజీ ప్రయత్నాలు ఫలించును.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో ప్రారంభం నుండి ప్రతీ కార్యం ఇబ్బందులను ఎదుర్కొనును. సమస్యలతో పనులు కొనసాగును. ధనాదాయం సామాన్యం. బంధు వివాదాలు, నూతన పరిచయాల వలన నష్టం ఎదురగును. ముఖ్యంగా పితృ వర్గీయులతో మాట్లాడునపుడు జాగ్రత్త అవసరం. 10వ తేదీ తదుపరి వ్యక్తిగత జీవితంలో నిరాశకు గురిచేయు సంఘటనలు ఎదుర్కొందురు. భవిష్యత్ గురించిన ఆలోచనలు చేయాలి. అతి ధైర్యం పనికిరాదు. పనులు వాయిదా వేయకండి. పట్టుదల ప్రదర్శించాలి. స్థిరాస్తి వ్యవహారాలకు ఈ మాసం అనుకూలమైనది కాదు. ఈ మాసంలో 6, 7, 11, 13 తేదీలలో ఇబ్బందులు అధికమగును.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసం మొదటి వారంలో నూతన ఆదాయ మార్గములు లభించును. వ్యాపారములు మంచి అభివృద్ధికర మార్గంలో కొనసాగును. భాత్రు వర్గంతో ఆధిపత్య సంబంధ విరోధములు ఏర్పడును. ద్వితీయ వారం సామాన్య ఫలితాలను కలుగచేయును. తృతీయ వారంలో మిత్రుల వలన ఒక ఆర్ధిక నష్టం ఎదుర్కొందురు. కుటుంబ పరంగా సిద్ధంగా లేని ఖర్చులు ఎదుర్కొందురు. వివాహ ప్రయత్నములలో ఆశాభంగం ఏర్పడును. ఈ మాసంలో బ్యాంకు లేదా బంగారు ఆధారిత ఋణములు తీసుకొనుట, ఇతర ఆర్ధిక పరమైన విషయాలలో పాల్గొనుట అంత మంచిది కాదు. కుటుంబ పెద్దల సలహా పాటించండి.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },





      },
      //JULY
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం కొంత వృద్ధి చెందును. వృత్తి వ్యాపార ఉద్యోగ జీవనాలు బాగానే ఉండును. సంఘంలో చక్కటి పేరు ప్రఖ్యాతలు లభించును. ప్రభుత్వ ఉద్యోగులకు సన్మానములు ఏర్పడును. కుటుంబ సభ్యుల గౌరవం పొందుతారు. నూతన ఉద్యోగ అవకాశములు లభిస్తాయి. కోర్టు తగాదాలు, వివాహ సంబంధ మరియు స్థిరాస్థి సంబంధ వివాద విషయములందు లాభములు పొందుతారు. 15, 16, 17, 18 తేదీలలో శుభ వార్తలు వినుదురు. చివరి వారంలో ఆకస్మిక ప్రయాణాలు ఏర్పడు సూచన. మొత్తం మీద ఈ మాసం అన్ని వర్గముల వారికి అనుకూలంగా ముగుస్తుంది.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో ఆర్ధిక విషయాలు కొంత బాగుంటాయి. వ్యాపారములలో ఆదాయం గత రెండు మాసాల కన్నా పెరుగుతుంది. చక్కటి ప్రోత్సాహకర వాతావరణం ఏర్పడుతుంది. వృషభ రాశి జాతకులు ఉద్యోగ రీత్యా చేయు స్థాన చలన ప్రయత్నాలు, విదేశీ ఉద్యోగ ప్రయత్నాలు విజయవంతం అవుతాయి. ఆశించిన ధనాదాయం లభించును. వృధా వ్యయం కూడా తగ్గుతుంది. ప్రభుత్వ ఉద్యోగ జీవనం వారికి ప్రధమ, ద్వితీయ వారాలలో కొన్ని పొరపాట్లు చేయుట వలన ఇబ్బందులు ఉన్నాయి. ఏకాగ్రతతో పనిచేయాలి. కుటుంబ బంధాలపై సక్రమమైన ఆలోచన అవసరం. రక్త సంబంధాల విలువ అర్ధం చేసుకోవాలి. ఈ మాసంలో 16 నుండి 19 వ తేదీల మధ్య కాలం కుటుంబ విషయాలకై చర్చించుటకు మంచి రోజులు.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో సువర్ణ లేదా అధునాతన పరికరాలకు ధనాన్ని ఖర్చు చేస్తారు. ఉద్యోగ జీవనం సామాన్య యోగాన్ని కలుగచేయును. ఆలోచనా విధానం బాగుండును. ఈ మాసంలో కూడా కుటుంబ సమస్యలు మానసికంగా చికాకులు కలుగచేయును. రక్త సంబంధీకుల పట్ల అభిమానం అవసరం. ఈ మాసంలో ధనాదాయం సామాన్యం. సంతాన సంబంధిత విషయాలలో ప్రతికూల ఫలితాలు ఏర్పడును. ఈ మాసంలో 29, 30 తేదీలలో తలపెట్టు నూతన కార్యములు జయప్రదంగా కొనసాగుతాయి",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో నూతన వ్యక్తుల పరిచయాలు ఆర్ధిక సమస్యలకు, మానసిక అశాంతికి దారితీయు సూచనలు కలవు. ఈ మాసంలో ప్రధమ వారం మంచి ఫలితాలు ఏర్పడవు. ఈ వారంలో ముఖ్యమైన పనులను వాయిదా వేసుకోవడం మంచిది. ప్రేమ వ్యవహారముల వలన అపకీర్తి, గౌరవ హాని పొందు సూచన. కర్కాటక రాశి పురుషులకు పరభార్యసక్తి వలన తీవ్ర సమస్యలు ప్రాప్తించును. ఉద్యోగ జీవనంలో భాద్యతలను చివరి నిమిషంలో పూర్తీ చేయగలుగుతారు. ప్రశంసలు పొందుతారు. పై అధికారుల సూచనలు ఉపయోగపడును. మాసం చివరి వారంలో శుభాకార్యక్రమాలలో పాల్గొందురు.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో అతి కష్టం మీద వివాహ ప్రయత్నాలు ఫలించును. ధనాదాయం బాగుండును. పలుకుబడి కలిగిన వ్యక్తులతో ఉన్న పరిచయాల వలన లాభములు ఏర్పడును. వృత్తి వ్యాపారములు సామాన్యం. జీవిత భాగస్వామి మూలక ధన లాభములు. మాసం మొత్తం మీద ఉద్యోగ జీవనం సామాన్యంగా కొనసాగును.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో శరీర ఆరోగ్యం సహకరించును. సంతానం వలన మానసిక ఉల్లాసం లభించును. నూతన దంపతుల సంతాన ప్రయత్నాలు విజయవంతం అగును. గృహంలో బంధు మిత్రుల కలయిక ఏర్పడును . పితృ వర్గీయుల కొరకు ధన వ్యయం చేయ వలసి వస్తుంది. ఉద్యోగ సంబంధ స్థాన చలన ప్రయత్నములకు ద్వితీయ , తృతీయ వారములు అనుకూలంగా ఉండును. తలపెట్టిన పనులలో ఏర్పడుతున్న ఆటంకములు తొలగును. అధికారులతో వివాదాలు తొలగును. ధనాదాయం బాగుండును. సినీరంగ వ్యాపారములు చేయు వారికి మాత్రం ఆశించిన లాభములు లభించుట కష్టం. భారీ పెట్టుబడులు పెట్టుటకు ముందు కుటుంబ పెద్దల సలహాలు తీసుకొనుట మంచిది.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో ఆర్ధిక పరిస్థితి అంత ఆశాజనకంగా ఉండదు. వృత్తి జీవనంలో పనులు తీవ్ర జాప్యం ఎదుర్కొందును. కొన్ని విషయాలు మానసికంగా చికాకులు లేదా గౌరవ హాని కలుగచేయును. భాత్రు వర్గం వారి సహకారంతో సమస్యలు పరిష్కారమగును. 10వ తేదీ తదుపరి కొంత అనుకూల పరిస్థితులు ఏర్పడును. నూతన అవకాశములు లభించును. వివాహ ప్రయత్నాలు విజయవంతం అగును. వ్యాపార విస్తరణ అవకాశములు లభించును. విదేశీ విద్య ఆశిస్తున్న వారికి ప్రయత్న పుర్వక లాభం ఏర్పడును. ఈ మాసంలో 2, 3, 8, 13 తేదీలు అంత అనుకూలమైనవి కావు. ఈ మాసం మొత్తం మీద ధనాదాయం కొంత తగ్గును.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },

        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం అంతగా అనుకూలమైనది కాదు. అనవసర విమర్శలు, అపవాదులు ఎదుర్కొందురు. కష్టానికి తగిన గుర్తింపు లభించదు. గృహంలో అనారోగ్య మూలక ఆందోళన ఉండును. సంతాన వ్యవహార తీరు అసహనం కలిగిస్తుంది. స్వయం నియంత్రణ అవసరం. మన మాటే నెగ్గాలి అనే పట్టుదల పనికిరాదు. కఠిన నిర్ణయాల వలన నష్టం పొందు సూచనలు అధికంగా కలవు. మొత్తం మీద ఈ మాసంలో ధనాదాయం సామాన్యం. జ్యేష్టా నక్షత్ర జాతకులకు వైవాహిక జీవన సంబంధ మానసిక అశాంతి ఏర్పడును. ఖర్మను మించి ఏమి పొందలేరని గ్రహించాలి. ఈ మాసంలో 6, 9, 10, 15, 17, 26 తేదీలు అనుకూలమైనవి కావు.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2, 3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ధనాదాయం కొంత తగ్గును. చేపట్టిన పనులు ప్రణాళికాబద్దంగా ముందుకు సాగక ఇబ్బందులను ఎదుర్కొనును. తోటి ఉద్యోగుల నుండి రావలసిన సహకారం సకాలంలో లభించదు. ఆశించిన గుర్తుంపు లభించదు. నూతన అవకాశములు చేజారిపోవును. ఆలోచనల్లో స్పష్టత లోపిస్తుంది. కుటుంబ పెద్దల ప్రవర్తన వలన సమస్యలు. అనుకోని కలహాలకు అవకాశముంటుంది. నూతన దంపతుల సంతాన ప్రయత్నాలలో ఓర్పు అవసరం. ఈ మాసంలో 2, 6, 7, 18, 25, 29, 30 తేదీలు అనుకూలమైనవి కావు. ఆర్ధికంగా వృధా ఖర్చులు ఏర్పడును.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో కూడా ధనాదాయం బాగుండును. నూతనంగా ప్రారంభించిన వ్యాపారములో లాభములు పెరుగును. పోటీదారులు తొలగును. గుత్తేదారులకు భారి ప్రభుత్వ కాంట్రాక్టులు లభించును. సంతానానికి కొద్దిపాటి అనారోగ్యం వలన మనస్తాపం. పై అధికారుల సహకారంతో ఉద్యోగ జీవనంలో అనుకూల కాలం. సులువుగా లక్ష్యాలను పుర్తి చేయగలుగుతారు. ద్వితియ వారంలో ఆకస్మిక ధన వ్యయములు ఎదుర్కొందురు. విదేశీ జీవన సంబంధ ప్రయత్నాలలో జాప్యం చికాకు కలిగించును. నూతన ప్రయత్నాలలో ప్రభుత్వ పరమైన అడ్డంకులు ఎదుర్కొందురు. తృతీయ వారం నుండి అన్ని విధాల సామాన్య ఫలితాలు పొందుదురు. ఈ మాసంలో 8, 10, 11, 13 తేదీలు అనుకూలమైనవి కావు.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో కుటుంబ పరిస్థితులు సంతృప్తికరంగా ఉంటాయి. ధనాదాయం పెరుగును. ఋణ బాధలు తగ్గును. విద్యార్ధులకు చక్కటి అనుకూల కాలం. విదేశీ విశ్వ విద్యాలయ ప్రవేశం కోసం చేయు ప్రయత్నాలు విజయవంతం అగును. ఉద్యోగ జీవనం సామాన్యంగా కొనసాగును. సరైన ప్రణాళికల ద్వారా మంచి పేరు ప్రఖ్యాతలు సంపాదిన్చుకొందురు. సంతాన ప్రయత్నాలు ఫలించవు. వివాహ ప్రయత్నాలలో ఓర్పు అవసరం.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో ప్రధమ అర్ధభాగం అంత అనుకూలంగా ఉండదు. స్త్రీలకు గర్భ సంబంధమైన అనారోగ్య సమస్యలు తీవ్ర ఇబ్బందులు కలుగచేయును. ధనాదాయం తగ్గును. అవసరాలకు రావలసిన ధనం అందుట కష్టం. హామీలు ఇచ్చిన వారు మాట నిలబెట్టుకోరు. సాహసోపేతమైన నిర్ణయాలు తీసుకోకండి. ద్వితీయ అర్ధ భాగం నుండి వృత్తి వ్యాపారములలో అనుకూలత ప్రారంభమగును. నూతన కాంట్రాక్టులు పొందుతారు. సంతాన ప్రయత్నములు వంశ పెద్దల ఆశీస్శులతో విజయవంతం అవుతాయి. ఆర్ధిక విషయాలలో కూడా అనుకూలత పొందుతారు. చివరి వారం స్థానచలన ప్రయత్నాలకు మంచి కాలం. ఈ మాసంలో దంత సంబంధ సమస్యల వలన ఇబ్బందులు పడుదురు.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
      },
      //AUG
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసం అవివాహితుల వివాహ ప్రయత్నములకు అనుకూలంగా ఉండును. ధనాదాయం ఆశించిన విధంగా బాగుంటుంది. కుటుంబంలో నూతన వ్యక్తుల చేరిక వలన ఆనందకర సమయం ఏర్పడుతుంది. కుటుంబ బలం పెరుగుతుంది. నూతన కార్యములు ప్రారంభించుటకు ఈ మాసం అనుకూలమైన కాలం. గృహ వాతావరణంలో కోరుకున్న విధంగా శాంతి పొందుతారు. చేయుచున్న వ్యాపారములు విస్తరించే అవకాశములు లభించును. తృతీయ వారంలో వ్యక్తిగత జీవనంలో సమస్యలు తొలగును. చివరి వారంలో 26, 28, 30 తేదీలలో చేయు ప్రయత్నాలు మంచి ఫలితాలను కలిగించును.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో దూర ప్రాంత ఆదాయ ప్రయత్నాలు ఫలించి ఆశించిన ఆదాయం పొందగలరు. నూతన ఉద్యోగ అవకాశములు పొందుతారు. ఉద్యోగ జీవనంలో నిలకడ వస్తుంది. వ్యాపార వ్యవహారాలు సజావుగా సాగును. కుటుంబ విషయాలలో కొంత అనుకూల వాతావరణం ఏర్పడుతుంది. ఉద్యోగ విషయాలలో పట్టు సాధిస్తారు. పరిస్థితులు అవగాహన అవుతాయి. సంతాన ప్రయత్నాలు విజయవంతం అగును. జీవితానికి సంబందించిన ముఖ్యమైన నిర్ణయాలను ఈ మాసంలో తీసుకొనవచ్చు. ఈ మాసం లో తీవ్ర వ్యక్తిగత ఇబ్బందుల నుండి బయటపడతారు. మానసిక ప్రశాంతత లభిస్తుంది.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడును. ఉద్యోగ జీవనంలో అధికారుల వలన వేధింపులు, ఒత్తిడి పెరుగు సంఘటనలు. వ్యక్తిగత జీవితంలో సౌఖ్యం తగ్గును. ముఖ్యంగా భాత్రు వర్గం వలన మానసిక అశాంతి ఎదురగును. కుటుంబ పరంగా భూ లేదా స్థల సంబంధమైన నష్టం ఏర్పడుటకు సూచనలు అధికం. నేత్ర సంబంధ సమస్యల వలన ఇబ్బందులున్నాయి. ఈ మాసంలో 13 నుండి 18 వ తేదీ మధ్య కాలంలో గౌరవం దెబ్బతినును. జీవన విధానంలో సమస్యలు ఎదురగును. 29, 30 తేదీలలో ఆరోగ్య సమస్యలు వలన ఖర్చులు అధికం అవుతాయి. నిరాశాజనక వాతావరణం ఎదురగును. మిత్రులకు సంబంధించి అశుభ వార్తలు వినవలసి వచ్చును.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో పితృ వర్గం వారి నుండి ఎదురుచూస్తున్న సహాయం లభించును. నూతన వాహన కోరిక ఫలించును. వ్యాపార వ్యవహారములలో జయం ప్రాప్తించును. ధన సమస్యలు తొలగును. యువకులకు ఉద్యోగ పరమైన లాభములు ప్రాప్తించును. ప్రతిభకు ఆశించిన గుర్తింపు లభిస్తుంది. ఉపకార వేతనాలు పొందుతారు. మాసం ద్వితీయ అర్ధ భాగంలో ప్రయాణాలు ఏర్పడును. తలపెట్టిన ప్రయాణాలు లాభించును. సక్రమంగా ఆలోచించ గలుగుతారు. ఉద్యోగ జీవనంలో సామాన్య యోగం కొనసాగును. మాసాంతంలో కుటుంబ పరిస్థితులలో నూతన వ్యక్తుల ప్రవేశ సూచనలు కలవు. ఈ రాశి మహిళలకు వారి పేరుమీద చక్కటి భూ సంపద ఏర్పడు యోగము కలదు. ఈ మాసంలో 4, 7, 10, 19.21 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో కూడా గత మాసపు అనుకూల ఫలితాలు కొనసాగును. ధనాదాయం సామాన్యం. ద్వితీయ వారంలో కుటుంబంలోని పెద్ద వయస్సు వారికి ఆరోగ్య భంగములు. 4,7,13,17,22 తేదీలలో వృత్తి వ్యాపారాలలో చక్కటి ధన ఆదాయం. నూతన ప్రయత్నములలో సులువుగా విజయం చేకూరును.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసం కూడా అనుకూలమైన ఫలితాలు కలుగచేయు మాసం. చిరకాలంగా ఉన్న నూతన గృహ లేదా వాహన కోరిక నెరవేరును. ఆశించిన విధంగా ధనార్జన లభిస్తుంది. గత కాలపు పెట్టుబడుల నుండి ధనం చేతికి లభించును. గృహ సంబంధ క్రయవిక్రయాలందు మధ్యవర్తిత్వం వలన కొన్ని ఇబ్బందులు ఎదుర్కొందురు. ఈ మాసంలో ఆలోచనలు సక్రమంగా ఉండును. కార్య భారం తగ్గుతుంది. తోటి ఉద్యోగుల నుండి చక్కటి సహకారం పొందుతారు. తృతీయ వారంలో మాతృ వర్గీయులతో ఆర్ధిక సంబంధ విషయాలందు విభేదాలు ఏర్పడును. నూతన విషయాల పట్ల ఆసక్తి పెరుగును. వ్యక్తిగత జీవనం ఆనందకరంగా కొనసాగుతుంది.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో ధన వ్యయం తగ్గును. దూరప్రాంత జీవన ప్రయత్నాలు చేయు వారు ఆటంకములు ఎదుర్కొందురు. ఈ మాసంలో మాతృ వర్గీయులకు తీవ్ర ఆరోగ్య సమస్యలు కలవు. అఖస్మిక ప్రయాణాలు ఏర్పడును. నూతన ఆలోచనలు, వ్యాపార లావాదేవీలు సమస్యలతో కొనసాగును. ఉద్యోగ జీవనంలో కూడా ఊహించని సమస్యలు ఇబ్బంది పెట్టు సూచన. విలువైన వస్తువులు పోగొట్టుకొనే అవకాశము ఉన్నది. స్థాయిని మించిన సహాయములు చేయుట వలన చేతిలో ధనం నిలువదు. ఇతరుల సమస్యలలో కలుగచేసుకోనుట వలన గౌరవ హాని సూచనలు కలవు. ముఖ్యంగా 15, 16, 17 తేదీలలో సమన్వయంతో వ్యవహరించుట మంచిది.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },

        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో ఆర్ధిక పరిస్థితులలో మెరుగుదల కనిపిస్తుంది. ఋణ విముక్తులు అగుదురు. ఉద్యోగ జీవనంలో చేజారిన అవకాశములు తిరిగి లభించును. సులభంగా పనులను పూర్తీ చేయగలరు. వ్యాపార వ్యవహారాలు లాభదాయకంగా ఉండును. వ్యక్తిగత జీవనంలో అపసృతులకు అవకాశం ఉన్నది. స్త్రీ లకు గర్భ సంబంధ వ్యాధుల సమస్యలకు సూచనలు కలవు. విశాఖ నక్షత్ర జాతకులకు ఆఖస్మిక వాహన మరమత్తుల సంబంధ వ్యయం. కుటుంబ విషయాలలో భాత్రు వర్గంతో మైత్రి తగ్గును. నూతన వ్యాపకాలు అలవడును. నూతన ఆలోచనలు స్పురిస్తాయి. స్నేహ వర్గాలలో ఒకరికి సంబందించిన దుర్వార్త తెలుసుకుంటారు. ఈ మాసంలో ఉత్తర దిశలో చేసే ప్రయాణాలు మంచి లాభపురితంగా ఉంటాయి.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ఉద్యోగ జీవనంలో ఇబ్బందులు తొలగును. లక్ష్యాలను సకాలంలో పూర్తీ చేయగలుగుతారు. మీ సామర్ధ్యం పై నమ్మకముంచండి. 8, 9, 10 మరియు 11 తేదీలలో నూతన అవకాశములు, అధికారుల ప్రోత్సాహం లభించును. నిరుద్యోగులకు ఉద్యోగ అవకాశములు లభించును. క్రమంగా అభివృద్ధి ఏర్పడుతుంది. వ్యాపార వ్యవహారాలు బాగుంటాయి. సినిమా రంగంలో పెట్టుబడులు పెట్టిన వారు బాగా లాభపడతారు. వృత్తి పరమైన వ్యాపారాలు కూడా బాగుంటాయి. మూలా నక్షత్ర జాతకులకు జూదం వలన పెద్దమొత్తంలో నష్టములు ఏర్పడును. కుటుంబ జీవనంలో ఆశించిన ఫలితాలు ఏర్పడును. సంతానానికి భవిష్యత్ నిధిని ఏర్పాటు చేయగలుగుతారు.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో గృహంలో ఆకస్మిక శుభకార్యములు , విందు భోజనములు, అతిధుల ఆతిధ్య సంబంధిత వ్యయం ఎదుర్కొందురు. నూతన పరిచయాలు లాభించును. ఈ మాసంలో ప్రారంభ రోజులలో ఉద్యోగ జీవనంలో కొద్దిపాటి ఇబ్బందులు ఏర్పడినప్పటికీ మాసాంతమునకు గురు గ్రహ బలం వలన తొలగును. తృతీయ వారంలో నిరుద్యోగులకు ఉద్యోగ జీవన సంబంధిత శుభవార్త. అందరి మన్ననలను పొందుతారు. మాసాంతంలో ఆశించిన ఫలితాలు ఏర్పడుతాయి. మొత్తం మీద ఈ మాసంలో ఆదాయం కన్నా వ్యయం అధికం అగును.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో శత్రు విజయాలు లభిస్తాయి. దగ్గర వారి ప్రమేయంతో సమస్యలకు పరిష్కారం పొందుతారు. నూతన వస్తువులు అమరుతాయి. ఉద్యోగ వ్యాపార వ్యవహరాదులు సామాన్యం. ధనాదాయం సామాన్యం. వ్యక్తిగత జీవనంలో పాప ఖర్మలు చేయుదురు. పాప-పుణ్య విచక్షణ అవసరమగును. ఆకస్మిక ప్రయాణ లాభాలున్నాయి. ఆరోగ్య విషయాలు క్రమ క్రమంగా మేరుగవును. విదేశీ నివాస సంబంధ వ్యవహారాలలో శుభవార్త.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో ప్రారంభంలో చికాకులు ఉన్నప్పటికీ చివరకు జయం చేకురును. వ్యక్తిగత జీవనంలో సంతోషకర సంఘటనలు ఏర్పడును. శత్రువులపై విజయం పొందుతారు. కొద్దిపాటి గర్వం ప్రదర్శిస్తారు. తలపెట్టిన పనులలో ఆశించిన గుర్తింపు లభిస్తుంది. ఈ మాసంలో ధనాదాయం సామాన్యం. ద్వితియ తృతీయ వారములలో అనేక ఆటంకములతో విదేశీ ఉద్యోగ ప్రయత్నములు సఫలం అగును. కుటుంబ వ్యవహారములలో మాత్రం ఆశించిన విధంగా ఫలితాలు ఉండవు. మీ మాటతీరు సమస్యలను ఏర్పరచును. చివరి వారంలో శ్రమ అధికం మరియు ఉద్యోగ జీవనంలో అనిశ్చిత వాతావరణం. ఈ మాసంలో 10, 11, 18, 25, 26 తేదీలలో చేయు ప్రయాణాలు కలసిరావు. నూతన కార్యాకలాపాలు ప్రారంభించకండి.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
      },
      //SEP
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో వ్యాపార రంగంలోని వారికి అఖండ విజయం ప్రాప్తించును. భాగస్వామ్య వ్యవహారముల ద్వారా చక్కటి ధన ప్రాప్తి పొందుతారు. వృత్తి జీవనంలోని వారికి సులువైన ధన సంపాదన ఏర్పడును. ఉద్యోగ జీవనంలోని వారికి మాత్రం మిశ్రమ ఫలితాలు ఏర్పడును. పై అధికారులతో మాట పడుదురు. శ్రమకు తగిన ఫలితం వుండదు. అనుకొన్న సమయానికి పనులు పూర్తి అవ్వవు. తోటి ఉద్యోగుల వలన ఇబ్బందులు ఎదుర్కొంటారు. మాసాంతంలో ఒక ముఖ్య వ్యవహారం అటంకములను పొందును. జీవిత భాగస్వామికి ఇచ్చిన మాట నిలబెట్టుకోలేరు. ఈ మాసంలో 26, 27, 28 తేదీలలో పెద్ద వయ్యస్కులకు హృదయ సంబంధ ఆరోగ్య సమస్యలు ఏర్పడు సూచన. ఈ మాసంలో ధనాదాయం బాగుండును.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసం మిశ్రమ ఫలితాలను ఏర్పరుస్తుంది. పట్టుదలకు పోయి వ్యవహారాలను పాడు చేసుకొంటారు. మిత్రుల మాట అతిగా విని నష్టపోతారు. దురలవాట్ల వలన ప్రమాదాలు ఎదుర్కొంటారు. ఎదో ఒక విధంగా అవసరాలకు తగిన ధనం మాత్రం సర్దుబాటు అవుతుంది. ప్రారంభించిన నూతన వ్యవహారాలు ప్రభుత్వ పరమైన ఇబ్బందులు ఎదుర్కొంటాయి. ద్వితీయ మరియు తృతీయ వారములలో కుటుంబంలో ఒక తీవ్ర నష్టం ఏర్పడుటకు సూచన ఉన్నది. ఈ మాసంలో ప్రయాణ మూలక ఆరోగ్య సమస్యలు మరియు ఆశా భంగములు ఏర్పడును. మాసం చివరిలో భాగస్వామ్య వ్యవహారాలలో జరుగుతున్న మోసాన్ని తెలుసుకుంటారు.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో కూడా వ్యాపార వ్యవహరాదులలో ఆశించిన ఫలితాలు ఏర్పడుట కష్టం. ఉద్యోగ జీవనంలో ఒడిదుడుకులు ఎదుర్కొందురు. తోటి ఉద్యోగుల వలన తీవ్ర ఇబ్బందులు అనుభవిస్తారు. ఆర్ధిక అపవాదులు ఎదుర్కొను సూచనలు అధికంగా ఉన్నవి. నూతన ఉద్యోగ ప్రయత్నాలు అతి కష్టంపై ఫలించును. వ్యాపార వ్యవహారములలో అననుకూల మార్పులు జరుగును. రసాయన సంబంధ వ్యాపారం చేయువారికి జీవనాధారం దెబ్బతింటుంది. లభించిన అవకాశములతో తృప్తి పడవలెను. నాయకులకు పదవీభంగం. ఈ మాసంలో ధనాదాయం సామాన్యం.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో ప్రారంభం నుండి 20 వ తేదీ వరకూ ఉద్యోగ , వ్యాపార వ్యవహారాలు ఇబ్బందులు ఎదుర్కొనును. ఆశించిన విజయాలు చివరి నిమిషంలో చేజారును. ఆరోగ్యం కూడా అంతగా సహకరించదు. 21 వ తేదీ తదుపరి రావలసిన బాకీలు వసూలు అగును. ఆర్ధికంగా కొంత ఒత్తిడి తగ్గును. పని భారం కూడా తగ్గును. నిరుద్యోగుల ఉద్యోగ ప్రయత్నాలు విజయం పొందును. అదృష్టం కలసి వచ్చును. గృహ నిర్మాణ పనులలో అడ్డంకులు తొలగిపోవును. ఒక చిరకాల వాంఛ నెరవేరుతుంది. 28, 29 తేదీలలో అపవాదులు ఎదుర్కొనుటకు సూచనలు కలవు.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో వ్యక్తిగత ఆరోగ్య విషయంలో మానసిక అధైర్యం ఏర్పడును. ఆత్మీయుల అనుచిత ప్రవర్తన వలన మానసిక అశాంతితో బాధపడుదురు. కుటుంబంలో అనవసరమైన కలహములు ఏర్పడి అపకీర్తి పొందుతారు. తృతీయ వారం వరకూ కార్య విఘ్నములు ఏర్పడుచుండును. 18, 19 తేదీలలో ప్రయాణములలో ప్రమాద సూచన. జాగ్రత్త అవసరం. ఈ మాసంలో కాలం అంతగా కలసి రాదు. ధనాదాయం సామాన్యం.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో కుటుంబ పరిస్టితులు మెరుగుపడును. భాగస్వామ్య వ్యాపారములు ఆర్ధికంగా వివాదాలను కలుగచేయును. తొందరపాటు నిర్ణయాలతో భాగస్వామ్య వ్యాపారములందు పెట్టుబడులు పెట్టుట మంచిది కాదు. ద్వితీయ వారం లోపున వాయిదా పడుతూ వస్తున్న ఇష్ట దైవ సందర్శన ఏర్పడును. పారమార్ధిక చింతన అలవరచుకుంటారు. భాగస్వామ్య వ్యాపారములు మినహా మిగిలిన రంగాల వారికి ఆర్ధిక పరిస్థితులు సంతృప్తికరంగా ఉంటాయి. మిత్రులకు రుణ విషయములందు వ్యక్తిగత హామీలు ఇవ్వకండి. ఈ మాసంలో చేయు వివాహ , సంతాన ప్రయత్నములు లాభించును. పుత్ర సంతానమునకు ఈ మాసం అనుకూలమైన కాలం.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో ధన సంబంధమైన సమస్యలు కొద్దిగా తగ్గును. నూతన వ్యాపార వ్యవహారాలు మాత్రం సమస్యలు కలిగించును.స్నేహ వర్గం నుండి ఆశించిన సహకారం ఉండదు. స్వతంత్రంగా వ్యవహారములు చక్కపెట్టుకోవాలి. స్నేహితులపై ఉన్న నమ్మకం దెబ్బతినును. ప్రముఖుల నుండి ఆహ్వానాలు అందును. విద్యార్ధులకు కోరకున్న విద్యా ప్రవేశాలు లభిస్తాయి. పుణ్య క్షేత్ర సందర్శన ప్రయత్నాలు చేస్తారు. ఆధ్యాత్మిక కార్యక్రమాలలో పాల్గొందురు. ఈ మాసంలో 9 నుండి 20 తేదీ మధ్యకాలం వివాహ - సంతాన- నూతన గృహ నిర్మాణ సంబంధ పనులు చేయుటకు అనువైన కాలం.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },

        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో జీవితంలో నూతన ఉత్సాహం లభిస్తుంది. ఆత్మ విశ్వాసం పెరుగుతుంది. విరామం లేకుండా వ్యవహారాలు పూర్తిచెయుదురు. శ్రమకు తగిన లబ్ది లభించును. వ్యాపార వ్యవహారాలలో ఆశించిన పెట్టుబడులు లభించును. నూతన భాగస్వాములు లభిస్తారు. భాగస్వాముల సలహాలు కలసి వచ్చును. పితృ వర్గ ఆరోగ్యం కోసం ఆందోళన పెరుగుతుంది. నిరుద్యోగుల ఉద్యోగ ప్రయత్నాలు విజయం పొందును. పుణ్య క్షేత్ర సందర్శన చేయుదురు. ఉద్యోగ మార్పిడికి సంబంధించిన సంతోషకర వార్తలు వింటారు. ఈ మాసం ఉద్యోగ ప్రయత్నాలకు అనుకూల కాలం. మొత్తం మీద వృశ్చిక రాశి వారికీ ఈ మాసం సంతోషకరంగానే ఉంటుంది. ఈ మాసంలో 4, 5, 8, 10 తేదీలలో చేపట్టే కార్యములు విజయవంతం అగును.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో వ్యక్తిగత జీవితంలో ఇతరుల ప్రమేయం వలన ఒక నష్టం ఏర్పడును. జీవిత భాగస్వామి ఫై అపోహలకు తావివ్వకండి. మాత్రు వర్గం వారికి కొద్దిపాటి అనారోగ్య సమస్య. వ్యాపార వ్యవహారాలు సామాన్యం. ధనార్జన ఆశించిన విధంగానే ఉండును. సంతానం యొక్క పురోగతి ఆనందాన్ని కలుగచేస్తుంది. ద్వితీయ తృతీయ వారాలాలో ఉద్యోగులకు చక్కటి ప్రోత్సాహం లభిస్తుంది. చిన్న అవకాశాలు కూడా భవిష్యత్లో మంచి చేస్తాయి. అవకాశములను వదులుకోకండి. అతి తెలివితేటలు ప్రదర్శించుట , తర్కంగా ఆలోచించుట మంచిది కాదు. చివరి వారంలో రాజకీయ రంగంలోని వారికి పదవీయోగం ఉన్నది. ప్రయత్నాలు చేయండి.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },


        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడును. ధనాదాయం సామాన్యం. నూతన పనులు ఆరంభించకూడదు. నష్ట పరచును. భూ సంబంధ లేదా వారసత్వ సంబంధ విషయలాలో చికాకులు అధికం అగును. ద్వితియ వారంలో సంతాన సంబంధ లాభం. ధనాదాయం లో కొద్దిపాటి పెరుగుదల ఏర్పడును. కుటుంబంలో చక్కటి అనుకూల వాతావరణం. ఆనందకర సమయం. నాలుగవ వారం ప్రారంభం నుండి మాసాంతం వరకూ చేతిలో ధనం నిలువదు. వృధా వ్యయం ఏర్పడును. తలపెట్టిన పనులలో ఊహించని చికాకులు. ప్రయాణమూలక ఆరోగ్య సమస్యలు ఎదురగును.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో సంతాన లేమి దంపతుల సంతాన కోరిక నెరవేరును. వ్యాపారాల వలన లాభకర పరిస్థితి. పితృ వర్గం వార్కి మంచిది కాదు. జీవితంపై ఆలోచనా దృక్పధం మారుతుంది. వైవాహిక జీవనంలో సమస్యలు తొలగును. కుటుంబ సభ్యుల సహకారం లభిస్తుంది. ఉదార స్వభావం వలన పేరు లభిస్తుంది. చంచల స్వభావ మిత్రువర్గం వలన కొద్దిపాటి ఇబ్బందులు ఏర్పడును. వీరి వలన చేతికి వచ్చిన ఒక ఫలితమును పోగొట్టుకుంటారు. ఈ మాసంలో ధనాదాయం సామాన్యం. చివరి వారంలో మాట తగాదాల వలన దూరమైనవారు తిరిగి దగ్గరవుతారు.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో బహు అవరోధములు ఎదుర్కొందురు. మానసిక వ్యధ కూడా అధికం అగును. భవిష్యత్ ఆందోళన అధికం అగును. అకాల భోజనములు చికాకులు కలుగ చేయును. పిల్లలకు శిరోబాధ లేదా కర్ణ సంబంధ సమస్యలు. వివాహ ప్రయత్నాలు కష్టం మీద జయం పొందును. ఉద్యోగ జీవనంలో ఉహించని ఆటంకములు ఏర్పడును. దురుకోపాన్ని తగ్గించుకోవాలి. సోదరి వర్గ సంబంధ పనులకోసం విరామం లేకుండా శ్రమించవలసి వచ్చును. నూతన విద్యలను అభ్యసించు అవకాశములు లభిస్తాయి. ఈ మాసంలో ఆవేశం తగ్గించుకోని ప్రవర్తించుట మంచిది. వృత్తి వ్యపారములందు మొత్తం మాసం మీద ధనాదాయం కొంత తగ్గును.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },




      },
      //OCT
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో సంతాన సంబంధ విషయాలలో ఊహించని నష్టం. గర్భిణిలు చాలా జాగ్రత్తగా ఉండవలెను. ధనాదాయం సామాన్యంగానే ఉండును. ప్రధమ వారంలో ప్రారంభించు వ్యాపారములు విజయవంతం అగును. ద్వితీయ వారంలో ఉద్యోగ జీవనంలో సమస్యలు తొలగి ఉన్నతి ఏర్పడు సూచన. అందరి మన్ననలూ పొందుతారు. నూతన గృహ లేదా వాహన ప్రయత్నములు ఫలించును. విద్యార్దులకు చివరి వారంలో మానసికంగా క్రుంగదీయు ఆలోచనలు అధికంగా ఏర్పడును. ప్రేమకలాపముల వలన తీవ్ర ఇబ్బందులు ఎదుర్కొంటారు. పెద్ద వయస్సు వారికి ఆరోగ్య సమస్యలు కొనసాగును. ఈ మాసంలో ఒక పర్యాయం రుద్రాభిషేకం చేయించుకొనుట మంచిది.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో కూడా మిశ్రమ ఫలితాలు ఎదుర్కొందురు. చివరి వారానికి కొంత అనుకూలత ఏర్పడుతుంది. సంతాన ప్రయత్నాలు చేయువారు అశుభ వార్త తెలుసుకొను సూచనలు ఉన్నవి. ఉద్యోగ వ్యవహారాలలో నూతన అవకాశములు లభిస్తాయి. విద్యార్దులకు ఒత్తిడి అధికమగును. నిద్రలేమితనం బాధిస్తుంది. ఆర్ధిక విషయాలలో ఆశించిన సహకారం ఆలస్యంగా లభిస్తుంది. ఈ మాసంలో మిత్రులతో సంభాషించునపుడు ఆచి తూచి మాట్లాడాలి. ఆర్ధిక పరంగా మిత్రులకు ఆలోచించకుండా హామీలు ఇవ్వకూడదు.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం ప్రధమ వారంలో సంతాన సంబంధ లాభములు ఏర్పడును. బాగా ఎదిగిన సంతానానికి కోరుకొన్న విధంగా ఉద్యోగ లాభం ఏర్పడును. గత కాలపు సమస్యల నుండి కొంత ఉపశమనం లభించును. కుటుంబ సంబంధాలు కొంత బాగుండును. స్నేహ పూర్వకంగా మాట్లాడడం వలన ఉత్తమ ఫలితాలు ఉంటాయి. ఆత్మీయుల వలన నూతన ప్రయత్నాలు లాభించును. విమర్శల నుండి బయట పడతారు. పెద్దల అనుభవం ఉపయోగపడుతుంది. ఫైనాన్సు వ్యాపారం చేయువారికి ఈ మాసం అంతగా కలసిరాదు. పెద్ద మొత్తంలో ధనాన్ని నష్టపోవుదురు. మాస ద్వితియార్ధంలో కోర్టు, విదేశీ వ్యవహారాలు సానుకూలంగా ఉంటాయి. ఆశించిన గుర్తింపు పొందుతారు.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో ఆధ్యాత్మిక కార్యక్రమాలలో పాల్గొను అవకాశములు అధికం. పారమార్ధిక చింతన అధికమగును. కొంత వరకు సంతృప్తికర పరిస్థితులు ఎదురగును. ఉద్యోగ జీవులకు విషయ పరిజ్ఞానం పెంపొందించుకోవలసిన సమయం ఏర్పడును. ప్రభుత్వ సంబంధ ఉద్యోగం ఆశిస్తున్న వారికి ఈ మాసంలో ద్వితీయ లేదా తృతీయ వారాలలో వెతలు తీరును. విద్యార్ధులకు చక్కని భవిష్యత్ లభించును. వ్యాపారములు వృద్ధి చెందును. ఆశించిన ధనాదాయం లభించును. కోర్టు కేసులు అనుకూలంగా ముగియును. నూతన మిత్ర వర్గం ఏర్పడును. ఈ మాసంలో 23, 24 తేదీలలో ప్రయనములందు జాగ్రత్తగా ఉండవలెను.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
          
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలను పొందుతారు. వ్యాపార రంగంలో పోటీ పెరిగి ఆదాయంలో తగ్గుదల ఏర్పడుతుంది. తల్లితో లేదా మాతృ వర్గీయులతో విభేదాలు భాదించును. ఉద్యోగ జీవనంలో 22, 23, 24, 25 తేదీలలో ఉన్నత అధికారుల వలన ఇబ్బందులు ఎదుర్కొనవలసి వస్తుంది. కెరీర్ కు సంబందించిన ఆందోళన అధికం అగును. ఈ మాసంలో ఉద్యోగ మార్పు కొరకు ప్రయత్నించుట మంచిది కాదు. వైద్య రంగంలో ధనార్జన చేయు వారు 22 నుండి 26 వ తేదీల మధ్య కాలంలో జాగ్రత్తగా ఉండవలెను. అనవసర తగాదాలు ఎదుర్కొంటారు.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో ఆర్ధికంగా అనుకూల ఫలితాలు ఉన్నప్పటికీ కుటుంబ సభ్యుల మధ్య ఉన్న సంఘర్షణ సంబంధిత చికాకులు మాత్రం కొనసాగును. సొంత మనుష్యుల నిరాదరణ మానసికంగా బాధించును. ఈ మాసంలో దూర ప్రాంత , పరదేశ స్థిర నివాస ప్రయత్నాలు కష్టం మీద ఫలించును. ద్వితీయ వారంలో శరీరమునకు కార్య భారం వలన అలసట ఏర్పడును. ఉద్యోగ జీవనలో ఒత్తిడి ఎదుర్కొంటారు. ఆలోచనలు ముందుకు సాగవు. నూతన వ్యాపారాలు అంతగా రాణించవు. చివరి వారంలో మిత్రులే శత్రువులగుదురు. మీకు అపఖ్యాతిని ఆపాదించడానికి ప్రయత్నిస్తారు. వ్యక్తిగత విషయాలు అన్నింటిని మిత్రులతో ప్రస్తావించట మంచిది కాదు.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో ధన ఆదాయం అవసరములకు సరిపోతుంది. కుటుంబ పరమైన ఖర్చులు అధికం అగును. గృహంలో శుభ కార్యములు నిర్వహించెదరు. సంతానానికి సంతోషాన్ని కలుగచేయుదురు. నూతన కార్యములకు రూపకల్పన చేయుదురు. ఉద్యోగ జీవనం సాఫీగా కొనసాగును. సంతన లేమీ దంపతుల సంతాన ప్రయత్నాలు విజయం పొందును. ఎంతో కాలంగా ఇబ్బంది పెడుతున్న ఒక వ్యక్తిగత వ్యవహార సమస్య తీరి మానసిక ప్రశాంతత ఏర్పడుతుంది. సోదర వర్గం మధ్య సఖ్యత పెరుగుతుంది. వ్యవసాయం మీద ఆధారపడిన వారికి ఈ మాసంలో తీవ్ర ప్రతికూలత ఎదురగును. పెంపుడు జంతువుల విషయంలో జాగ్రత్త అవసరం. ఈ మాసంలో ప్రయాణాలు వాయిదా వేసుకోవడం మంచిది.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
     
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడును. ఆలోచనలు నిలకడగా ఉండవు. అవకాశాలు చివరినిమిషంలో చేజారిపోవును. వ్యయం కూడా అదుపు తప్పుతుంది. ఆత్మీయుల ప్రవర్తన మానసికంగా తీవ్ర ఇబ్బందులు కలుగచేస్తుంది. గౌరవానికి భంగం ఏర్పడుతుంది. దైనందిన విషయాల పట్ల ఆసక్తి పోతుంది. సొంత ఆరోగ్యం పట్ల శ్రద్ధ అవసరమగును. చేపట్టిన కార్యక్రమాలలో అడ్డంకులు, స్తబ్ధత నిరాశ కలుగచేస్తాయి. మొత్తం మీద ఈ మాసం అంత ఆశించిన ఫలితాలను ఇవ్వదు. జీవన విధానంలో నూతన మార్పులకు ప్రయత్నించకండి. ఈ మాసంలో ఆర్ధికంగా భారి పెట్టుబడులు పెట్టకుండా ఉండుట మంచిది.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
    
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో నూతన ఆలోచనలు ఆచరణలోకి తీసుకురావడం వలన శుభ ఫలితాలు ఏర్పడును. శ్రమ అధికం అయినా పట్టుదలతో పని చేస్తారు. స్థాన చలన , ఉద్యోగ మార్పు ప్రయత్నాలు ఫలిస్తాయి. వైవాహిక జీవనంలో సమస్యలు తొలగుతాయి. జీవిత భాగస్వామితో సంతోష జీవనం. ధనాదాయం బాగుండును. జీవన మార్గంలో ఆశించిన మార్పులు ఏర్పడతాయి.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. ప్రధమ అర్ధ భాగంలో ఇష్టమైన వ్యక్తులతో దూర ప్రయాణములు చేస్తారు. కుటుంబ అవసరాలకు ఆశించిన విధంగా ధనం సర్దుబాటు చేయగలుగుతారు. పుత్ర సంతానం వలన చక్కటి సౌఖ్యత ఏర్పడును. ద్వితియ అర్ధ భాగం నుండి అనగా 13, 14, 15, 16 తేదీలలో భాగస్వామ్య వ్యాపారములలో నష్టం ఏర్పడు సూచనలు అధికం. చిన్న చిన్న విషయాలలో ఇరుగు పొరుగు వారితో తగాదాల వలన చికాకులు ఏర్పడును. ఉద్యోగ జీవనంలో ఆకస్మిక సమస్యలు. 17,18 తేదీలలో గౌరవ హాని. మాసాంతంలో ఉద్యోగ ఒత్తిడి వలన శ్రమ అధికం అగును. వివాహ సంబంధ ప్రయత్నాలలో ఒక అశుభ వార్త.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో వ్యతిరేక ఫలితాలు ఎదుర్కొంటారు. ఆర్ధికంగా ధనాదాయం తగ్గుతుంది. ఉద్యోగ జీవనంలో ఆకస్మిక నష్టములు ఏర్పడును. ఉద్యోగ జీవనంలో ఇబ్బందుల వలన ఋణ బాధలు ఎదుర్కొంటారు. వాహన సంబంధిత సమస్యలు. వృత్తి జీవనం లోని వారికి అవమానకరమైన పరిస్థితులు. అకాల భోజనం వలన సమస్యలు. ఈ మాసంలో ప్రయత్నాలు కలసి రావు. ఈ మాసంలో 2, 5, 9, 10, 11, 20, 21, 27, 28, 29 తేదీలలో ఇబ్బందులు అధికమగును.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
     
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో వృద్ధులకు శరీర ఆరోగ్యం సహకరించును. కుటుంబ జీవనంలో శుభకార్యములు నిర్వహించుట, ఉల్లాసంగా బంధువులతో, మిత్రులతో చేరికలు ఏర్పడుట జరుగును. ఉద్యోగ జీవనంలో కార్యానుకులాత లభించును. స్నేహితుల వాహనం వలన ఇబ్బందులు ఎదురగును. జీవిత భాగస్వామి సహకారం వలన కుటుంబ సమస్యలు తొలగును. చివరి వారంలో వృత్తి వ్యాపారములు సులువుగా ఆశించిన విధంగా కొనసాగును. మాసాంతంలో సంఘంలో పేరు ప్రఖ్యాతలు పెరుగును. నిల్వధనం ఏర్పడును. చక్కటి ఉద్యోగ స్థిరత్వం లభించును.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
    
      },
      //NOV
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో గృహంలో ఒక అశుభ సంఘటన ఏర్పడుటకు సూచనలు అధికంగా ఉన్నవి. వ్యక్తిగత విషయాల్లో మానసికంగా తీవ్ర సంఘర్షణ ఎదుర్కొందురు. ప్రశాంతత లోపించును. ఈ మాసంలో ఏర్పడు నూతన పరిచయాలు ఇబ్బంది పాలుచేయును. ముఖ్యంగా అశ్విని నక్షత్ర యువతులు జాగ్రత్తగా ఉండవలెను. ఈ మాసంలో ధన ఆదాయం సామాన్యం. ద్వితియ వారంలో వృత్తి జీవనంలోని వారికి కొద్దిపాటి చికాకులు ఎదురగును. మిత్రుల సహాయ సహకారములతో అడ్డంకులను అధిగమించెదరు. మిత్రుల సలహాలు ఉపయోగపడును. మూడవ వారంలో వ్యయం కొంత అధికమగు సూచన. ఈ మాసంలో 19, 20, 24 తేదీలు అనుకూలమైనవి కావు.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసం బాగుంటుంది. చర్చలు, సమావేశాల్లో పాల్గొంటారు. సంఘములో గౌరవం లభిస్తుంది. ధనాదాయం బాగుంటుంది. విద్యార్ధుల ప్రతిభకు తగిన గుర్తింపు లభిస్తుంది. ఈ మాసంలో అవివాహితులు తమ జన్మ స్థానానికి ఉత్తర దిశగా వివాహ ప్రయత్నాలు చేయడం వలన కలసి వచ్చును. స్త్రీ లకు నూతన వస్తు - ఆభరణాలు లభించు సూచనలు ఉన్నవి. ఈ మాసంలో శుభ వార్తలు వినుట వలన పోయిన మనోధ్యైర్యం తిరిగి పొందుతారు. ఆశించిన విధంగా దైవం నుండి సహకారం లభిస్తుంది. మొత్తం మీద ఈ మాసం సౌఖర్యవంతంగా ఉంటుంది.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో నూతన భాద్యతలు చేపడతారు. వాటికి అనుగుణంగా మీ వ్యవహార శైలి మార్చుకొని వ్యవహరిస్తారు. ఇరుగుపొరుగు వారి వలన ఇబ్బందులు ఎదుర్కొంటారు. జీవన మార్గంలో ఆసక్తికర సంఘటనలు జరుగుతాయి. జీవిత భాగస్వామితో అభిప్రాయ భేదాలు అధికం అవుతాయి. దూర ప్రయాణ సంబంధ ప్రణాళికలు సిద్ధం చేసుకొంటారు. వ్యాపార రంగాల్లో చక్కటి అభివృద్ధి అవకాశాలు లభిస్తాయి. దేవతా ప్రతిష్ట వంటి దైవ సంబంధిత కార్యక్రమాలలో పాల్గొంటారు. కుటుంబ సభ్యుల విశ్వాసం సంపాదించుకొంటారు. మిత్ర వ్యాపార సంబంధ విషయాలలో నిర్ణయాలను వాయిదా వేయకుండా ఉండుట మంచిది. ఈ మాసంలో 13, 14 , 19, 25 తేదీలలో చేపట్టు నూతన ప్రయత్నాలు విజయవంతం అగును.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో కళత్ర సంబంధ విషయాలు తప్ప మిగిలిన అన్ని విషయాలందు అనుకూలత ఏర్పడును. వ్యక్తిగత జీవనంలో సౌఖ్యం తక్కువ అగును. సుఖ వ్యాధుల వలన బాధపడు సూచనలు ఉన్నవి. జీవిత భాగస్వామితో వివాదాలు అశాంతిని కలుగ చేయును. భూ లేదా గృహ సంబంధ వ్యాపారాదులలో నష్టం ఏర్పడును. ఇతర వ్యాపార వర్గములకు ధనాదాయం సామాన్యం. ఈ మాసంలో వృధా ధనవ్యయం అధికంగా ఎదుర్కొందురు. ఈ మాసంలో 12, 13, 14, 18, 22, 26 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
          
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసం అనుకూలమైన కాలం. ప్రతీ కార్యం దైవ ఆశీస్సులతో విజయం పొందును. కుటుంబ కలహాలు తొలగి బంధువుల, స్నేహితుల తోడ్పాటు లభించుట వలన కష్టములు నుండి బయటపడుడురు. ఆశించిన విధంగా ధన ప్రాప్తి పొందుతారు. అనారోగ్య సమస్యల నుండి ఉపశమనం లభిస్తుంది. అన్ని రంగముల వారికి ఈ మాసం అనుకూల ఫలితాలను కలుగచేస్తుంది. మీ చేతి మీద పుణ్యకార్యములు నిర్వహిస్తారు.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసం అంత అనుకూలమైనది కాదు. ఉహించని విధంగా వ్యాపార వ్యవహారములందు ఆదాయం తగ్గును. భవిష్యత్ అవసరాలకు సిద్ధం చేసుకున్న నిల్వ ధనం కూడా అవసరాలకు ఉపయోగించవలసి వచ్చను. వైవాహిక జీవితంలో ఆశించిన సంతోషాలు లభించవు. పెట్టుబడులు, శారీరక శ్రమ వృధా అగును. మానసికంగా ఒంటరితనం బాధించును. 13 వ తేదీ తదుపరి తలపెట్టిన ఏ ప్రయత్నం అనుకూలించక నిరాశ ఆవహించును. ఈ మాసంలో 16, 17, 25, 26 తేదీలు అనుకూలమైనవి కావు.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },

        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో ప్రారంభ వారములు అనుకూల ఫలితాలు ఇచ్చును. తృతీయ వారం నుండి వ్యతిరేక ఫలితాలు ప్రారంభం అగును. వైవాహిక జీవనంలో తీవ్ర మార్పులు , సమస్యలు ఏర్పడును. కోర్టు వ్యవహారాలలో ప్రతికూల తీర్పులు. ధనం వృధాగా వ్యయం అగును. వృత్తి - వ్యాపారములందు చాలా ఆలోచించుకొని నిర్ణయాలు తీసుకోవాలి. రాబోవు కాలం అంత అనుకూలమైనది కాదు. ఈ మాసంలో రుణాలు తీసుకోవడం లేదా ఇవ్వడం, వివాహాలు కుదుర్చుకోవడం, స్థిరాస్తుల క్రయ విక్రయాలు జరపడం, నూతన పెట్టుబడులు పెట్టడం మంచిది కాదు. దూర ప్రాంత ప్రయాణాలకు ఈ మాసం కూడా అనువైనది కాదు. ఈ మాసంలో 20, 21, 22, 23, 27,28 తేదీలు అనుకూలమైనవి కావు. అనుకోని కలహాలకు అవకాశం ఉన్నది.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
     
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం తీవ్ర ప్రతికూల ఫలితాలు కలుగచేయు సూచనలు అధికంగా ఉన్నవి. ఉద్యోగ, వ్యాపార వ్యవహారాలు సమస్యలు కలిగించును. ఆలోచనలు కార్యరూపం దాల్చవు. అన్ని విధాల ప్రతి బంధకాలు ఎదురగును. వృధా ప్రయాణాల వలన ధనము, సమయము కోల్పోవుదురు. దూర ప్రయాణాలు చేయకపోవడం మంచిది. వ్యక్తీ గత జీవన సుఖ సంతోషాలు తగ్గును. జీవన విధానంలో అనిచ్చితి ఏర్పడుతుంది. తలచిన పనులకు దైవ బలం, ఆశీస్సులు అవసరం. విలాస వస్తువులకు సంబంధించిన వ్యయం చేయకండి. స్థిరాస్తి లావాదేవీలలో మధ్యవర్తులను నమ్మకండి. స్త్రీలకు గౌరవ హాని కలిగించు సంఘటనలు కలవు.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ఆశించిన లక్ష్యాలను చేరుకోవడంలో స్వల్ప ఆటంకాలున్నాయి. ముఖ్యంగా స్త్రీలకు గౌరవ హాని సంఘటనలు కలవు. మాట విలువ తగ్గుతుంది. ఇతరులను విమర్శించుట వలన సమస్యలను కోరి తెచ్చుకుంటారు. మాటలయందు జాగ్రత్త అవసరం. వృత్తి వ్యాపారములు సామాన్యంగా కొనసాగును. ధనాదాయంలో నిలకడ లోపిస్తుంది. ఆర్ధిక విషయాలలో ప్రణాళిక అవసరం. కోర్టు లావాదేవిల వలన ధన వ్యయం అధికమగును. వివాహ ప్రయత్నాలు బెడిసికోట్టును. చివరి వారంలో ఒక ప్రమాదం లేదా పెద్ద నష్టం. నిదానంగా నిర్ణయాలు తీసుకొనవలసి వచ్చును.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో నూతన ప్రారంభాలకు ఆశించిన ఋణాలు లభించును. శరీర ఆరోగ్యం కొంత ఇబ్బందులు కలుగచేయును. 14 వ తేదీ వరకూ ప్రయత్నములు సులువుగా విజయవంతం అగును. 14 వ తేదీ తదుపరి అతి కష్టం మీద కార్య విజయం ఏర్పడును. వ్యాపార విస్తరణకు 14 వ తేదీ తదుపరి అనుకూలమైన సమయం కాదు. ఈ మాసంలో పనులు పూర్తి అవడానికి మధ్యవర్తుల పై ఆదరాపడకండి. అలసత్వం పనికిరాదు. కోర్టు వ్యవహారములు వాయిదా తీసుకోండి. గృహ వాతావరణంలో జీవన సంతోషములు మధ్యమం. ఈ నెలలో 18,25,30 తేదీలు మంచివి కావు. 5,6 తేదీలలో వివాహ ప్రయత్నములకు అనుకూలం. మొత్తం మీద ఈ మాసంలో ధనాదాయం సామాన్యం.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో కూడా ఆర్ధిక ఇబ్బందులు కొనసాగును. ఆర్ధికంగా ఒత్తిడి అధికం అగును. కుటుంబ ఖర్చులు కూడా చికాకులు కలుగచేస్తాయి. వ్యాపార లావాదేవీలు సమస్యలతో కొనసాగును. అఖస్మిక వ్యవహార నష్టం. వైవాహిక జీవనంలో పట్టుదల వలన వ్యవహారాలు సమస్యల పాలగును. మానసికంగా స్థిరత్వం లోపిస్తుంది. కుటుంబ విషయాల వలన అశాంతి కలుగుతుంది. ప్రేమ వ్యవహారాలలో నమ్మక ద్రోహం. ప్రతీ విషయంలో నిగ్రహం ప్రదర్శించాలి. గొడవలకు అవకాశం ఇవ్వకండి. భవిష్యత్ పై నమ్మకం, ఆశావాదం ఉండాలి.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
       
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో చాలా అంశాలలో అనుకూలమైన ఫలితాలు ఏర్పడును. సజ్జన సాంగత్యం లభించును. పిల్లలకు సంబంధించిన వినోద కార్యక్రమాలలో పాల్గొంటారు. నూతన వస్త్ర లేదా గృహ లేదా జీవిత భాగస్వామి మూలక ధన లాభములు ఏర్పడును. బ్యాంకు ఋణ బాధలు తొలగుతాయి. అవసరమైన ధనం చేతికి సకాలంలో అందును. భవిష్యత్ గురించిన అనుకూల బాటలు ఏర్పడును. ఉద్యోగ జీవనంలో స్థిరత్వం లభిస్తుంది. 17 వ తేదీ తదుపరి మాసాంతం వరకూ చక్కటి లాభకరమైన పరిస్థితులు ఏర్పడును. దూర దేశ ప్రయాణాలు చేస్తారు.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
     
     
    
      },
      //DEC
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో గతకాలపు సమస్యలు కొంత వరకు తగ్గును. ఆర్ధిక ఋణముల నుండి విముక్తి లభించును. అనారోగ్య సమస్యలు మాత్రం కొంత ఇబ్బంది కలిగించు సూచన. ప్రధమ వారంలో ఆకస్మిక ఆర్ధిక లాభములు ఏర్పడు సూచన. భాతృ వర్గం వారితో నెలకొనిన వివాదములు తొలగును. ద్వితియ వారం సామాన్య ఫలితాలు ఏర్పరచును. వ్యక్తిగత జీవన సౌఖ్యం ఏర్పడును. తృతియ వారం విద్యార్దులకు అనుకూలంగా ఉండును. పరదేశ విద్య కోసం చెసే ప్రయత్నాలు లాభించను. గృహంలో బందువుల రాకపోకలు ఉండగలవు. చివరి వారంలో వృత్తి జీవనంలోని వారికి పేరు ప్రఖ్యాతలు పెరుగును. గృహ నిర్మాణ సంబంధ మార్పు కోసం చేయు ఫలితాలు ఆటంకములతో ఫలించును. మొత్తం మీద ఈ మాసంలో ధనాదాయం బాగుండును.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో కూడా అనుకూల కాలం ఏర్పడుతుంది. ప్రణాళికాబద్ధమైన జీవనం లభిస్తుంది. వృత్తి ఉద్యోగాల్లో శ్రమకు తగ్గ ప్రతిఫలం లభిస్తుంది. నూతన ఆదాయ మార్గాలు లభిస్తాయి. గృహంలో నిర్మాణ సంబంధ మార్పులు చేస్తారు. నూతన వస్తువులను ఏర్పరచుకుంటారు. జీవిత భాగస్వామితో సౌఖ్యం ఏర్పడుతుంది. ధన సమస్యల నుండి బయటపడతారు. అధికారుల ప్రశంసలు అందుకుంటారు. కీర్తి పెరుగుతుంది. రాజకీయ పరంగా కూడా ఆశించిన సహకారం లభిస్తుంది. సాంకేతికంగా నూతన పరిజ్ఞానం సంపాదించుకొంటారు. కుటుంబ ప్రయాణాలకు సిద్ధం అవుతారు. ఈ మాసంలో 2, 7, 21, 28, 29 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో సంతోషకరమైన వైవాహిక జీవన యోగములు ఉన్నవి. గృహంలో బంధువుల కలయిక, సంతోషకర వాతావరణం ఏర్పడుతుంది. ధనాదాయం సామాన్యంగా ఉంటుంది. మీ పైన కుటుంబ పెద్దలు పెట్టుకున్న ఆశలు నేరవేర్చగలరు. మొత్తం మీద ఈ మాసంలో ఆశించిన సుఖ సంతోషాలు పొందగలుగుతారు",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో సమస్యలు చాలా వరకు తగ్గును. ఆలోచనలు కార్యరూపం దాల్చును. కుటుంబ ఖర్చులు తగ్గించగలుగుతారు. సోదర వర్గం వారితో సమస్యలు కొనసాగును. ఉన్నత అధికారులతో చేసే చర్చలు ఫలించును. ఉద్యోగ సమస్యలకు పరిష్కారములు లభించును. తృతీయ వారంలో సంతాన అనారోగ్యత వలన చికాకులు ఏర్పడును. విదేశీ జీవన ప్రయత్నాలు, ఉద్యోగంలో ప్రమోషన్లకు చేసే ప్రయత్నాలలో విఘ్నాలు ఎదుర్కొందురు. వివాహ ప్రయత్నాలకు కూడా ఇబ్బందులు ఎదురవును. స్త్రీలు మానసిక ఆందోళనకు లోనగు పరిస్థితులు ఏర్పడును. ఈ మాసంలో చేసిన భూమి లేదా గృహ సంబంధ రుణాలు అంత త్వరగా తీరవు. ప్రయాణాలలో జాగ్రత్తగా ఉండవలెను. వాయిదా వేసుకోవడం మంచిది.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },

        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో గత కాలంలో నిలిచిపోయిన పనులు, కార్యములను విజయవంతంగా పూర్తి చేయగలుగుతారు. సంతాన సంబందిత విషయాలలో మాత్రం చికాకులు ఏర్పడును. సంతానం కొరకు అధికంగా ధనం ఖర్చుపెట్టవలసి వచ్చును. స్నేహితుల పనులు కోసం వృధాగా శ్రమించవలెను. గృహ సంతోషములు మధ్యమం. ఈ నెలలో 22 నుండి 29 తేదీల మధ్య ఆర్ధిక సంబంధ కార్యక్రమాలలో జాగ్రత్త వహించవలెను. చివరి వారంలో వ్యాపార రంగంలోని వారికి ఆకస్మికంగా ధనాదాయం స్థంభించును.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో మిశ్రమ పరిస్థితులు ఎదుర్కొందురు. ప్రధమ అర్ధ భాగంలో ఉద్యోగ జీవన నష్టం, కార్య విఘ్నతలు, చిన్న పిల్లలకు శారీరక హాని, వృత్తి - వ్యాపార రంగం వార్కి ఆదాయంలో తగ్గుదల, వ్యవహార సమస్యల వలన మానసిక అశాంతి వంటి అననుకూల ఫలితాలు ఏర్పడతాయి. 19 వ తేదీ తదుపరి అనవసర ఖర్చులు తగ్గుతాయి. నూతన అవకాశములు లభిస్తాయి. శారీరక శ్రమ తగ్గుతుంది. నూతన కుటుంబ సంబంధాలు ఏర్పడుతాయి. ఈ మాసంలో 4, 7 9, 12 , తేదీలు అనుకూలమైనవి కావు.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో కూడా మిశ్రమ ఫలితాలు ఏర్పడును. సున్నితమైన విషయాల్లో మానసిక ఆందోళన ఏర్పడును. ఈ మాసంలో 13, 14 తేదీలు మినహా మిగిలిన రోజులలో ప్రయాణాలు చేయవచ్చు. విదేశీ నివాస ప్రయత్నాలు చివరి నిమిషంలో విజయవంతం అవుతాయి. వృత్తి జీవనం ద్వారా ధనార్జన చేయువారు జాగ్రత్తగా ఉండవలెను. మనో నిగ్రహాన్ని ప్రదర్శించాలి లేనిచో అపఖ్యాతి పాలగుదురు. ప్రస్తుత హోదాను కోల్పోవుదురు. అఖస్మిక వ్యవహార నష్టములకు సూచనలు కలవు. ఈ మాసంలో గురు గ్రహ శాంతి జపము జరిపించుకోనుట మంచిది.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
     
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం కూడా చాలా అప్రమత్తంగా ఉండాలి. ఇతరుల సహాయ సహకారాలు ఆశించకుండా ఉండుట మంచిది. ఖర్చులను తగ్గించుకోవాలి. ఉద్యోగ వ్యాపార జీవనల్లో ఆఖస్మిక నష్టములు ఎదురగుటకు సూచనలు ఉన్నవి. యువకులు తప్పుడు మార్గములలో ప్రయాణించే అవకాశములు అధికం. పనులు ముందుకు సాగవు. అనురాధ నక్షత్ర జాతకులు కళత్ర జీవనంలో సమస్యలు ఎదుర్కొంటారు. బంధు - మిత్ర విరోధాలు బాధించును. ఆరోగ్య పరంగా చ్చాతి సంబంధ లేదా శ్వాస సంబంధ సమస్యలు బాధించును. మొత్తం మీద ఈ మాసం అనుకూలమైనది కాదు. అన్ని రంగాల వారికి అంతగా కలసి రాదు.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ధనాదాయం తగ్గును. ఆశించిన ధనం చేతికి వచ్చుట కష్టం. స్థిరాస్తి వ్యవహారాలు ఇబ్బందులు కలుగచేయును. పట్టుదలలు ఏర్పడును. ఉద్యోగ జీవనంలో జాగ్రత్తగా ఉండవలెను. నిరుద్యోగులకు నిరాశాపూరితమైన కాలం. ప్రారంభించిన పనులు ముందుకు సాగవు. మిత్ర బలం కూడా తగ్గుతుంది. సున్నితమైన విషయాలలో మానసిక ఆందోళన ఏర్పడుతుంది. ముఖ్యంగా స్వ ఆరోగ్య విషయంలో భయానికి లోనగు అవకాశం అధికం. ఈ మాసంలో 8, 12, 14, 21, 22 తేదీలు ఎదో ఒక అంశంలో నష్టాన్ని ఏర్పరచు సూచన.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం కొంచం తగ్గును. వృత్తి , ఉద్యోగ , వ్యాపారములలో ఆశించిన విధంగా ఫలితాలు ఉండవు. ఆధార పడిన వారి నుండి సహకారము లభించదు. నూతన ప్రయత్నాలు వాయిదా వేసుకోవడం మంచిది. కుటుంబ సభ్యుల వలన అనారోగ్య మూలక ధనవ్యయం ఎదురగును. తృతీయ వారంలో దైవదర్శన, పుణ్య క్షేత్ర సందర్శన ఫలితం ఏర్పడును. 21 వ తేదీ తదుపరి సంతాన ప్రయత్నాలలో శుభ వార్త. మాసాంతమునకు ఉద్యోగ పరిస్టితులలో కొంచం ప్రశాంతత లభించును. ఈ నెలలో 5,14,20,29 తేదీలు మంచివి కావు. ఈ తేదీలలో చేయు ప్రయాణములందు జాగ్రత్తగా ఉండాలి. మరియు ఈ తేదీలలో స్థిరాస్తి క్రయవిక్రయాలు కలసిరావు.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో సమస్యలు తగ్గుముఖం పట్టును. ఆర్ధికంగా కొంత ఉపశమనం లభిస్తుంది. ధన వ్యయం తగ్గును. సమస్యలకు కారణమును గుర్తించగలరు. గృహంలో వాస్తు సంబంధ మార్పులు చేయుదురు. సోదర వర్గం వలన సహకారం పొందుతారు. నూతన ఆలోచనలు క్రమేపి కార్యరూపం పొందుతాయి. క్రమక్రమంగా మానసిక అశాంతి తగ్గుతుంది. మనోభీతి తగ్గును. నూతన ఉద్యోగ ప్రయత్నాలు విజయవంతం అగును. వ్యక్తిగత జీవనంలో సౌఖ్యం ఏర్పడును. పుణ్యక్షేత్రాలు సందర్శిస్తారు. పట్టుదలతో పరిష్కార సాఫల్యత ఏర్పడుతుంది. ప్రయాణాలు కలసి వచ్చును. 26, 27, 28 తేదీలలో వివాహ ప్రయత్నాలు చేయండి. విజయవంతం అవుతాయి.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
     
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో ధనాదాయం బాగుంటుంది. జీవిత భాగస్వామి పనులకై సమయం వెచ్చిస్తారు. మీ సహకారం వలన వారికి కార్యజయం ఏర్పడుతుంది. వ్యక్తిగత జీవనంలో సౌక్యం ఏర్పడును. ఆర్ధిక విషయాలలో మిత్ర వర్గంపై ఆధారపడకుండా ఉండుట మంచిది. కుటుంబంలో ఎదుర్కొంటున్న వృధా వ్యయం తగ్గించగలుగుతారు. ద్వితియ వారంలో వాహనముల వలన ఇబ్బందులు కలుగును. పరోపకారం వలన ఆత్మతృప్తి లభించును. విదేశీ లేదా ఉద్యోగ స్థానచలన ప్రయత్నములలో కష్టం మీద విజయం చేకురును. 7,8,9,10 తేదీలలో నూతన ప్రయత్నాలు చేయుట మంచిది.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
      },
    ],
    [
      //JAN
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. నివాస గృహంలో దీర్ఘకాలంగా ఆశిస్తున్న అలంకరణ సంబంధ మార్పులు చేయగలుగుతారు. నూతన వస్తువులను అమర్చుకుంటారు. గృహ సంతోషాలు సంపూర్ణంగా ఉండును. ప్రతీ వ్యవహారం ఆశించిన విధంగా నిదానంగా పూర్తిఅగును. 21వ తేదీ తదుపరి చేయు ప్రయనములందు జాగ్రత్త అవసరం. వ్యక్తిగత జాతకంలో శని గ్రహ దోషం కలిగిన వారికి వాహన ప్రమాద సూచన లేదా ప్రయాణ సంబంధ ఆరోగ్య భంగం కలవు. ఈ మాసంలో 21, 24, 25 తేదీలు అనుకూలమైనవి కావు. ఈ మాసంలో నల్లని వస్త్రములు ధరించకుండా ఉండుట మంచిది.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో ధనాదాయం బాగుండును. సమయాన్ని కుటుంబ సభ్యులతో ఆహ్లాదకరంగా గడిపెదురు. నిరుద్యోగులకు ఉద్యోగ ప్రయత్నాలు విజయవంతం అగును. దూరప్రాంత జీవన ప్రయత్నాలు విజయమగును. కుటుంబానికి నూతన సౌకర్యాలు ఏర్పాటు చేస్తారు నూతన వ్యాపార వ్యవహారాలు ప్రారంభిస్తారు. ఆశించిన విధంగానే వ్యవహారాలు కొనసాగుతాయి. పనిచేయు కార్యాలయంలో స్త్రీలకు గౌరవ మర్యాదలు పెరుగుతాయి. 15 వ తేదీ నుండి 18వ తేదీ మధ్య కాలంలో పాద సంబంధ వ్యాధులకు అవకాసం ఉన్నది. వివాహ ప్రయత్నాలలో కొన్ని అవాంతరాలు ఎదురవుతాయి. ఈ మాసంలో వివాహ ప్రయత్నాలు వాయిదా వేయడం మంచిది.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో ధన - ధాన్య లాభములు ఉన్నవి. స్త్రీలకు మనో అభీష్టం నెరవేరుతుంది. ఉద్యోగ వ్యాపారాలు సంతృప్తికరమైన మార్గంలో కొనసాగును. ధనాదాయంలో పెరుగుదల ఉంటుంది. మంచి భవిష్యత్ కు పునాదులు వేసుకొంటారు. కుటుంబ కీర్తి వృద్ధి చెందుతుంది. దీర్ఘకాలిక ఆరోగ్య సమస్యల నుండి కొంత ఉపశమనం లభిస్తుంది. సంతాన విషయమై వైద్య సహాయం తీసుకొంటారు. ముఖ్య వ్యక్తులతో తలపెట్టిన చర్చలు ఫలప్రదమవుతాయి. గత అనుభవాలను దృష్టిలో పెట్టుకొని గంభీరంగా వ్యవహరించాలి. చివరి వారంలో కోర్టు కేసులు కొట్టివేయబడతాయి. మానసిక ప్రశాంతత లభిస్తుంది.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడును. ఆశించిన ధనాదాయం లభించును కాని కుటుంబ సభ్యుల వలన జరిగే నమ్మక ద్రోహం, పై అధికారుల వలన ఏర్పడు సమస్యలు, పని ఒత్తిడి , నేత్ర సంబంధ ఆరోగ్య సమస్యలు మానసికంగా దిగులు ఏర్పరచును. చేపట్టిన పనులు నిదానంగా పుర్తిఅగును. ఈ మాసం మీ సహనానికి పరీక్షాకాలం. గర్భవతులు జాగ్రత్తగా ఉండవలెను. చివరి వారంలో బంధు వర్గం వారితో చికాకులు, పెద్ద వయస్సు వారికి జీర్ణ సంబంధ సమస్యలు. విందు - వినోద కార్యములకు దూరంగా ఉండుట మంచిది. పోలిసుల వలన ఇబ్బందులకు అవకాసం ఉన్నది.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో ప్రధమ వారంలో నిరుద్యోగులకు ఉద్యోగ ప్రాప్తి. 5,6 తేదీలు విదేశీ సంబంధ ప్రయత్న వ్యవహారాలకు అనుకూలమైనవి. ద్వితియ వారంలో ఆర్ధిక ఋణములు తీరును. నూతన పరిచయాలు ఏర్పడును. తృతీయ వారం నుండి సామాన్య ఫలితాలు. 25 వ తేదీ తదుపరి గృహంలో శుభకార్య సంబంధ ఉత్సాయపూరిత వాతావరణం. మాసం మొత్తం మీద ధనాదాయం బాగుండును.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసం బాగుంటుంది. ఆరోగ్య సమస్యల వలన బాధపడుతున్నవారికి ఉపశమనం లభించును. ప్రభుత్వ అధికారుల వలన ఏర్పడుతున్న సమస్యలు తొలగును. నూతన గృహ నిర్మాణ సంబంధమైన ప్రయత్నములు చేయుటకు ఈ మాసం అనుకూలమైన కాలం. ఉద్యోగ జీవనంలో పదోన్నతిని ఆశించవచ్చు. ధార్మిక కార్యక్రమాలకు సహకరిస్తారు. శ్రమ వలన పేరు ప్రఖ్యాతలు ఆర్జిస్తారు. జీవిత భాగస్వామి సంబంధ విషయాలలో కూడా అనుకూలత ఏర్పడుతుంది. మనోబలం పెరుగును. గురువులు , పెద్దల సహాయ సహకారములు లభించును. ఎదురుచూస్తున్న శుభవార్త వింటారు.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. మానసికంగా ఇబ్బంది పెట్టె అనుభవాలు తరచుగా ఉండును. చేపట్టిన పనులలో తీవ్ర ఒత్తిడులు ఎదుర్కొందురు. ద్వితీయ వారంలో వివాహ సంబంధ ప్రయత్నాలు విజయవంతం అగును. వాగ్ధాన భంగములు చేయకండి. ఉన్నత అధికారులతో మాటకలయికలో ఇబ్బందులు ఎదురగును. తృతీయ వారంలో సోదర వర్గం నుండి సహకారం లభించును. సంతాన సంబంధ శుభవార్త. అవసరములకు ధనం సర్దుబాటు అగును. ఈ మాసంలో 7, 8, 10, 12, 13, 17 తేదీలలో ముఖ్యమైన నిర్ణయాలు తీసుకొనరాదు. అగ్రిమెంట్లు చేయరాదు. మానసిక ధైర్యం తగ్గును. 22 వ తేదీ తదుపరి కొంత వరకూ ఇబ్బందులు తగ్గును. పరిష్కార మార్గములు కనిపిస్తాయి.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
    
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడును. 14 వ తేదీ వరకూ కొంత నిరాశాజనకంగా ఉండును. ప్రమాదాల నుండి తప్పించుకోనుదురు. కుటుంబ విషయాలకై ఒంటరి పోరాటం చేయవలసి ఉంటుంది. మానసిక సంతృప్తి ఉండదు. ఉత్తరాయణ పుణ్య కాల ప్రారంభం నుండి కొంత ఒత్తిడి తగ్గును. సంతాన మూలక లాభము ఏర్పడును. బంధుత్వాలు బలపడును. సహాయం లభిస్తుంది. వివాహాది ప్రయత్నాలు చురుకుగా ముందుకు కదులుతాయి. దైవ సంబంధ కార్యములందు పాల్గొంటారు. కుటుంబ సమస్యలకు కారణం గుర్తించగలరు. యువకులకు వెన్నుపూస సంబంధ అనారోగ్య సూచన. ఈ మాసంలో 3, 7, 10, 12 తేదీలు మానసికంగా అధిక ఆందోళన కలిగించును.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో కూడా సమస్యలు కొనసాగును. ఆదాయం గత మాసం కన్నా కొంచం పెరుగును. ఆర్ధిక విషయాలలో సహాయం లభిస్తుంది. ప్రధమ వారంలో ఒక ముఖ్య వార్త వింటారు. గృహ మార్పిడి లేదా నిర్మాణ పనులలో మిక్కిలి అవాంతరాలు లేదా అతి వ్యయం. ఆందోళన పెరుగుతుంది. జీర్ణ సంబంధ సమస్యలు బాధించును. ఉద్యోగ కార్యములు పూర్తీ చేయుటకు శక్తికి మించి కష్టపడతారు. సోదర వర్గంతో మాట పట్టింపు విడిచిపెట్టుట మంచిది. నూతన వ్యాపార వ్యవహారాలు ఇబ్బందులు ఎదుర్కొనును. ప్రత్యర్ధుల నుండి వ్యతిరేకత అధికం అవుతుంది. వ్యాపార పరంగా నష్టములు పొందు సూచనలు అధికం.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో చేయు ప్రయత్నములలో ప్రారంభమున ఆటంకములు ఏర్పడినప్పటికీ అంతిమంగా విజయం లభించును. ధనాదాయం నెమ్మదిగా పెరుగును. గతకాలపు ఇబ్బందులు క్రమంగా తొలగును. ద్వితియ , తృతీయ వారములలో నూతన ఆదాయ మార్గములకై చేయు ప్రయత్నాలు లాభించును. కళత్ర మూలక ధనలాభం ఏర్పడును. వ్యక్త్రిగత జీవనంలో సౌఖ్యం. దూరపు బంధువులతో కలయిక వలన లాభం. 24 వ తేదీ తదుపరి వ్యాపారంలో గొడవలు. మానసిక అశాంతి. మొత్తం మీద ఈ మాసం అంత అనుకూలమైన మాసం కాదు.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో దూర ప్రాంత జీవనాన్ని ఆశించువారికి విజయం లభించును. వ్యాపారాదులు, ఉద్యోగ జీవనంలో సమస్యలు తగ్గును. అధికారుల సహకారం లభిస్తుంది. లక్ష్యాలను పూర్తీ చేయగలుగుతారు. ఆశించిన ధనాదాయం లభిస్తుంది. ఆత్మీయుల రాక వలన గృహంలో ఆనందకర సమయం. విందు వినోదాలను ఏర్పాటు చేస్తారు. బంధువర్గంతో చికాకులు తొలగుతాయి. శుభకార్యా ప్రయత్నాలు నెరవేరుతాయి. సంతానం వలన మానసిక ఆనందం పొందుతారు. తృతీయ , చతుర్ధ వారాలలో అనారోగ్య సమస్యల వలన చికాకులు.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో 2వ తేదీ నుండి 7వ తేదీ వరకూ కాలం అనుకూలంగా ఉండదు. గృహంలో చొరభయం లేదా విలువైన వస్తువులు చెడిపోవును. మానసికంగా ఆందోళన చెందుతారు. ద్వితియ వారంలో కార్యభారం పెరుగును. తీవ్ర కాలయాపనతో దూరప్రాంత ప్రయాణములు ఏర్పడతాయి. మిక్కిలి ప్రయాస అనంతరం కార్యములలో విజయం సాధిస్తారు. వృత్తి - వ్యాపారాదులు సామాన్యంగా కొనసాగును. ఆదాయంలో పెరుగుదల కొరకు నూతన మార్గములకై చేయు ఆలోచనలు ఫలిస్తాయి. పుత్రికా సంతానం వలన కొద్దిపాటి చికాకులు. ఉద్యోగ జీవనం సామాన్యంగా కొనసాగును. మాసాంతంలో అధికారుల, పెద్దల సహాయ సహకారములు లభించును.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
      },
      //FEB
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో ధనసంబంధమైన చికాకులు భాదించును. వృధా వ్యయం చేయకండి. ప్రధమ ద్వితీయ వారములలో నూతన వ్యక్తుల పరిచయాలు ఏర్పడును. కష్టంతో కార్యవిజయం. విరోధుల వలన ఉద్యోగ జీవితంలో ఇబ్బందులు ఎదుర్కొందురు. పెద్దలకు వైరాగ్య భావన ఏర్పడగలదు. తృతీయ వారంలో కొంత ఒత్తిడి తగ్గును. సంతాన సంబంధ సుఖ సంతోషములు నెలకొనును. నిరుద్యోగుల ఉద్యోగ ప్రయత్నాలు లేదా చేయుచు ఉన్న ఉద్యోగ జీవనంలో మార్పు కొరకు చేసిడి ప్రయత్నాలు సఫలము అగును. ఈ మాసంలో 15 నుండి 22వ తేదీ మధ్య చేయు వివాహ ప్రయత్నములు విజయవంతం అగును.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. ఉద్యోగ జీవనంలో అదృష్ట యోగం ఉన్నది. హోదాలో పెరుగుదల ఆశించవచ్చు. గ్రంధ రచన లేదా ముద్రణా రంగంలోని వారికి ఈ మాసం విశేషమైన ఆర్ధిక లాభాన్ని ఏర్పరచును. ప్రమాదాల నుండి బయటపడతారు. అపోహలు తొలగుతాయి. మనస్తాపం కలిగించే మనుషుల నుండి దూరం కాగలుగుతారు. ప్రయాణాలు లాభిస్తాయి. బంధువులకు సహకరించగలరు. నూతన అవకాశముల అన్వేషణ ఫలిస్తుంది. విద్యార్ధులకు మంచి భవిష్యత్ ఏర్పడుతుంది. మొత్తం మీద ఈ మాసం అనుకూలంగానే ఉంటుంది.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో ఒక శస్త్రచికిత్స లేదా పెద్ద ప్రమాదం నుండి తప్పించుకొనుట వంటి ఫలితాలు ఎదుర్కొంటారు. మాసం ప్రధమ అర్ధ భాగంలో గృహంలో ఒక ముఖ్య వస్తువు కనిపించకుండా పోవుట వలన ఆందోళన ఎదుర్కొంటారు. ఉద్యోగ జీవనంలో కార్యభారం పెరుగును. దూరప్రాంత ప్రయాణములు అనుకూలంగా ఉండవు. ప్రేమ వ్యవహారాలలో మనోవేదన ఎదురగును. తీవ్ర ప్రయాస అనంతరం కార్యములు విజయం పొందును. వ్యాపారాదులు సామాన్యంగా కొనసాగును. ఆదాయంలో పెరుగుదల కొరకు నూతన మార్గములు అన్వేషిస్తారు. ఎదిగిన పుత్ర సంతనముతో కొద్దిపాటి అభిప్రాయ బేధాలు ఏర్పడును. ఉద్యోగ జీవన ఫలితాలు సామాన్యం. ఈ మాసంలో 1, 3, 4, 16, 27 తేదీలు అనుకూలమైనవి కావు.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో సమస్యలు తగ్గును. ఉద్యోగ జీవనంలో మంచి ప్రోత్సాహం లభించును. ఉన్నత మార్పును ఈ మాసంలో ఆశించవచ్చు. వైవాహిక జీవన సమస్యలు కూడా తగ్గును. విదేశీ జీవనమును కోరుకుంటున్న వారికి శుభ ఫలితాలు. కుటుంబ అనారోగ్యత తగ్గుముఖం పట్టును. చేజారిన వివాహ సంబంధాలు తిరిగి వచ్చుటకు సూచనలు అధికం. ఈ మాసంలో సువర్ణ సంబంధ కొనుగోలు, పెట్టుబడులు లాభించును. 11 వ తేదీ నుండి 22 తేదీలు గత కాలంలో ఆగిపోయిన పనులను తిరిగి ప్రారంభించుటకు అనుకూలమైనవి. పటిష్టమైన ప్రణాళికలు రూపొందించుకోవడానికి , బంధుత్వాలను తిరిగి కలుపుకోవడానికి ఈ మాసం మంచి కాలం. 25, 26, 27, 28 తేదీలలో కాంట్రాక్టు పనులు చేయు వారికి, వడ్డీ వ్యాపారం చేయువారికి మంచి ధన లాభములు ఏర్పడును.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },

        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో సంతాన సంబంధ ప్రయత్నాలలో చక్కటి లాభములు పొందుతారు. ఆశించిన విధంగా సంతాన ప్రాప్తి ఏర్పడును. ధనాదాయం సామాన్యం. 12వ తేదీ తదుపరి ఉద్యోగ ఉన్నతి ఏర్పడు సూచన. అందరి మన్ననలూ లభించును. ద్వితియ వారం తదుపరి నూతన గృహ ప్రయత్నములు ఫలించును. వ్యక్తిగత జీవనంలో ఆందోళనతో కూడిన ఆలోచనలు అధికమగును. ఒక ముఖ్య నిర్ణయాన్ని తీసుకోవలసి వస్తుంది. ఈ మాసంలో ప్రారంభించు నూతన వ్యాపారములు విజయవంతం అగును.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసం ప్రారంభ రోజులలో ద్రవ్య లాభం పొందుతారు. బంధు వర్గం వలన సహకారం పొంది నూతన స్తిరాస్తులను ఏర్పాటు చేసుకొంటారు. వృత్తి జీవనంలోని వారికి గౌరవం, చక్కటి అభివృధ్హి ఏర్పడును. విదేశీ జీవనం చేయువార్కి ఈ మాసం కలసిరాదు. సంతానం మీ అభీష్టానికి వ్యతిరేకంగా ప్రవర్తించుదురు. సంతానంతో విరోధములు ఏర్పడును. స్త్రీలకు అపవాదులు భాదించును. మనశ్శాంతి లోపించును. మాసాంతమునకు ప్రతీ వ్యవహారం శ్రమకరంగా ఉండును. ముఖ్యంగా 26, 27, 28 తేదీలు మంచివి కావు.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో కుటుంబ సమస్యలు తగ్గుతాయి. మనస్పర్ధలు తొలగును. భాగస్వామ్య వ్యాపారములు ఈ మాసంలో కలసి వస్తాయి. వ్యాపారులకు ధనాదాయం పెరుగును. రుణాలు తీర్చగులుగుతారు. నూతన నిర్ణయాలు తీసుకోవచ్చు. ఈ మాసంలో తీవ్ర ప్రతికూల ఫలితాలు, అనుకూలం కాని రోజులు లేవు. ప్రయాణాలు చేయుట వలన మానసికంగా ప్రశాంతత లభించును. నూతన ప్రణాళికలు రచించుకొందురు. కొత్త సంబంధాలు ఏర్పడును. బంధు వర్గానికి సహాయం చేసెదరు.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
      
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో ఆకస్మిక ప్రమాదాలు ఏర్పడు సూచన. జాగ్రత్తగా ఉండవలెను. నమ్మిన వారి వలన ఊహించని విధంగా ఒక నష్టం లేదా నింద ఎదురగును. కోర్టు వ్యవహారాలు లేదా ప్రభుత్వ జరిమానాలు చికాకు కలిగించును. ధనాదాయం సామాన్యం కాని ధన వ్యయ సూచన బలంగా ఉన్నందున ఆర్భాటములకు దూరంగా ఉండవలెను. . స్థాన చలన ప్రయత్నాలు విఫలమగును. ఉద్యోగ వ్యాపార వ్యవహారాలు సామాన్యంగా కొనసాగును. శుభకార్యములు వాయిదా పడును. చివరి వారంలో కొద్దిపాటి కార్యానుకులత ఏర్పడును. ఈ మాసంలో ఎదుటివారిని ఏ సందర్భంగానూ విమర్శించవద్దు. తగాదాలలో తగ్గి ఉండడం మంచిది.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో విరివిగా ప్రయాణాలు ఏర్పడును. ప్రయత్నపుర్వక కార్య లాభాలు ఏర్పడును. గాడి తప్పిన ఆర్ధిక విషయాలు క్రమంగా దారిలోకి వస్తాయి. ఉద్యోగ వ్యాపార వ్యవహారాలలో ప్రోత్సాహక కాలం ప్రారంభం అవుతుంది. ఈ మాసంలో వివాహ ప్రయత్నాలు చేయవచ్చు. ఇబ్బందుల నుండి బయటపడతారు. శత్రు విజయం ఏర్పడుతుంది. గృహంలో శుభ పరిణామాలు ఉన్నాయి. గొప్ప స్థాయి వ్యక్తుల ద్వారా లాభపడతారు. మాసాంతంలో సంతానం పట్ల శ్రద్ధ వహించాలి. ఈ మాసంలో 4, 5, 6 తేదీలు నూతన ప్రయత్నాలు చేయుటకు అనుకూలమైనవి.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో చక్కటి ఆదాయమునకు సూచనలు కలవు. కెరీర్ పరంగా ఉన్నతి పొందుతారు. పెద్ద వయస్సు వారికి శరీర అరోగ్యం సహకరించదు. గత కాలపు పెట్టుబడుల నుండి రాబడి ప్రారంభమగును. పశు సంబంధ హాని పొందుటకు సూచనలు ఉన్నవి. 14, 15, 16, 17 తేదీలలో కార్యానుకులత ఏర్పడును. ప్రభుత్వ రంగ ఉద్యోగం చేయువారికి వారికి చాలా అనుకూలమైన కాలం. గృహంలో మార్పులు చేయుటకు ఇది మంచి సమయం. 26, 27, 28, 29 తేదీలలో చేయు దూర ప్రాంత నివాస ప్రయత్నములు లాభించును. ఆలోచనలు సక్రమ మార్గంలో ఉంటాయి. అభివృద్ధికర జీవనం ఏర్పడుతుంది.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. నూతన ఆలోచనలను తిరిగి కార్యాచరణలో పెట్టాలని చూస్తారు. కోర్టు లావాదేవీలు, మిత్ర వర్గ తగాదాలు పరిష్కారం పొందుతాయి. దైవ కార్యములందు ఆసక్తి ఏర్పడును. వ్యాపార విస్తరణ పనులు పూర్తిచెయగలరు. పెట్టుబడులు లభిస్తాయి. సకాలంలో పనులు పూర్తగుట వలన ఒత్తడి తగ్గుతుంది. దాంపత్య సుఖం పొందుతారు. కుటుంబ అంతర్గత సమస్యలను వివేకంతో పరిష్కరిస్తారు. స్వ ఆరోగ్య విషయాలలో ఉదర సంబంధ సమస్యలు చికాకులు కలిగించు సూచన.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో అంతగా అనుకూల ఫలితాలు లేవు. వ్యక్తిగత ఆరోగ్యంలో జ్వరతత్వం వలన బాధలు ఏర్పడును. కష్టంతో పనులు పుర్తిచేయగలుగుతారు. గత కాలంలో దాచుకున్న ధనము కొంత వ్యయం అగును. 16వ తేదీ నుండి నుండి ఆందోళనకర పరిస్థితలు అధికం అగును. ప్రభుత్వ అధికారుల వలన తీవ్ర ఆటంకములు. చివరి వారానికి ఆదాయం కన్నా వ్యయం అధికంగా ఉండగలదు. ప్రతీ వ్యవహారంలో బాగా అలోచించి నిర్ణయాలు తీసుకోవాలి. విద్యా సంబంధ ప్రయత్నాలలో ఆర్ధిక ఇబ్బందులు ఏర్పడును. ఈ మాసంలో 7,9,11,25 తేదీలు అనుకూలమైనవి కావు.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
      },
      //MAR
      {
        MeshaRasi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడును. ప్రధమ అర్ధ భాగంలో మంచి అనుకూల వాతావరణం ఏర్పడును. కుటుంబంలో చికాకులు తొలగును. పనులు వాయిదా పడకుండా ఆశించిన విధంగా పూర్తీ అగును. భాత్రు వర్గంతో ఘర్షణలు తొలగును. ఆశించిన ధనప్రాప్తిని పొందుతారు. ప్రయాణాలు సౌకర్యవంతంగా పూర్తీ అగును. గృహంలో బంధు లేదా స్నేహ వర్గ కూడికలు ఏర్పడును. విదేశీ నివాస ప్రయత్నములు చేయువారికి శుభం. ద్వితీయ అర్ధ భాగంలో ధన అవసరములు పెరిగి మానసిక ఆందోళన ఏర్పడును. కుటుంబంలోని పెద్దవయ్యస్సు వారికి అనారోగ్య సమస్యలు కొనసాగును.",
          Path: RashiIcons,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మేష రాశి",
          Avamanam: "7",
        },
        vrishabhaRashi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "7",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభ రాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభ రాశి.",
          Para: "ఈ మాసంలో ఆశించిన పనులు కార్యరూపం దాల్చును. ఉద్యోగ వ్యాపార వృత్తి జీవనాలలో మంచి ప్రోత్సాహం ఉంటుంది. నూతన ఉన్నత వర్గీయుల పరిచయాలు ఏర్పడుతాయి. సంతాన లేమి దంపతుల సంతాన ప్రయత్నాలు విజయం పొందును. వ్యక్తిగత జీవితంలో కొద్దిపాటి అననుకుల ఫలితాలు ఎదురగును. జీవిత భాగస్వామితో చికాకులు. ధనాదాయం బాగుండును. పెద్దవయస్సు వారికి కుడి నేత్ర సంబంధ సమస్యలు బాధించును.",
          Path: Vrushabha,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Avamanam: "3",
        },
        MithunaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "7",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో పనులలో విఘ్నములు క్రమేపి తగ్గుముఖం పట్టును. ధనాదాయం సామాన్యంగా ఉంటుంది. ఈ మాసంలో ఉద్యోగ ప్రయత్నాలు, స్థిర నివాశ మార్పులకై చేయు ప్రయత్నాలు విజయవంతం అగును. తృతీయ వారంలో 13, 14, 15 తేదీలలో వాహన సంబంధ తగాదాలు ఎదురగుట వలన అశాంతి పొందుతారు. ఈ మాసంలో 18, 19, 20, 21 తేదీలలో నూతన ఉన్నత అవకాశములు లభించును. మాసాంతానికి గృహంలోకి నూతన వస్తువుల సమకుర్చుకొందురు. భూ సంబంధ క్రయ విక్రయాలలో ఆశించిన లాభాలు పొందుతారు. జీవిత భాగస్వామి వలన సౌఖ్యం వంటి అనుకూల ఫలితాలు ఏర్పడును.",
          Path: Midhuna,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Avamanam: "3",
        },
        karkatakaRasi: {
          Aadayama: "8",
          Vyayam: "2",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో సంతాన సంబంధ విషయాలు ప్రతికూల ఫలితాలు ఇచ్చును. ఉద్యోగ, వ్యాపార వ్యవహారాలు సామాన్య యోగమును కలుగచేయును. ధనాదాయం సామాన్యం. వ్యక్తిగత జీవనంలో నూతన వ్యక్తీ వలన తీవ్ర మానసిక ఆందోళన అనుభవిస్తారు. నిరుద్యోగులకు కూడా ప్రతికూల ఫలితాలు ఎదురగును. ఆశించిన ఉద్యోగాలు చివరి నిమిషంలో చేజారిపోవును. నరఘోష వలన ఇబ్బందులు ఎదుర్కొనవలసి వస్తుంది. మితభాషణం మంచిది. ఈ మాసంలో సాహసోపేత నిర్ణయాలు తీసుకోవద్దు. ప్రతీ పని స్వయంగా చేసుకోవడం మంచిది. తగాదలలో సర్దుకుపోవడం ఉత్తమం.",
          Path: Karkata,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Avamanam: "3",
        },
        SimhaRasi: {
          Aadayama: "11",
          Vyayam: "11",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహ రాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహ రాశి.",
          Para: "ఈ మాసంలో విద్యార్ధులకు అఖండ విజయం ఏర్పడును. నూతన వ్యాపారముల ద్వారా చక్కటి ధన ప్రాప్తి పొందుతారు. వృత్తి నిపుణులకు సులువైన ధన సంపాదన ఏర్పడును. ఉద్యోగ జీవనంలోని వారికి మాత్రం మిశ్రమ ఫలితాలు. పై అధికారులతో మాట పడుదురు. శ్రమకు తగిన ఫలితం వుండదు. తోటి ఉద్యోగుల వలన ఇబ్బందులు. ద్వితియ మరియు తృతీయ వారములు సామాన్య ఫలితాలు. మాసాంతంలో ఒక ముఖ్య వ్యవహారం అటంకములను పొందును. ఈ మాసంలో 19, 20, 25, 26, 27 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path: Simha,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Avamanam: "6",
        },
        KanyaRasi: {
          Aadayama: "14",
          Vyayam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యా రాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యా రాశి.",
          Para: "ఈ మాసంలో గృహంలోనూ , వృత్తి వ్యాపార ఉద్యోగ జీవనంలో అనుకూలమైన పరిస్థితిలు ఏర్పడతాయి. చక్కటి లాభకరమైన వాతావరణం మరియు సంతృప్తికరమైన ఫలితాలు పొందుతారు. నూతన వాహన సంబంధ ప్రయత్నాలు లాభించును. కాలయాపన చేస్తూ , వాయిదా వేస్తూ వస్తున్న పనుల వలన మరింత ఇబ్బందులు ఎదుర్కొందురు. 16 నుండి 21 వ తేదీ మధ్య ఆహార సంబంధ అనారోగ్యం భాదించు సూచన.",
          Path: Kanya,
          Rajapujyam: "6",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Avamanam: "2",
        },
        TulaRasi: {
          Aadayama: "11",
          Vyayam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "7",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులా రాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులా రాశి.",
          Para: "ఈ మాసంలో ధనాదాయం కొంత పెరుగును. నూతన గృహ నిర్మాణాలకు చేయు ప్రయత్నాలు ఫలించును. దీర్ఘాలోచనలు కలసి వచ్చును. వివాదాలలో రాజీలు చేసుకొందురు. నిరుద్యోగులకు కూడా ఈ మాసం ఆశాజనకంగా ఉండును. స్థాన చలన లేదా ఉద్యోగ మార్పిడి ప్రయత్నాలు చేయవచ్చు. నూతన పెట్టుబడులు కలసి వచ్చును. మాస మాధ్యమంలో సువర్ణ లాభం ఉన్నది. జీవిత భాగస్వామి సహకారంతో కుటుంబ పెద్దలను మెప్పించగలరు.",
          Path: Tula,
          Rajapujyam: "2",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "తులా రాశి",
          Avamanam: "2",
        },
    
        vrischikaRashi: {
          Aadayama: "2",
          Vyayam: "14",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో కొన్ని దీర్ఘకాలిక సమస్యల నుండి బయటపడతారు. కుటుంబ విషయాలలో ప్రశాంతత ఏర్పడుతుంది. రావలసిన ధనం చేతికి అందుతుంది. ప్రభుత్వ కాంట్రాక్టు వ్యవహారలందు వేగం పెరుగుతుంది. నిరుద్యోగులకు కూడా ప్రభుత్వం వలన లాభం ఏర్పడుతుంది. గృహమార్పిడి ప్రయత్నాలు ఫలించును. ఈ మాసంలో 11 నుండి 15 వ తేదీ మధ్య కాలంలో విలువైన పత్రాలు, ఆభరణాల పట్ల జాగ్రత్త అవసరం. తృతియ వారంలో నూతన అవకాశములు లభించును. కుటుంబ సభ్యుల ఆరోగ్య సమస్యలు తగ్గును. జీవిత భాగస్వామి సలహాలు పనిచేస్తాయి. మాసాంతానికి తలపెట్టిన పనులు సజావుగా సాగుతాయి.",
          Path: Vrushika,
          Rajapujyam: "5",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Avamanam: "2",
        },
        DhanasuRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. ధనాదాయం పర్వాలేదు. భాగస్వామ్య వ్యాపారములలో పెట్టుబడులు పెట్టిన వారికి నష్టములు ప్రాప్తించును. ద్వితియ వారంలో కుటుంబ సభ్యులకు సంబంధించిన ఒక అశుభ వార్త. గృహ వాతావరణం అశాంతిని కలిగి ఉండును. మాస మధ్యంలో ఖర్చులు అధికంగా ఉండును. పుబ్బా నక్షత్ర జాతకులకు వివాహ సంతాన విషయాలలో వ్యతిరేక ఫలితాలు. చివరి వారంలో దైవదర్శన భాగ్యం, ఒంటరగా ప్రయాణం చేయాలనే కోరిక నెరవేరును. విద్యార్దులు శ్రమించవలెను. ఈ నెలలో 2,6,7,15,29 తేదీలు మంచివి కావు.",
          Path: Dhanushu,
          Rajapujyam: "1",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Avamanam: "5",
        },
        MakaraRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకర రాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో ఇతరుల విమర్శలకు బాధపడకుండా మీ కర్తవ్యాన్ని పూర్తి చేయవలసిన పరిస్థితులు ఏర్పడును. ధనాదాయం సామాన్యం. సొంత గృహ నిర్మాణ ప్రయత్నాలు ప్రారంభించడానికి ఈ మాసం అనుకూలమైన కాలం. నూతన ప్రయోగాలు చేయుటకు ఇది మంచి కాలం. ఉన్నత పదవులు పొందటానికి ఇది యోగావంతమైన కాలం. నూతన పారితోషకాలు అందుకోను సూచనలున్నాయి. చివరి వారంలో ఆరోగ్య సమస్య లేదా శస్త్ర చికిత్స జరుగు అవకాశం కలదు.",
          Path: Makara,
          Rajapujyam: "4",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మకర రాశి",
          Avamanam: "9",
        },
        KumbhaRasi: {
          Aadayama: "8",
          Vyayam: "14",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభ రాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభ రాశి.",
          Para: "ఈ నెలలో ధనాదాయం సామాన్యం. గృహంలో సంతోషకర సంఘటనలు. బంధువులు మిత్రుల వలన సహకారములు లభించును. ఆశించిన కార్యములందు విజయం. శుభకార్య సంబంధ శ్రమ అధికం అగును. 20వ తేదీ తదుపరి జీవిత భాగస్వామి సహాయ సహకారములు సంపూర్ణంగా లభిస్తాయి. సంతాన ప్రయత్నాలలో విజయం. నూతన ఉద్యోగ ప్రయత్నాలలో విజయం. ఈ నెలలో 3,8,9,16 తేదీలు నూతన ప్రయత్నాలకు అనుకూలమైనవి. 24వ తేదీ తదుపరి కష్టంతో కార్యములు పూర్తి అగును. కొద్దిపాటి ఒత్తిడి ఎదుర్కొంటారు. అంతరాత్మ ప్రభోదించిన మాటను వినడం మంచిది.",
          Path: Kumbha,
          Rajapujyam: "7",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Avamanam: "5",
        },
        MeenaRasi: {
          Aadayama: "5",
          Vyayam: "5",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "1",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీన రాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీన రాశి.",
          Para: "ఈ మాసంలో కూడా ప్రతికూల పరిస్థితులు కొనసాగును. ధనాదాయం ఆశించినంతగా ఉండదు. భూ సంబంధ క్రయవిక్రయాల వలన నష్టం ఏర్పడును. వ్యాపార వ్రుత్తిజీవనంలోని వారికి ఆదాయంలో తగ్గుదల ఎదురగును. ఉద్యోగ జీవనంలోని వారికి నూతన భాద్యతల వలన విశ్రాంతి వుండదు. పూర్తిగా శ్రమించినా కూడా విమర్శలు ఎదుర్కొందురు.",
          Path: Meena,
          Rajapujyam: "3",
          RasiDates: "ది.30-మార్చి-2025 నుండి ది.19-మార్చి-2026 వరకు",
          Samvatsaram: "శ్రీ విశ్వావసు నామ సంవత్సరం",
          Title: "మీన రాశి",
          Avamanam: "1",
        },
        
        },
    ],

  ]

}








