import React, { useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import geetasaaramsam from '../../assets/jsons/Telugu/gitaSaramsam'
import Flower from '../../assets/images/image.webp';

const Geethasaramsam = () => {
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="card-body rightPanel">
              <h5>గీతా సారాంశం</h5>
              {geetasaaramsam.map((item, index) => (
                <div key={index}>
                  <ul style={{ color: index === 1 ? 'yellow' : 'inherit' }}>
                    {index !== 1 && (
                      <img
                        src={Flower}
                        alt="Flower"
                        style={{ float: 'left', width: '20px', marginRight: '10px' }}
                      />
                    )}
                    {item.title}
                  </ul>
                  <br></br>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Geethasaramsam
