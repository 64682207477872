import React, { useState, useEffect } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import sideGridRightImage from '../../assets/images/sideGridRight.webp';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Samskrutham = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  // Set the default active category to the first item in the filtered categories
  useEffect(() => {
    const firstCategory = Object.values(filteredCategories)[0];
    if (firstCategory) {
      setActiveCategory(firstCategory);
    }
  }, []);

  const handleToggle = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const renderCatIDList = (category) => {
    if (category && Array.isArray(category.CatID)) {
      return category.CatID.map((item, index) => (
        <div key={index}>{item.title}</div>
      ));
    }
    return null;
  };

  const filteredCategories = [
    'sanskritDateNames',
    'nividenela',
    'prasademulu',
    'pushpalu',
    'Pushpamuluvaatiphalithalu'
  ].reduce((acc, key) => {
    if (ItaramuluData[key]) {
      acc[key] = ItaramuluData[key];
    }
    return acc;
  }, {});

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
         <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            // style={{
            //   overflowY: leftSticky ? 'hidden' : 'auto',
            //   height: '80vh',
            //   position: leftSticky ? 'sticky' : 'static',
            //   top: 0,
            // }}
            >
            <div
            style={{
              overflowY: rightSticky ? 'scroll' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'sticky',
              top: 0,
          }}
             className="row">
              <div id="accordion">
                {Object.keys(filteredCategories).map((key, index) => (
                  <div
                    className="card mb-3"
                    style={{ 
                      backgroundColor: activeCategory === ItaramuluData[key] ? '#B35C0C' : '#754213',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleToggle(ItaramuluData[key])}
                    key={index}
                  >
                    <div className="card-header">
                      <h5 className="mb-0">
                        <button
                          className="btn btn"
                          aria-expanded={activeCategory === ItaramuluData[key]}
                          aria-controls={`collapse${index}`}
                          style={{ color: '#FFFFFF', border:"none" }}
                        >
                          {ItaramuluData[key].title}
                        </button>
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            {Object.keys(filteredCategories).map((key, index) => (
              <div
                key={index}
                id={`collapse${index}`}
                className={`collapse ${activeCategory === ItaramuluData[key] ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-parent="#accordion"
              >
                <div className="card-body rightPanel">
                  <h5>{ItaramuluData[key].title}</h5>
                  <ul>{renderCatIDList(ItaramuluData[key])}</ul>
                  <br />
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Samskrutham;
