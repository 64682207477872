import React, { useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import ItaramuluData from '../../assets/jsons/ItaramuluData'
import img1 from '../../assets/images/Telugu_Years.webp'
import img2 from '../../assets/images/Rasi.webp'
import img3 from '../../assets/images/Telugu_Months.webp'
import img4 from '../../assets/images/Telugu_Numbers.webp'
import img5 from '../../assets/images/Telugu_Letters.webp'
import img6 from '../../assets/images/Gunnithalu.webp'
import img7 from '../../assets/images/Janapada_kalalu.webp'
import img8 from '../../assets/images/Kulavrutulu.webp'
import img9 from '../../assets/images/Navagrahalu.webp'
import img10 from '../../assets/images/Rutuvulu.webp'
import img11 from '../../assets/images/Kolathalu.webp'
import img12 from '../../assets/images/PakshamuluImg.webp'
import img13 from '../../assets/images/Lagnam.webp'
import img14 from '../../assets/images/paksham_lo_Thidulu.webp'

const Telugusamskrutham = () => {
  // Filtered categories
  const categoryImages = {
    Teluguyears: img1,
    Telugurasulu: img2,
    Telugumonths: img3,
    Telugunumbers: img4,
    Telugulettters: img5,
    teluguguninthamulu: img6,
    JanapadaKalalu: img7,
    Kulavruthulu: img8,
    telugunavagrahalu: img9,
    Telugurutuvulu: img10,
    kolethelu: img11,
    Telugunpakshamulu: img12,
    telugulagnam: img13,
    Telugupakshmaulonithidulu: img14,
  };
  const filteredCategories = [
    'Teluguyears',
    'Telugurasulu',
    'Telugumonths',
    'Telugunumbers',
    'Teluguletters',
    'teluguguninthamulu',
    'JanapadaKalalu',
    'Kulavruthulu',
    'telugunavagrahalu',
    'Telugurutuvulu',
    'kolethelu',
    'Telugunpakshamulu',
    'telugulagnam',
    'Telugupakshmaulonithidulu'
  ].reduce((acc, key) => {
    if (ItaramuluData[key]) {
      acc[key] = ItaramuluData[key];
    }
    return acc;
  }, {});

  // Initialize with the first category from filteredCategories
  const defaultCategoryKey = Object.keys(filteredCategories)[0];
  const [activeCategory, setActiveCategory] = useState(filteredCategories[defaultCategoryKey]);


  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);


  const handleToggle = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const getCategoryTitle = (category, index) => {
    switch (index) {
      case 0:
        return "తెలుగు సంవత్సరాలు"; // Telugu text for index 0
      case 3:
        return "తెలుగు అంకెలు"; // Telugu text for index 3
      case 9:
        return "ఋతువులు"; // Telugu text for index 9
      default:
        return category.title;
    }
  };

  const renderCatIDList = (category, index) => {
    const title = getCategoryTitle(category, index);
    console.log('Rendering category:', title);

    if (category && Array.isArray(category.CatID)) {
      return category.CatID.map((item, index) => (
        <div key={index}>
          <div>{item.title}</div>
          <div>{item.measure}</div>
        </div>
      ));
    } else if (Array.isArray(category)) {
      return category.map((item, index) => (
        <div key={index}>
          <div>{item.title}</div>
          {item.CatID && item.CatID.map((catItem, catIndex) => (
            <div key={catIndex} style={{ marginLeft: '20px' }}>
              <div>{catItem.title}</div>
              <div>{catItem.measure}</div>
            </div>
          ))}
        </div>
      ));
    }
    return null;
  };

  console.log('Filtered Categories:', filteredCategories);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
          // style={{
          //   overflowY: leftSticky ? 'hidden' : 'auto',
          //   height: '80vh',
          //   position: leftSticky ? 'sticky' : 'static',
          //   top: 0,
          // }}
        >

            <div 
            style={{
              overflowY: rightSticky ? 'scroll' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'sticky',
              top: 0,
          }}
            className="row">
           <div id="accordion">
  {Object.keys(filteredCategories).map((key, index) => {
    const title = getCategoryTitle(filteredCategories[key], index);
    const image = categoryImages[key]; // Fetch the image for the current category

    return (
      <div
        key={index}
        id={`heading${index}`}
        onClick={() => handleToggle(filteredCategories[key])}
        className="card-header card mb-3"
        style={{
          color: "#FFFFFF",
          backgroundColor: activeCategory === filteredCategories[key] ? "#B35C0C" : "#754213",
          display: "flex", // Add flex display for alignment
          alignItems: "center", // Center-align content
        }}
      >
        {/* Display the image */}
        <img
          src={image}
          alt={key}
          style={{
            width: "50px",
            height: "50px",
            marginRight: "10px", // Add spacing between image and text
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
        <h5 className="mb-0">
          <button
            className="btn btn"
            aria-expanded={activeCategory === filteredCategories[key]}
            aria-controls={`collapse${index}`}
            style={{ color: "#FFFFFF" }}
          >
            {title}
          </button>
        </h5>
      </div>
    );
  })}
</div>

            </div>
          </div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{
            overflowY: rightSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: rightSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
          
            {Object.keys(filteredCategories).map((key, index) => (
              <div
                key={index}
                id={`collapse${index}`}
                className={`collapse ${activeCategory === filteredCategories[key] ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-parent="#accordion"
              >
                <div className="card-body rightPanel">
                  <h5>{getCategoryTitle(filteredCategories[key], index)}</h5>
                  <ul>{renderCatIDList(filteredCategories[key], index)}</ul>
                  <br />
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Telugusamskrutham;
