import React, { useState, useEffect } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import otherMoreData from '../../assets/jsons/otherMoreData';

const Nithipadhyalu = () => {
  const [activeKey, setActiveKey] = useState(0); // Default first item active
  const [padyamTitles, setPadyamTitles] = useState([]);

  useEffect(() => {
    const extractPadyamTitles = (data) => {
      return data.neetipadyalu.CatID.flatMap(category =>
        category.Padyalu.flatMap(padyalu =>
          padyalu.padyam.map(padyam => padyam.title)
        )
      );
    };

    if (otherMoreData.neetipadyalu) {
      const titles = extractPadyamTitles(otherMoreData);
      setPadyamTitles(titles);
    }
  }, []);

  const handleToggle = (index) => {
    setActiveKey(activeKey === index ? null : index);
  };

  const categories = otherMoreData?.neetipadyalu?.CatID || [];
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            // style={{
            //   overflowY: leftSticky ? 'hidden' : 'auto',
            //   height: '80vh',
            //   position: leftSticky ? 'sticky' : 'static',
            //   top: 0,
            // }}
            >
            <div 
            style={{
              overflowY: rightSticky ? 'scroll' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'sticky',
              top: 0,
          }}
            className="row">
              <div id="accordion">
                {categories.map((category, index) => (
                  <div
                    key={index}
                    id={`heading${index}`}
                    onClick={() => handleToggle(index)}
                    className="card-header card mb-3"
                    style={{ 
                      backgroundColor: activeKey === index ? '#B35C0C' : '#754213', 
                      color: '#FFFFFF',
                      cursor: 'pointer'
                    }}
                  >
                    <h5 className="mb-0">
                      <button
                        className="btn btn"
                        aria-expanded={activeKey === index}
                        aria-controls={`collapse${index}`}
                        style={{ color: '#FFFFFF' }}
                      >
                        {category.title}
                      </button>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            {activeKey !== null && (
              <div
                id={`collapse${activeKey}`}
                className="collapse show"
                aria-labelledby={`heading${activeKey}`}
              >
                <div className="card-body rightPanel">
                  <h5>{categories[activeKey].title}</h5>
                 
                  {categories[activeKey].Padyalu.map((padyalu, index) => (
                    <div key={index}>
                      {padyalu.padyam.map((padyam, idx) => (
                        <ul key={idx}>
                          {padyam.title}
                        </ul>
                      ))}
                    
                      <hr />
                    </div>
                  ))}

                </div>
              </div>
            )}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Nithipadhyalu;
