export const LocalData = {

  FestivalUpdated: [
    [
      {
        day: [
          {
            Date: "సోమవారము, 01 జనవరి 2024",
            Festival: "ఆంగ్ల సంవత్సరాది",
          },
          {
            Date: "మంగళవారము, 02 జనవరి 2024",
            Festival: "శూన్య తిథి",
          },
          {
            Date: "బుధవారము, 10 జనవరి 2024",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "శుక్రవారము, 12 జనవరి 2024",
            Festival: "నేషనల్ యూత్ డే, స్వామి వివేకానంద జయంతి",
          },
          {
            Date: "శనివారము, 13 జనవరి 2024",
            Festival: "తిథిద్వయం(విదియ+తదియ)",
          },
          {
            Date: "ఆదివారము, 14 జనవరి 2024",
            Festival: "భోగి",
          },
          {
            Date: "సోమవారము, 15 జనవరి 2024",
            Festival: "మకర సంక్రాంతి, సంక్రాంతి పండుగ, ఉత్తరాయణం ప్రారంభం",
          },
          {
            Date: "మంగళవారము, 16 జనవరి 2024",
            Festival: "కనుమ పండుగ",
          },
          {
            Date: "బుధవారము, 17 జనవరి 2024",
            Festival: "ముక్కనుమ, సావిత్రి గౌరీవ్రతం, బొమ్మలనోము ప్రారంభం",
          },
          {
            Date: "మంగళవారము, 23 జనవరి 2024",
            Festival: "నేతాజీ జయంతి",
          },
          {
            Date: "శుక్రవారము, 26 జనవరి 2024",
            Festival: "రిపబ్లిక్ డే",
          },
          {
            Date: "సోమవారము, 29 జనవరి 2024",
            Festival: "సంకష్టహర చతుర్థి",
          },
          {
            Date: "మంగళవారము, 30 జనవరి 2024",
            Festival: "మహాత్మాగాంధీ వర్ధంతి",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 4 ఫిబ్రవరి 2024",
            Festival: "ప్రపంచ క్యాన్సర్ రోజు",
          },
          {
            Date: "గురువారము, 8 ఫిబ్రవరి 2024",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "శుక్రవారము, 9 ఫిబ్రవరి 2024",
            Festival: "చొల్లంగి అమావాస్య, శూన్య తిథి",
          },
          {
            Date: "మంగళవారము, 13 ఫిబ్రవరి 2024",
            Festival: "కుంభ సంక్రమణం",
          },
          {
            Date: "బుధవారము, 14 ఫిబ్రవరి 2024",
            Festival: "శ్రీ పంచమి, మదన పంచమి, ప్రేమికుల రోజు",
          },
          {
            Date: "శుక్రవారము, 16 ఫిబ్రవరి 2024",
            Festival: "రధసప్తమి, సూర్య జయంతి, తిథిద్వయం(సప్తమి+అష్టమి)",
          },
          {
            Date: "శనివారము, 17 ఫిబ్రవరి 2024",
            Festival: "భీష్మాష్టమి, నవమి తిథి",
          },
          {
            Date: "ఆదివారము, 18 ఫిబ్రవరి 2024",
            Festival: "దశమి తిథి",
          },
          {
            Date: "శనివారము, 24 ఫిబ్రవరి 2024",
            Festival: "మాఘ పౌర్ణమి",
          },
          {
            Date: "బుధవారము, 28 ఫిబ్రవరి 2024",
            Festival: "సంకష్టహర చతుర్థి",
          },
        ],
      },
      {
        day: [
          {
            Date: "శుక్రవారము, 08 మార్చి 2024",
            Festival: "మహా శివరాత్రి, కోటిపల్లి తీర్ధం",
          },
          {
            Date: "సోమవారము, 11 మార్చి 2024",
            Festival: "తిథిద్వయం(పాడ్యమి+విదియ)",
          },
          {
            Date: "బుధవారము, 13 మార్చి 2024",
            Festival: "పుత్ర గణపతి వ్రతం",
          },
          {
            Date: "గురువారము, 14 మార్చి 2024",
            Festival: "మీన సంక్రమణం, శ్రీకంఠ జయంతి",
          },
          {
            Date: "శనివారము, 23 మార్చి 2024",
            Festival: "శని త్రయోదశి",
          },
          {
            Date: "సోమవారము, 25 మార్చి 2024",
            Festival: "హోళీ పండుగ, హోళికా పూర్ణిమ",
          },
          {
            Date: "మంగళవారము, 26 మార్చి 2024",
            Festival: "శూన్య తిథి",
          },
          {
            Date: "గురువారము, 28 మార్చి 2024",
            Festival: "సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 29 మార్చి 2024",
            Festival: "గుడ్ ఫ్రైడే",
          },
          {
            Date: "ఆదివారము, 31 మార్చి 2024",
            Festival: "ఈస్టర్",
          },
        ],
      },
      {
        day: [
          {
            Date: "సోమవారము, 01 ఏప్రిల్ 2024",
            Festival: "ఏప్రిల్ ఫూల్, బ్యాంకు సెలవు",
          },
          {
            Date: "బుధవారము, 03 ఏప్రిల్ 2024",
            Festival: "తిథిద్వయం(నవమి+దశమి)",
          },
          {
            Date: "శుక్రవారము, 05 ఏప్రిల్ 2024",
            Festival: "బాబూ జగజ్జీవన్ రామ్ జయంతి",
          },
          {
            Date: "ఆదివారము, 07 ఏప్రిల్ 2024",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "మంగళవారము, 09 ఏప్రిల్ 2024",
            Festival: "శ్రీ క్రోధి నామ సంవత్సరం, ఉగాది",
          },
          {
            Date: "బుధవారము, 10 ఏప్రిల్ 2024",
            Festival: "రంజాన్, ఈద్ అల్ ఫితర్",
          },
          {
            Date: "ఆదివారము, 14 ఏప్రిల్ 2024",
            Festival: "అంబేద్కర్ జయంతి",
          },
          {
            Date: "బుధవారము, 17 ఏప్రిల్ 2024",
            Festival: "శ్రీరామనవమి, సీతారామ కళ్యాణం",
          },
          {
            Date: "మంగళవారము, 23 ఏప్రిల్ 2024",
            Festival: "హనుమాన్ జయంతి",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 01 మే 2024",
            Festival: "అంతర్జాతీయ కార్మిక దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 07 మే 2024",
            Festival: "రవీంద్రనాథ్ ఠాగూర్ జయంతి",
          },
          {
            Date: "శుక్రవారము, 10 మే 2024",
            Festival: "అక్షయ తదియ, సింహాచలం వరాహ లక్ష్మీనారసింహ స్వామి చందనోత్సవం, పరశురామ జయంతి",
          },
          {
            Date: "ఆదివారము, 12 మే 2024",
            Festival: "విశ్వజ్ఞాని ఆది శంకరాచార్యుల జయంతి",
          },
          {
            Date: "గురువారము, 23 మే 2024",
            Festival: "బుద్ధ పూర్ణిమ",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 05 జూన్ 2024",
            Festival: "ప్రపంచ పర్యావరణ దినోత్సవము",
          },
          {
            Date: "ఆదివారము, 16 జూన్ 2024",
            Festival: "ఫాదర్స్ డే",
          },
          {
            Date: "సోమవారము, 17 జూన్ 2024",
            Festival: "బక్రీదు",
          }
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 07 జూలై 2024",
            Festival: "జగన్నాథ్ రథయాత్ర",
          },
          {
            Date: "బుధవారము, 17 జూలై 2024",
            Festival: "తొలి ఏకాదశీ, మొహర్రం",
          },
          {
            Date: "ఆదివారము, 21 జూలై 2024",
            Festival: "గురు పూర్ణిమ",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 04 ఆగష్టు 2024",
            Festival: "స్నేహితుల దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 06 ఆగష్టు 2024",
            Festival: "మంగళ గౌరీ వ్రతం",
          },
          {
            Date: "ఆదివారము, 11 ఆగస్టు 2024",
            Festival: "తులసీదాస్ జయంతి",
          },
          {
            Date: "గురువారము, 15 ఆగస్టు 2024",
            Festival: "స్వాతంత్య్రదినోత్సవం",
          },
          {
            Date: "శుక్రవారము, 16 ఆగస్టు 2024",
            Festival: "వరలక్ష్మీ వ్రతం",
          },
          {
            Date: "సోమవారము, 19 ఆగస్టు 2024",
            Festival: "రాఖీ, రక్షా బంధన్",
          },
          {
            Date: "సోమవారము, 26 ఆగస్టు 2024",
            Festival: "శ్రీ కృష్ణ జన్మాష్టమి",
          },
        ],
      },
      {
        day: [
          {
            Date: "గురువారము, 05 సెప్టెంబర్ 2024",
            Festival: "ఉపాధ్యాయుల దినోత్సవం",
          },
          {
            Date: "శనివారము, 07 సెప్టెంబర్ 2024",
            Festival: "సిద్ధి వినాయక చవితి",
          },
          {
            Date: "ఆదివారము, 15 సెప్టెంబర్ 2024",
            Festival: "ఇంజనీర్ల దినోత్సవము",
          },
          {
            Date: "సోమవారము, 16 సెప్టెంబర్ 2024",
            Festival: "మిలాద్ ఉన్ నబీ",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 02 అక్టోబర్ 2024",
            Festival: "మహ్మత్మాగాంధీ జయంతి",
          },
          {
            Date: "శుక్రవారము, 11 అక్టోబర్ 2024",
            Festival: "దుర్గ అష్టమి",
          },
          {
            Date: "శనివారము, 12 అక్టోబర్ 2024",
            Festival: "మహా నవమి, విజయ దశమి",
          },
        ],
      },
      {
        day: [
          {
            Date: "శుక్రవారము, 01 నవంబర్ 2024",
            Festival: "దీపావళి, లక్ష్మి పూజ",
          },
          {
            Date: "గురువారము, 14 నవంబర్ 2024",
            Festival: "బాలల దినోత్సవం, నెహ్రూ జయంతి",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 01 డిసెంబర్ 2024",
            Festival: "ప్రపంచ ఎయిడ్స్ దినోత్సవం",
          },
          {
            Date: "శనివారము, 07 డిసెంబర్ 2024",
            Festival: "సుబ్రహ్మణ్య షష్ఠి",
          },
          {
            Date: "బుధవారము, 25 డిసెంబర్ 2024",
            Festival: "క్రిస్మస్",
          },
          {
            Date: "గురువారము, 26 డిసెంబర్ 2024",
            Festival: "బాక్సింగ్ డే",
          },
        ],
      },
    ],
    [
        {
          day: [
            {
              Date: "బుధవారము, 01 జనవరి 2025",
              Festival: "ఆంగ్ల సంవత్సరాది, చంద్రోదయం",
            },
            {
              Date: "గురువారము, 02 జనవరి 2025",
              Festival: "ప్రపంచ ప్రకృతి దినోత్సవం",
            },
            {
              Date: "శుక్రవారము, 03 జనవరి 2025",
              Festival: "చతుర్థి వ్రతం",
            },
            {
              Date: "ఆదివారము, 05 జనవరి 2025",
              Festival: "స్కంద షష్ఠి",
            },
            {
              Date: "సోమవారము, 06 జనవరి 2025",
              Festival: "ఎపిఫని",
            },
            {
              Date: "మంగళవారము, 07 జనవరి 2025",
              Festival: "దుర్గా అష్టమి వ్రతం",
            },
            {
              Date: "శుక్రవారము, 10 జనవరి 2025",
              Festival: "పుష్యము పుత్రాద ఏకాదశీ, కుర్మా ద్వాదశి, ఉత్తరాషాడ కార్తె, ముక్కోటి ఏకాదశి",
            },
            {
              Date: "శనివారము, 11 జనవరి 2025",
              Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం",
            },
            {
              Date: "ఆదివారము, 12 జనవరి 2025",
              Festival: "నేషనల్ యూత్ డే, స్వామి వివేకానంద జయంతి",
            },
            {
              Date: "సోమవారము, 13 జనవరి 2025",
              Festival: "భోగి, పౌర్ణమి, శ్రీ సత్య నారాయణ పూజ",
            },
            {
              Date: "మంగళవారము, 14 జనవరి 2025",
              Festival: "మకర సంక్రాంతి, పొంగల్, ఉత్తరాయణ పుణ్య కాలం ప్రారంభం, హజారత్ అలీ జయంతి",
            },
            {
              Date: "బుధవారము, 15 జనవరి 2025",
              Festival: "కనుమ",
            },
            {
              Date: "గురువారము, 16 జనవరి 2025",
              Festival: "ముక్కనుము, బొమ్మల నోము",
            },
            {
              Date: "శుక్రవారము, 17 జనవరి 2025",
              Festival: "లంబోదర సంకష్టహర చతుర్థి",
            },
            {
              Date: "శనివారము, 18 జనవరి 2025",
              Festival: "త్యాగరాజ స్వామి ఆరాధన",
            },
            {
              Date: "మంగళవారము, 21 జనవరి 2025",
              Festival: "భాను సప్తమి",
            },
            {
              Date: "గురువారము, 23 జనవరి 2025",
              Festival: "శ్రావణ కార్తె, నేతాజీ జయంతి",
            },
            {
              Date: "శనివారము, 25 జనవరి 2025",
              Festival: "షట్తిలా ఏకాదశీ",
            },
            {
              Date: "ఆదివారము, 26 జనవరి 2025",
              Festival: "రిపబ్లిక్ డే",
            },
            {
              Date: "సోమవారము, 27 జనవరి 2025",
              Festival: "సోమా ప్రదోష్ వ్రతం, మాస శివరాత్రి",
            },
            {
              Date: "మంగళవారము, 28 జనవరి 2025",
              Festival: "లాలా లజపత్ రాయ్ జయంతి",
            },
            {
              Date: "బుధవారము, 29 జనవరి 2025",
              Festival: "అమావాస్య,  చొల్లంగి అమావాస్య",
            },
            {
              Date: "గురువారము, 30 జనవరి 2025",
              Festival: "చంద్రోదయం, మాఘ గుప్త నవరాత్రి, మహాత్మా గాంధీ వర్దంతి",
            },
            {
              Date: "శుక్రవారము, 31 జనవరి 2025",
              Festival: "అవతార్ మెహర్ బాబా అమర తిథి",
            },
          ],
        },
        {
          day: [
            {
              Date: "శనివారము, 1 ఫిబ్రవరి 2025",
              Festival: "గణేష్ చతుర్థి, చతుర్థి వ్రతం, శ్రీ మార్కండేయ మహర్షి జయంతి",
            },
            {
              Date: "సోమవారము, 3 ఫిబ్రవరి 2025",
              Festival: "సరస్వతీ పూజ, సోమవార వ్రతం, స్కంద షష్ఠి",
            },
            {
              Date: "మంగళవారము, 4 ఫిబ్రవరి 2025",
              Festival: "రధసప్తమి, ప్రపంచ క్యాన్సర్ రోజు",
            },
            {
              Date: "బుధవారము, 5 ఫిబ్రవరి 2025",
              Festival: "దుర్గాష్టమి వ్రతం, బుద్ధ అష్టమి, బీష్మాష్టమి",
            },
            {
              Date: "గురువారము, 6 ఫిబ్రవరి 2025",
              Festival: "ధనిష్ఠ కార్తె, మధ్వ నవమి",
            },
            {
              Date: "శనివారము, 8 ఫిబ్రవరి 2025",
              Festival: "జయ ఏకాదశీ",
            },
            {
              Date: "ఆదివారము, 9 ఫిబ్రవరి 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "బుధవారము, 12 ఫిబ్రవరి 2025",
              Festival: "పౌర్ణమి, మాఘ పౌర్ణమి, పౌర్ణమి వ్రతం, కుంభ సంక్రమణం, సింధూస్నానం, శ్రీ సత్యనారాయణ స్వామి వ్రత",
            },
            {
              Date: "శుక్రవారము, 14 ఫిబ్రవరి 2025",
              Festival: "షబ్-ఎ-బరాత్, ప్రేమికుల రోజు",
            },
            {
              Date: "ఆదివారము, 16 ఫిబ్రవరి 2025",
              Festival: "ద్విజప్రియ సంకష్టహర చతుర్థి",
            },
            {
              Date: "బుధవారము, 19 ఫిబ్రవరి 2025",
              Festival: "శతభిష కార్తె",
            },
            {
              Date: "ఆదివారము, 23 ఫిబ్రవరి 2025",
              Festival: "స్వామి దయానంద సరస్వతి జయంతి",
            },
            {
              Date: "సోమవారము, 24 ఫిబ్రవరి 2025",
              Festival: "విజయ ఏకాదశీ",
            },
            {
              Date: "మంగళవారము, 25 ఫిబ్రవరి 2025",
              Festival: "ప్రదోష్ వ్రతం, మెహర్ బాబా జయంతి",
            },
            {
              Date: "బుధవారము, 26 ఫిబ్రవరి 2025",
              Festival: "మహా శివరాత్రి, మాస శివరాత్రి",
            },
            {
              Date: "గురువారము, 27 ఫిబ్రవరి 2025",
              Festival: "అమావాస్య",
            },
            {
              Date: "శుక్రవారము, 28 ఫిబ్రవరి 2025",
              Festival: "జాతీయ సైన్స్ దినోత్సవం",
            },
          ],
        },
        {
          day: [
            {
              Date: "శనివారము, 1 మార్చి 2025",
              Festival: "యాదాద్రి శ్రీ లక్ష్మీ నరసింహ స్వామి వారి బ్రహ్మోత్సవాలు ప్రారంభం, చంద్రోదయం, రంజాన్ ఉపవాస దీక్షలు ప్రారంభం",
            },
            {
              Date: "సోమవారము, 3 మార్చి 2025",
              Festival: "సోమవార వ్రతం, చతుర్థి వ్రతం",
            },
            {
              Date: "మంగళవారము, 4 మార్చి 2025",
              Festival: "పూర్వాభాద్ర కార్తె",
            },
            {
              Date: "బుధవారము, 5 మార్చి 2025",
              Festival: "స్కంద షష్ఠి",
            },
            {
              Date: "శుక్రవారము, 7 మార్చి 2025",
              Festival: "దుర్గాష్టమి వ్రతం",
            },
            {
              Date: "శనివారము, 8 మార్చి 2025",
              Festival: "యాదాద్రి శ్రీ లక్ష్మీ నరసింహ స్వామి వారి బ్రహ్మోత్సవాలు తిరు కల్యాణం, అంతర్జాతీయ మహిళా దినోత్సవం",
            },
            {
              Date: "సోమవారము, 10 మార్చి 2025",
              Festival: "తిరుమల శ్రీవారి తెప్పోత్సవం ప్రారంభం, కోరుకొండ తీర్థం, ఆమలకీ ఏకాదశీ",
            },
            {
              Date: "మంగళవారము, 11 మార్చి 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "గురువారము, 13 మార్చి 2025",
              Festival: "హోలికా దహన్,  శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి వ్రతం",
            },
            {
              Date: "శుక్రవారము, 14 మార్చి 2025",
              Festival: "హోళీ, పౌర్ణమి, శ్రీ లక్ష్మీ జయంతి, మీన సంక్రమణం, తిరుమల శ్రీవారి తెప్పోత్సవం సమాప్తి",
            },
            {
              Date: "ఆదివారము, 16 మార్చి 2025",
              Festival: "పొట్టి శ్రీరాములు జయంతి",
            },
            {
              Date: "సోమవారము, 17 మార్చి 2025",
              Festival: "బాలచంద్ర సంకష్టహర చతుర్థి, ఉత్తరాబాధ్ర కార్తె",
            },
            {
              Date: "మంగళవారము, 18 మార్చి 2025",
              Festival: "అంగార్కి చతుర్థి, సంకటహర చతుర్థి",
            },
            {
              Date: "బుధవారము, 19 మార్చి 2025",
              Festival: "రంగ పంచమి",
            },
            {
              Date: "శుక్రవారము, 21 మార్చి 2025",
              Festival: "శీతల సప్తమి, షహదత్ హజ్రత్ అలీ",
            },
            {
              Date: "మంగళవారము, 25 మార్చి 2025",
              Festival: "పాపమొచనీ ఏకాదశీ",
            },
            {
              Date: "బుధవారము, 26 మార్చి 2025",
              Festival: "వైష్ణవ పాపమొచనీ ఏకాదశీ",
            },
            {
              Date: "గురువారము, 27 మార్చి 2025",
              Festival: "మాస శివరాత్రి, ప్రదోష్ వ్రతం",
            },
            {
              Date: "శుక్రవారము, 28 మార్చి 2025",
              Festival: "షబ్-ఎ-ఖదర్(లైలతుల్ ఖదర్), జుముఅతుల్-విదా",
            },
            {
              Date: "శనివారము, 29 మార్చి 2025",
              Festival: "అమావాస్య",
            },
            {
              Date: "ఆదివారము, 30 మార్చి 2025",
              Festival: "ఉగాది, శ్రీ విశ్వావసు నామ సంవత్సరం, చంద్రోదయం, వసంత నవరాత్రులు ప్రారంభం",
            },
            {
              Date: "సోమవారము, 31 మార్చి 2025",
              Festival: "గౌరీ పూజ, రంజాన్, ఈద్ అల్ ఫితర్, సోమవార వ్రతం, మత్స్య జయంతి, రేవతి కార్తె",
            },
          ],
        },
        {
          day: [
            {
              Date: "మంగళవారము, 01 ఏప్రిల్ 2025",
              Festival: "ఏప్రిల్ ఫూల్, చతుర్థి వ్రతం, వార్షిక ఖాతాల ముగింపు (బ్యాంకు సెలవు)",
            },
            {
              Date: "బుధవారము, 02 ఏప్రిల్ 2025",
              Festival: "వసంత పంచమి",
            },
            {
              Date: "గురువారము, 03 ఏప్రిల్ 2025",
              Festival: "స్కంద షష్ఠి",
            },
            {
              Date: "శనివారము, 05 ఏప్రిల్ 2025",
              Festival: "దుర్గాష్టమి వ్రతం, బాబూ జగజ్జీవన్ రామ్ జయంతి",
            },
            {
              Date: "ఆదివారము, 06 ఏప్రిల్ 2025",
              Festival: "శ్రీరామ నవమి, తాటాకు ఆదివారం",
            },
            {
              Date: "సోమవారము, 07 ఏప్రిల్ 2025",
              Festival: "ధర్మరాజ దశమి, ప్రపంచ ఆరోగ్య దినోత్సవం",
            },
            {
              Date: "మంగళవారము, 08 ఏప్రిల్ 2025",
              Festival: "వైష్ణవ కామద ఏకాదశీ",
            },
            {
              Date: "గురువారము, 10 ఏప్రిల్ 2025",
              Festival: "ప్రదోష్ వ్రతం, అనంగ్ త్రయోదశి, మహావీర్ జయంతి, పస్కా పండుగ",
            },
            {
              Date: "శుక్రవారము, 11 ఏప్రిల్ 2025",
              Festival: "జ్యోతిరావు ఫూలే జయంతి",
            },
            {
              Date: "శనివారము, 12 ఏప్రిల్ 2025",
              Festival: "చైత్రము పూర్ణిమ, హనుమాన్ జయంతి, శ్రీ సత్యనారాయణ పూజ, పౌర్ణమి వ్రతం",
            },
            {
              Date: "ఆదివారము, 13 ఏప్రిల్ 2025",
              Festival: "అశ్వని కార్తె",
            },
            {
              Date: "సోమవారము, 14 ఏప్రిల్ 2025",
              Festival: "మేష సంక్రమణం, అంబేద్కర్ జయంతి",
            },
            {
              Date: "బుధవారము, 16 ఏప్రిల్ 2025",
              Festival: "వికట్ సంకష్టహర చతుర్థి",
            },
            {
              Date: "శుక్రవారము, 18 ఏప్రిల్ 2025",
              Festival: "గుడ్ ఫ్రైడే",
            },
            {
              Date: "ఆదివారము, 20 ఏప్రిల్ 2025",
              Festival: "ఈస్టర్",
            },
            {
              Date: "మంగళవారము, 22 ఏప్రిల్ 2025",
              Festival: "ఎర్త్ డే",
            },
            {
              Date: "గురువారము, 24 ఏప్రిల్ 2025",
              Festival: "వరుతిని ఏకాదశీ",
            },
            {
              Date: "శుక్రవారము, 25 ఏప్రిల్ 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "శనివారము, 26 ఏప్రిల్ 2025",
              Festival: "మాస శివరాత్రి",
            },
            {
              Date: "ఆదివారము, 27 ఏప్రిల్ 2025",
              Festival: "భరణి కార్తె, అమావాస్య",
            },
            {
              Date: "సోమవారము, 28 ఏప్రిల్ 2025",
              Festival: "సోమవార వ్రతం, చంద్రోదయం",
            },
            {
              Date: "మంగళవారము, 29 ఏప్రిల్ 2025",
              Festival: "పరశురామ జయంతి",
            },
            {
              Date: "బుధవారము, 30 ఏప్రిల్ 2025",
              Festival: "సింహాచల చందనోత్సవం, అక్షయ తృతీయ, శ్రీ శ్రీ జయంతి, బసవ జయంతి",
            }
          ],
        },
        {
          day: [
            {
              Date: "గురువారము, 01 మే 2025",
              Festival: "చతుర్థి వ్రతం, అంతర్జాతీయ కార్మిక దినోత్సవం",
            },
            {
              Date: "శుక్రవారము, 02 మే 2025",
              Festival: "విశ్వజ్ఞాని ఆది శంకరాచార్యుల జయంతి, స్కంద షష్ఠి, శ్రీరామానుజ జయంతి",
            },
            {
              Date: "సోమవారము, 05 మే 2025",
              Festival: "దుర్గా అష్టమి వ్రతం",
            },
            {
              Date: "బుధవారము, 07 మే 2025",
              Festival: "శ్రీ పోతులూరి వీరబ్రహ్మేంద్ర స్వామి ఆరాధన, వాసవీ కన్యకా పరమేశ్వరి జయంతి, రవీంద్రనాథ్ ఠాగూర్ జయంతి",
            },
            {
              Date: "గురువారము, 08 మే 2025",
              Festival: "మోహినీ ఏకాదశీ, పరశురామ ద్వాదశి, శ్రీ అన్నవరం సత్యదేవుని కళ్యాణం",
            },
            {
              Date: "శుక్రవారము, 09 మే 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "ఆదివారము, 11 మే 2025",
              Festival: "కృత్తికా కార్తె, నృసింహ జయంతి, అంతర్జాతీయ మాతృ దినోత్సవం",
            },
            {
              Date: "సోమవారము, 12 మే 2025",
              Festival: "బుద్ధ పూర్ణిమ, శ్రీ సత్యనారాయణ స్వామి పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి, శ్రీ కూర్మ జయంతి, వైశాఖ పూర్ణిమ, చైత్ర పూర్ణిమ, అన్నమయ్య జయంతి ",
            },
            {
              Date: "గురువారము, 15 మే 2025",
              Festival: "వృషభ సంక్రాంతి",
            },
            {
              Date: "శుక్రవారము, 16 మే 2025",
              Festival: "ఏకదంత సంకష్టహర చతుర్థి",
            },
            {
              Date: "శుక్రవారము, 23 మే 2025",
              Festival: "అపర ఏకాదశీ",
            },
            {
              Date: "శనివారము, 24 మే 2025",
              Festival: "ప్రదోష్ వ్రతం, శని త్రయోదశి",
            },
            {
              Date: "ఆదివారము, 25 మే 2025",
              Festival: "రోహిణి కార్తె, మాస శివరాత్రి",
            },
            {
              Date: "మంగళవారము, 27 మే 2025",
              Festival: "అమావాస్య",
            },
            {
              Date: "బుధవారము, 28 మే 2025",
              Festival: "చంద్రోదయం",
            },
            {
              Date: "శుక్రవారము, 30 మే 2025",
              Festival: "చతుర్థి వ్రతం",
            },
            {
              Date: "శనివారము, 31 మే 2025",
              Festival: "శీతల షష్ఠి",
            },
          ],
        },
        {
          day: [
            {
              Date: "ఆదివారము, 01 జూన్ 2025",
              Festival: "స్కంద షష్ఠి",
            },
            {
              Date: "సోమవారము, 02 జూన్ 2025",
              Festival: "సోమవార వ్రతం, తెలంగాణ అవతరణ దినోత్సవం",
            },
            {
              Date: "మంగళవారము, 03 జూన్ 2025",
              Festival: "దుర్గా అష్టమి వ్రతం, వృషభ వ్రతం",
            },
            {
              Date: "గురువారము, 05 జూన్ 2025",
              Festival: "ప్రపంచ పర్యావరణ దినోత్సవము, దశపాపహర దశమి",
            },
            {
              Date: "శుక్రవారము, 06 జూన్ 2025",
              Festival: "గాయత్రీ జయంతి, నిర్జల ఏకాదశీ",
            },
            {
              Date: "శనివారము, 07 జూన్ 2025",
              Festival: "రామ లక్ష్మణ ద్వాదశి, బక్రీదు",
            },
            {
              Date: "ఆదివారము, 08 జూన్ 2025",
              Festival: "ప్రదోష్ వ్రతం, మృగశిర కార్తె, తిరుమల శ్రీవారి జ్యేష్టాభిషేకం ప్రారంభం",
            },
            {
              Date: "శనివారము, 10 జూన్ 2025",
              Festival: "శ్రీ సత్యనారాయణ స్వామి పూజ, పౌర్ణమి వ్రతం, వట సావిత్రి పూర్ణిమ, తిరుమల శ్రీవారి జ్యేష్టాభిషేకం సమాప్తి",
            },
            {
              Date: "బుధవారము, 11 జూన్ 2025",
              Festival: "ఏరువాక పౌర్ణమి, పౌర్ణమి",
            },
            {
              Date: "శనివారము, 14 జూన్ 2025",
              Festival: "కృష్ణపింగళా సంకష్టహర చతుర్థి",
            },
            {
              Date: "ఆదివారము, 15 జూన్ 2025",
              Festival: "ఫాదర్స్ డే, మిథున సంక్రమణం, ఈద్ అల్-గదీర్",
            },
            {
              Date: "బుధవారము, 18 జూన్ 2025",
              Festival: "బుద్ధ అష్టమి",
            },
            {
              Date: "ఆదివారము, 22 జూన్ 2025",
              Festival: "ఆరుద్ర కార్తె, యోగిని ఏకాదశీ",
            },
            {
              Date: "సోమవారము, 23 జూన్ 2025",
              Festival: "ప్రదోష్ వ్రతం, సోమ ప్రదోష వ్రతం, మాస శివరాత్రి",
            },
            {
              Date: "బుధవారము, 25 జూన్ 2025",
              Festival: "అమావాస్య",
            },
            {
              Date: "గురువారము, 26 జూన్ 2025",
              Festival: "చంద్రోదయం, ఆషాఢ గుప్త నవరాత్రి",
            },
            {
              Date: "శుక్రవారము, 27 జూన్ 2025",
              Festival: "పూరి జగన్నాథ స్వామి రథోత్సవం, ఇస్లామీయ సంవత్సరాది",
            },
            {
              Date: "శనివారము, 28 జూన్ 2025",
              Festival: "చతుర్థి వ్రతం",
            },
            {
              Date: "ఆదివారము, 29 జూన్ 2025",
              Festival: "బోనాలు ప్రారంభం",
            },
            {
              Date: "సోమవారము, 30 జూన్ 2025",
              Festival: "స్కంద పంచమి, కుమార షష్టి, సోమవార వ్రతం",
            },
          ],
        },
        {
          day: [
            {
              Date: "మంగళవారము, 01 జూలై 2025",
              Festival: "కుసుమహర జయంతి, స్కంద షష్ఠి",
            },
            {
              Date: "బుధవారము, 02 జూలై 2025",
              Festival: "బుద్ధ అష్టమి",
            },
            {
              Date: "గురువారము, 03 జూలై 2025",
              Festival: "దుర్గా అష్టమి వ్రతం, సెయింట్ థామస్ రోజు",
            },
            {
              Date: "శుక్రవారము, 04 జూలై 2025",
              Festival: "అల్లూరి సీతారామరాజు జయంతి",
            },
            {
              Date: "శనివారము, 05 జూలై 2025",
              Festival: "పునర్వసు కార్తె",
            },
            {
              Date: "ఆదివారము, 06 జూలై 2025",
              Festival: "బోనాలు, చాతుర్మాస్య గోపద్మ వ్రతారంభం, దేవశయని ఏకాదశి / తొలి ఏకాదశి, మొహర్రం",
            },
            {
              Date: "సోమవారము, 07 జూలై 2025",
              Festival: "వాసుదేవ ద్వాదశి",
            },
            {
              Date: "మంగళవారము, 08 జూలై 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "గురువారము, 10 జూలై 2025",
              Festival: "వ్యాస పూజ, గురు పూర్ణిమ, శ్రీ సత్యనారాయణ స్వామి పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి",
            },
            {
              Date: "శుక్రవారము, 11 జూలై 2025",
              Festival: "మంగళ గౌరీ వ్రతం, ప్రపంచ జనాభా దినోత్సవం",
            },
            {
              Date: "శనివారము, 12 జూలై 2025",
              Festival: "చాతుర్మాస్య ద్వితీయ అశూన్య శ‌య‌న వ్ర‌తం",
            },
            {
              Date: "ఆదివారము, 13 జూలై 2025",
              Festival: "బోనాలు",
            },
            {
              Date: "సోమవారము, 14 జూలై 2025",
              Festival: "గజానన సంకష్టహర చతుర్థి",
            },
            {
              Date: "బుధవారము, 16 జూలై 2025",
              Festival: "కర్కాటక సంక్రమణం, దక్షిణాయనం ప్రారంభం",
            },
            {
              Date: "ఆదివారము, 20 జూలై 2025",
              Festival: "పుష్యమి కార్తె, బోనాలు",
            },
            {
              Date: "సోమవారము, 21 జూలై 2025",
              Festival: "కామిక ఏకాదశీ, బోనాలు",
            },
            {
              Date: "మంగళవారము, 22 జూలై 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "బుధవారము, 23 జూలై 2025",
              Festival: "మాస శివరాత్రి",
            },
            {
              Date: "గురువారము, 24 జూలై 2025",
              Festival: "అమావాస్య",
            },
            {
              Date: "శుక్రవారము, 25 జూలై 2025",
              Festival: "చంద్రోదయం",
            },
            {
              Date: "శనివారము, 26 జూలై 2025",
              Festival: "ముహర్రం ముగుస్తుంది",
            },
            {
              Date: "సోమవారము, 28 జూలై 2025",
              Festival: "చతుర్థి వ్రతం, సోమవార వ్రతం",
            },
            {
              Date: "మంగళవారము, 29 జూలై 2025",
              Festival: "గరుడ పంచమి, నాగ పంచమి",
            },
            {
              Date: "బుధవారము, 30 జూలై 2025",
              Festival: "కల్కి జయంతి, స్కంద షష్ఠి",
            },
          ],
        },
        {
          day: [
            {
              Date: "శుక్రవారము, 01 ఆగష్టు 2025",
              Festival: "దుర్గా అష్టమి వ్రతం",
            },
            {
              Date: "ఆదివారము, 03 ఆగష్టు 2025",
              Festival: "ఆశ్లేష కార్తె, తిరుమల శ్రీవారి పవిత్రోత్సవ ప్రారంభం, స్నేహితుల దినోత్సవం",
            },
            {
              Date: "మంగళవారము, 05 ఆగష్టు 2025",
              Festival: "శ్రావణ పుత్రదా ఏకాదశి",
            },
            {
              Date: "బుధవారము, 06 ఆగష్టు 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "గురువారము, 07 ఆగష్టు 2025",
              Festival: "తిరుమల శ్రీవారి తెప్పోత్సవం సమాప్తి",
            },
            {
              Date: "శుక్రవారము, 08 ఆగష్టు 2025",
              Festival: "వరలక్ష్మి వ్రతం",
            },
            {
              Date: "శనివారము, 09 ఆగష్టు 2025",
              Festival: "రక్షా బంధన్, జంద్యాల పూర్ణిమ, వైఖాసన హయగ్రీవ జయంతి, శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి, శ్రావణ పౌర్ణమి",
            },
            {
              Date: "మంగళవారము, 12 ఆగష్టు 2025",
              Festival: "అంగారక సంకష్టి చతుర్థి, సంకష్టహర చతుర్థి",
            },
            {
              Date: "బుధవారము, 13 ఆగష్టు 2025",
              Festival: "రక్షా పంచమి",
            },
            {
              Date: "బుధవారము, 14 ఆగష్టు 2025",
              Festival: "బలరామ జయంతి",
            },
            {
              Date: "శుక్రవారము, 15 ఆగష్టు 2025",
              Festival: "భారత స్వాతంత్య్ర దినోత్సవం",
            },
            {
              Date: "శనివారము, 16 ఆగష్టు 2025",
              Festival: "శ్రీ కృష్ణ జన్మాష్టమి",
            },
            {
              Date: "ఆదివారము, 17 ఆగష్టు 2025",
              Festival: "మఖ కార్తె, సింహ సంక్రమణం",
            },
            {
              Date: "మంగళవారము, 19 ఆగష్టు 2025",
              Festival: "అజ ఏకాదశీ, ప్రపంచ ఛాయాచిత్ర దినోత్సవం",
            },
            {
              Date: "బుధవారము, 20 ఆగష్టు 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "గురువారము, 21 ఆగష్టు 2025",
              Festival: "మాస శివరాత్రి",
            },
            {
              Date: "శనివారము, 23 ఆగష్టు 2025",
              Festival: "పోలాల అమవాస్య, అమావాస్య",
            },
            {
              Date: "ఆదివారము, 24 ఆగష్టు 2025",
              Festival: "చంద్రోదయం",
            },
            {
              Date: "సోమవారము, 25 ఆగష్టు 2025",
              Festival: "సోమవార వ్రతం, వరాహ జయంతి",
            },
            {
              Date: "మంగళవారము, 26 ఆగష్టు 2025",
              Festival: "సామవేద ఉపకర్మ",
            },
            {
              Date: "బుధవారము, 27 ఆగష్టు 2025",
              Festival: "చతుర్థి వ్రతం, సిద్ధి వినాయక చవితి",
            },
            {
              Date: "గురువారము, 28 ఆగష్టు 2025",
              Festival: "ఋషి పంచమి",
            },
            {
              Date: "శుక్రవారము, 29 ఆగష్టు 2025",
              Festival: "స్కంద షష్ఠి",
            },
            {
              Date: "శనివారము, 30 ఆగష్టు 2025",
              Festival: "పుబ్బ కార్తె",
            },
            {
              Date: "ఆదివారము, 31 ఆగష్టు 2025",
              Festival: "దుర్గా అష్టమి వ్రతం, మహాలక్ష్మీ వ్రతం, రాధాష్టమి",
            },
          ],
        },
        {
          day: [
            {
              Date: "బుధవారము, 03 సెప్టెంబర్ 2025",
              Festival: "పార్శ్వ ఏకాదశి",
            },
            {
              Date: "గురువారము, 04 సెప్టెంబర్ 2025",
              Festival: "వామన జయంతి",
            },
            {
              Date: "శుక్రవారము, 05 సెప్టెంబర్ 2025",
              Festival: "ఉపాధ్యాయుల దినోత్సవం, మిలాద్ ఉన్ నబీ, ప్రదోష్ వ్రతం, ఓనం, గురు పూజ మహోత్సవం",
            },
            {
              Date: "శనివారము, 06 సెప్టెంబర్ 2025",
              Festival: "అనంత పద్మనాభ వ్రతం, వినాయక నిమజ్జనం",
            },
            {
              Date: "ఆదివారము, 07 సెప్టెంబర్ 2025",
              Festival: "శ్రీ సత్యనారాయణ పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి",
            },
            {
              Date: "సోమవారము, 08 సెప్టెంబర్ 2025",
              Festival: "మహాలయ పక్ష ప్రారంభం",
            },
            {
              Date: "బుధవారము, 10 సెప్టెంబర్ 2025",
              Festival: "సంకటహర చతుర్థి ",
            },
            {
              Date: "గురువారము, 11 సెప్టెంబర్ 2025",
              Festival: "మహా భరణి",
            },
            {
              Date: "శనివారము, 13 సెప్టెంబర్ 2025",
              Festival: "ఉత్తర కార్తె",
            },
            {
              Date: "ఆదివారము, 14 సెప్టెంబర్ 2025",
              Festival: "మహాలక్ష్మి వ్రతం సమాప్తి",
            },
            {
              Date: "బుధవారము, 17 సెప్టెంబర్ 2025",
              Festival: "ఇందిరా ఏకాదశీ, విశ్వకర్మ జయంతి, కన్య సంక్రాంతి",
            },
            {
              Date: "గురువారము, 18 సెప్టెంబర్ 2025",
              Festival: "యతి మహాలయ",
            },
            {
              Date: "శుక్రవారము, 19 సెప్టెంబర్ 2025",
              Festival: "ప్రదోష్ వ్రతం, మాస శివరాత్రి, మాఘ స్మారక",
            },
            {
              Date: "ఆదివారము, 21 సెప్టెంబర్ 2025",
              Festival: "బతుకమ్మ ప్రారంభము, అమావాస్య, మహాలయ అమావాస్య",
            },
            {
              Date: "సోమవారము, 22 సెప్టెంబర్ 2025",
              Festival: "శరన్నవరాత్రులు, దేవీనవరాత్రులు ప్రారంభము, సోమవార వ్రతం",
            },
            {
              Date: "మంగళవారము, 23 సెప్టెంబర్ 2025",
              Festival: "చంద్రోదయం",
            },
            {
              Date: "గురువారము, 25 సెప్టెంబర్ 2025",
              Festival: "చతుర్థి వ్రతం",
            },
            {
              Date: "శుక్రవారము, 26 సెప్టెంబర్ 2025",
              Festival: "లలితా పంచమి",
            },
            {
              Date: "శనివారము, 27 సెప్టెంబర్ 2025",
              Festival: "ప్రపంచ పర్యాటక దినోత్సవం, హస్త కార్తె",
            },
            {
              Date: "ఆదివారము, 28 సెప్టెంబర్ 2025",
              Festival: "దుర్గాదేవి పూజ, స్కంద షష్ఠి",
            },
            {
              Date: "సోమవారము, 29 సెప్టెంబర్ 2025",
              Festival: "సద్దుల బతుకమ్మ పండుగ, సరస్వతి పూజా ప్రారంభం",
            },
            {
              Date: "మంగళవారము, 30 సెప్టెంబర్ 2025",
              Festival: "దుర్గాష్టమి వ్రతం, సరస్వతి పూజ, దుర్గాష్టమి",
            },
          ],
        },
        {
          day: [
            {
              Date: "బుధవారము, 01 అక్టోబర్ 2025",
              Festival: "మహా నవమి, సరస్వతి పూజ",
            },
            {
              Date: "గురువారము, 02 అక్టోబర్ 2025",
              Festival: "విజయ దశమి, మహ్మత్మాగాంధీ జయంతి, లాల్ బహదూర్ శాస్త్రి జయంతి",
            },
            {
              Date: "శుక్రవారము, 03 అక్టోబర్ 2025",
              Festival: "పాశాంకుశ ఏకాదశీ",
            },
            {
              Date: "శనివారము, 04 అక్టోబర్ 2025",
              Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం, ప్రపంచ వన్యప్రాణుల దినోత్సవం, యాజ్ దాహం",
            },
            {
              Date: "సోమవారము, 06 అక్టోబర్ 2025",
              Festival: "శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి",
            },
            {
              Date: "మంగళవారము, 07 అక్టోబర్ 2025",
              Festival: "మహర్షి వాల్మీకి జయంతి, పౌర్ణమి",
            },
            {
              Date: "గురువారము, 09 అక్టోబర్ 2025",
              Festival: "అట్లతద్ది",
            },
            {
              Date: "శుక్రవారము, 10 అక్టోబర్ 2025",
              Festival: "వక్రతుండా సంకష్టహర చతుర్థి, చిత్త కార్తె, కర్వా చౌత్",
            },
            {
              Date: "శుక్రవారము, 17 అక్టోబర్ 2025",
              Festival: "రమా ఏకాదశీ, కావేరీ తులా స్నానం, తులా సంక్రమణం",
            },
            {
              Date: "శనివారము, 18 అక్టోబర్ 2025",
              Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం, ధంతేరాస్",
            },
            {
              Date: "ఆదివారము, 19 అక్టోబర్ 2025",
              Festival: "ధన్వంతరి జయంతి, నరక చతుర్దశి, ధన త్రయోదశి, మాస శివరాత్రి",
            },
            {
              Date: "మంగళవారము, 21 అక్టోబర్ 2025",
              Festival: "దీపావళి, అమావాస్య, కేదార గౌరీ వ్రతం",
            },
            {
              Date: "బుధవారము, 22 అక్టోబర్ 2025",
              Festival: "ఆకాశ దీపం ప్రారంభం, చంద్రోదయం, గోవర్ధన పూజ",
            },
            {
              Date: "గురువారము, 23 అక్టోబర్ 2025",
              Festival: "యమ ద్వితీయ, భగినీ హస్త భోజనం",
            },
            {
              Date: "శుక్రవారము, 24 అక్టోబర్ 2025",
              Festival: "స్వాతి కార్తె",
            },
            {
              Date: "శనివారము, 25 అక్టోబర్ 2025",
              Festival: "నాగుల చవితి, చతుర్థి వ్రతం",
            },
            {
              Date: "సోమవారము, 27 అక్టోబర్ 2025",
              Festival: "సోమవార వ్రతం, స్కంద షష్ఠి, సూర్య షష్ఠి",
            },
            {
              Date: "బుధవారము, 29 అక్టోబర్ 2025",
              Festival: "బుద్ధ అష్టమి",
            },
            {
              Date: "గురువారము, 30 అక్టోబర్ 2025",
              Festival: "గోపాష్టమి, దుర్గా అష్టమి వ్రతం",
            },
          ],
        },
        {
          day: [
            {
              Date: "శనివారము, 01 నవంబర్ 2025",
              Festival: "దేవుత్తన ఏకాదశీ, ప్రబోధిని ఏకాదశి, కార్తిక శుద్ధ ఏకాదశి",
            },
            {
              Date: "ఆదివారము, 02 నవంబర్ 2025",
              Festival: "యోగేశ్వర ద్వాదశి, తులసి వివాహం, క్షీరాబ్ది ద్వాదశి, కైశిక ద్వాదశి, చాతుర్మాస్య వ్రత సమాప్తి",
            },
            {
              Date: "సోమవారము, 03 నవంబర్ 2025",
              Festival: "ప్రదోష్ వ్రతం, సోమా ప్రదోష వ్రతం, విశ్వేశ్వర వ్రతం",
            },
            {
              Date: "బుధవారము, 05 నవంబర్ 2025",
              Festival: "శ్రీ సత్యనారాయణ పూజ, పౌర్ణమి, శ్రీ ఉమా‌మ‍హేశ్వర వ్రతం, పౌర్ణమి వ్రతం, జ్వాలా తోరణం, కార్తీక పౌర్ణమి, గురునానక్ జయంతి",
            },
            {
              Date: "గురువారము, 06 నవంబర్ 2025",
              Festival: "విశాఖ కార్తే",
            },
            {
              Date: "శనివారము, 08 నవంబర్ 2025",
              Festival: "గణాధిప సంకష్టహర చతుర్థి, సౌభాగ్య సుందరి తీజ్",
            },
            {
              Date: "బుధవారము, 12 నవంబర్ 2025",
              Festival: "బుద్ధ అష్టమి",
            },
            {
              Date: "శుక్రవారము, 14 నవంబర్ 2025",
              Festival: "బాలల దినోత్సవం, నెహ్రూ జయంతి",
            },
            {
              Date: "శనివారము, 15 నవంబర్ 2025",
              Festival: "ఉత్పన్న ఏకాదశీ",
            },
            {
              Date: "ఆదివారము, 16 నవంబర్ 2025",
              Festival: "వృశ్చిక సంక్రమణం",
            },
            {
              Date: "సోమవారము, 17 నవంబర్ 2025",
              Festival: "శబరిమల మండల కలాం ఆరంభం, ప్రదోష్ వ్రతం, సోమా ప్రదోష వ్రతం",
            },
            {
              Date: "మంగళవారము, 18 నవంబర్ 2025",
              Festival: "మాస శివరాత్రి",
            },
            {
              Date: "గురువారము, 20 నవంబర్ 2025",
              Festival: "అనురాధ కార్తె, అమావాస్య",
            },
            {
              Date: "శుక్రవారము, 21 నవంబర్ 2025",
              Festival: "చంద్రోదయం",
            },
            {
              Date: "ఆదివారము, 23 నవంబర్ 2025",
              Festival: "శ్రీ సత్యసాయి బాబా జయంతి",
            },
            {
              Date: "సోమవారము, 24 నవంబర్ 2025",
              Festival: "చతుర్థి వ్రతం, సోమవార వ్రతం",
            },
            {
              Date: "బుధవారము, 26 నవంబర్ 2025",
              Festival: "సుబ్రహ్మణ్య షష్ఠి, స్కంద షష్ఠి",
            },
            {
              Date: "శుక్రవారము, 28 నవంబర్ 2025",
              Festival: "దుర్గా అష్టమి వ్రతం",
            }
          ],
        },
        {
          day: [
            {
              Date: "సోమవారము, 01 డిసెంబర్ 2025",
              Festival: "మొక్షద ఏకాదశీ, ప్రపంచ ఎయిడ్స్ దినోత్సవం, గీతా జయంతి",
            },
            {
              Date: "మంగళవారము, 02 డిసెంబర్ 2025",
              Festival: "మత్స్య ద్వాదశి, ప్రదోష్ వ్రతం",
            },
            {
              Date: "బుధవారము, 03 డిసెంబర్ 2025",
              Festival: "జ్యేష్ఠ కార్తె, శ్రీ హనుమద్ర్వతం",
            },
            {
              Date: "గురువారము, 04 డిసెంబర్ 2025",
              Festival: "దత్త జయంతి, పౌర్ణమి వ్రతం, శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి",
            },
            {
              Date: "ఆదివారము, 07 డిసెంబర్ 2025",
              Festival: "ఆఖురత సంకష్టహర చతుర్థి",
            },
            {
              Date: "శుక్రవారము, 12 డిసెంబర్ 2025",
              Festival: "బాలాజీ జయంతి",
            },
            {
              Date: "సోమవారము, 15 డిసెంబర్ 2025",
              Festival: "సఫల ఏకాదశీ, మూల కార్తె",
            },
            {
              Date: "మంగళవారము, 16 డిసెంబర్ 2025",
              Festival: "ధనుర్మాసం పూజ, ధనుస్సంక్రమణం",
            },
            {
              Date: "బుధవారము, 17 డిసెంబర్ 2025",
              Festival: "ప్రదోష్ వ్రతం",
            },
            {
              Date: "గురువారము, 18 డిసెంబర్ 2025",
              Festival: "మాస శివరాత్రి",
            },
            {
              Date: "శుక్రవారము, 19 డిసెంబర్ 2025",
              Festival: "అమావాస్య",
            },
            {
              Date: "ఆదివారము, 21 డిసెంబర్ 2025",
              Festival: "చంద్రోదయం",
            },
            {
              Date: "సోమవారము, 22 డిసెంబర్ 2025",
              Festival: "సోమవార వ్రతం",
            },
            {
              Date: "బుధవారము, 24 డిసెంబర్ 2025",
              Festival: "ప్రదోష్ వ్రతం, చతుర్థి వ్రతం, క్రిస్మస్ ఈవ్",
            },
            {
              Date: "గురువారము, 25 డిసెంబర్ 2025",
              Festival: "క్రిస్మస్",
            },
            {
              Date: "శుక్రవారము, 26 డిసెంబర్ 2025",
              Festival: "స్కంద షష్ఠి, బాక్సింగ్ డే",
            },
            {
              Date: "శనివారము, 27 డిసెంబర్ 2025",
              Festival: "మండల పూజ",
            },
            {
              Date: "ఆదివారము, 28 డిసెంబర్ 2025",
              Festival: "పూర్వాషాఢ కార్తె, దుర్గా అష్టమి వ్రతం",
            },
            {
              Date: "మంగళవారము, 30 డిసెంబర్ 2025",
              Festival: "పుష్యము పుత్రాద ఏకాదశీ, ముక్కోటి ఏకాదశి",
            },
            {
              Date: "బుధవారము, 31 డిసెంబర్ 2025",
              Festival: "వైష్ణవ పుష్యము పుత్రాద ఏకాదశీ, కుర్మా ద్వాదశి",
            },
          ],
        },
      ],
  ],
  GrahamUpdated: [
    [
      {
        Amav: "11 గురువారము",
        Chatur: "29 సోమవారము",
        Ekadas: "7 ఆదివారము, 21 ఆదివారము",
        Good: "6 శనివారము,7 ఆదివారము,20 శనివారము,21 ఆదివారము,25 గురువారము",
        Pournami: "25 గురువారము",
        Pradosh: "09 మంగళవారము, 23 మంగళవారము",
        Astami: "04 గురువారము, 18 గురువారము",
        Navami: "05 శుక్రవారము, 19 శుక్రవారము",
        data: {
          Amavasya: "11",
          Chaturthi: "29",
          Ekadasi: "7,21",
          Good: "6,7,20,21,25",
          Pradosham: "09,23",
          Purnima: "25",
          Astami: "04,18",
          Navami: "05,19",
        },
      },
      {
        Amav: "09 శుక్రవారము",
        Chatur: "28 బుధవారము",
        Ekadas: "06 మంగళవారము, 20 మంగళవారము",
        Good: "5 సోమవారము,6 మంగళవారము,14 బుధవారము,19 సోమవారము,20 మంగళవారము,24 శనివారము",
        Pournami: "24 శనివారము",
        Pradosh: "07 బుధవారము, 21 బుధవారము",
        Astami: "03 శనివారము, 17 శనివారము",
        Navami: "04 ఆదివారము, 18 ఆదివారము",
        data: {
          Amavasya: "09",
          Chaturthi: "28",
          Ekadasi: "06,20",
          Good: "5,6,14,19,20,24",
          Pradosham: "07,21",
          Purnima: "24",
          Astami: "03,17",
          Navami: "04,18",
        },
      },
      {
        Amav: "10 ఆదివారము",
        Chatur: "28 గురువారము",
        Ekadas: "06 బుధవారము, 20 బుధవారము",
        Good: "5 మంగళవారము,6 బుధవారము,19 మంగళవారము,20 బుధవారము,25 సోమవారము",
        Pournami: "25 సోమవారము",
        Pradosh: "08 శుక్రవారము, 22 శుక్రవారము",
        Astami: "04 సోమవారము, 17 ఆదివారము",
        Navami: "05 మంగళవారము, 18 సోమవారము",
        data: {
          Amavasya: "10",
          Chaturthi: "28",
          Ekadasi: "06,20",
          Good: "5,6,19,20,25",
          Pradosham: "08,22",
          Purnima: "25",
          Astami: "04,17",
          Navami: "05,18",
        },
      },
      {
        Amav: "08 సోమవారము",
        Chatur: "27 శనివారము",
        Ekadas: "05 శుక్రవారము, 19 శుక్రవారము",
        Good: "",
        Pournami: "23 మంగళవారము",
        Pradosh: "06 శనివారము, 21 ఆదివారము",
        Astami: "02 మంగళవారము, 16 మంగళవారము",
        Navami: "03 బుధవారము, 17 బుధవారము",
        data: {
          Amavasya: "08",
          Chaturthi: "27",
          Ekadasi: "05,19",
          Good: "5,6,7,12,14,19,21,23,27,28,29",
          Pradosham: "06,21",
          Purnima: "23",
          Astami: "02,16",
          Navami: "03,17",
        },
      },
      {
        Amav: "08 బుధవారము",
        Chatur: "26 ఆదివారము",
        Ekadas: "04 శనివారము, 19 ఆదివారము",
        Good: "",
        Pournami: "23 గురువారము",
        Pradosh: "05 ఆదివారము, 20 సోమవారము",
        Astami: "01 బుధవారము, 15 బుధవారము, 31 శుక్రవారము",
        Navami: "02 గురువారము, 16 గురువారము",
        data: {
          Amavasya: "08",
          Chaturthi: "26",
          Ekadasi: "04,19",
          Good: "4,5,6,11,13,19,20,23,26,27,29",
          Pradosham: "05,20",
          Purnima: "23",
          Astami: "01,15,31",
          Navami: "02,16",
        },
      },
      {
        Amav: "06 గురువారము",
        Chatur: "25 మంగళవారము",
        Ekadas: "02 ఆదివారము, 17 సోమవారము",
        Good: "",
        Pournami: "22 శనివారము",
        Pradosh: "04 మంగళవారము, 19 బుధవారము",
        Astami: "14 శుక్రవారము, 29 శనివారము",
        Navami: "01 శనివారము, 15 శనివారము, 30 ఆదివారము",
        data: {
          Amavasya: "06",
          Chaturthi: "25",
          Ekadasi: "02,17",
          Good: "4,5,10,12,17,19,22,25,27",
          Pradosham: "04,19",
          Purnima: "22",
          Astami: "14,29",
          Navami: "01,15,30",
        },
      },
      {
        Amav: "05 శుక్రవారము",
        Chatur: "24 బుధవారము",
        Ekadas: "02 మంగళవారము, 17 బుధవారము, 31 బుధవారము",
        Good: "",
        Pournami: "21 ఆదివారము",
        Pradosh: "03 బుధవారము, 19 శుక్రవారము",
        Astami: "14 ఆదివారము, 28 ఆదివారము",
        Navami: "15 సోమవారము, 29 సోమవారము",
        data: {
          Amavasya: "05",
          Chaturthi: "24",
          Ekadasi: "02,17,31",
          Good: "2,3,9,12,17,21,24,25,26,31",
          Pradosham: "03,19",
          Purnima: "21",
          Astami: "14,28",
          Navami: "15,29",
        },
      },
      {
        Amav: "04 ఆదివారము",
        Chatur: "22 గురువారము",
        Ekadas: "15 గురువారము, 29 గురువారము",
        Good: "",
        Pournami: "19 సోమవారము",
        Pradosh: "01 గురువారము, 17 శనివారము, 31 శనివారము",
        Astami: "12 సోమవారము, 27 మంగళవారము",
        Navami: "13 మంగళవారము, 27 మంగళవారము",
        data: {
          Amavasya: "04",
          Chaturthi: "22",
          Ekadasi: "15,29",
          Good: "1,2,8,10,15,17,19,22,23,24,29,31",
          Pradosham: "01,17,31",
          Purnima: "19",
          Astami: "12,27",
          Navami: "13,27",
        },
      },
      {
        Amav: "02 సోమవారము",
        Chatur: "21 శనివారము",
        Ekadas: "14 శనివారము, 28 శనివారము",
        Good: "",
        Pournami: "18 బుధవారము",
        Pradosh: "15 ఆదివారము, 29 ఆదివారము",
        Astami: "11 బుధవారము, 25 బుధవారము",
        Navami: "12 గురువారము, 26 గురువారము",
        data: {
          Amavasya: "02",
          Chaturthi: "21",
          Ekadasi: "14,28",
          Good: "7,9,14,15,18,21,23,28,29",
          Pradosham: "15,29",
          Purnima: "18",
          Astami: "11,25",
          Navami: "12,26",
        },
      },
      {
        Amav: "02 బుధవారము",
        Chatur: "20 ఆదివారము",
        Ekadas: "13 ఆదివారము, 28 సోమవారము",
        Good: "",
        Pournami: "17 గురువారము",
        Pradosh: "15 మంగళవారము, 29 మంగళవారము",
        Astami: "11 శుక్రవారము, 24 గురువారము",
        Navami: "11 శుక్రవారము, 25 శుక్రవారము",
        data: {
          Amavasya: "02",
          Chaturthi: "20",
          Ekadasi: "13,28",
          Good: "1,6,9,13,15,17,21,23,28,29,30",
          Pradosham: "15,29",
          Purnima: "17",
          Astami: "11,24",
          Navami: "12,25",
        },
      },
      {
        Amav: "01 శుక్రవారము",
        Chatur: "19 మంగళవారము",
        Ekadas: "12 మంగళవారము, 26 మంగళవారము",
        Good: "",
        Pournami: "15 శుక్రవారము",
        Pradosh: "13 బుధవారము, 28 గురువారము",
        Astami: "09 శనివారము, 23 శనివారము",
        Navami: "10 ఆదివారము, 24 ఆదివారము",
        data: {
          Amavasya: "01",
          Chaturthi: "19",
          Ekadasi: "12,26",
          Good: "3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,20,21,22,23,24,25,26,27,28,29",
          Pradosham: "13,28",
          Purnima: "15",
          Astami: "09,23",
          Navami: "10,24",
        },
      },
      {
        Amav: "01 ఆదివారము, 30 సోమవారము",
        Chatur: "18 బుధవారము",
        Ekadas: "11 బుధవారము, 26 గురువారము",
        Good: "",
        Pournami: "15 ఆదివారము",
        Pradosh: "13 శుక్రవారము, 28 శనివారము",
        Astami: "08 ఆదివారము, 23 సోమవారము",
        Navami: "9 సోమవారము, 24 మంగళవారము",
        data: {
          Amavasya: "01,30",
          Chaturthi: "18",
          Ekadasi: "11,26",
          Good: "2,3,4,5,6,7,8,9,10,11,12,13,14,15,17,18,19,20,21,22,23,24,25,26,27,28",
          Pradosham: "13,28",
          Purnima: "15",
          Astami: "08,23",
          Navami: "09,24",
        },
      },
    ],
    [
      {
        Amav: "29 బుధవారము",
        Chatur: "17 శుక్రవారము",
        Ekadas: "10 శుక్రవారము, 25 శనివారము",
        Good: "",
        Pournami: "13 సోమవారము",
        Pradosh: "11 శనివారము, 27 సోమవారము",
        Astami: "07 మంగళవారము, 22 బుధవారము",
        Navam: "08 బుధవారము, 23 గురువారము",
        data: {
          Amavasya: "29",
          Chaturthi: "17",
          Ekadasi: "10,25",
          Good: "13,21,28",
          Pradosham: "11,27",
          Purnima: "13",
          Astami: "07,22",
          Navami: "08,23",
        },
      },
      {
        Amav: "27 గురువారము",
        Chatur: "16 ఆదివారము",
        Ekadas: "08 శనివారము, 24 సోమవారము",
        Good: "",
        Pournami: "12 బుధవారము",
        Pradosh: "10 సోమవారము, 25 మంగళవారము",
        Astami: "05 బుధవారము, 21 శుక్రవారము",
        Navam: "06 గురువారము, 22 శనివారము",
        data: {
          Amavasya: "27",
          Chaturthi: "16",
          Ekadasi: "08,24",
          Good: "12,13,20,26,27",
          Pradosham: "10,25",
          Purnima: "12",
          Astami: "05,21",
          Navami: "06,22",
        },
      },
      {
        Amav: "29 శనివారము",
        Chatur: "17 సోమవారము",
        Ekadas: "10 సోమవారము, 25 మంగళవారము",
        Good: "",
        Pournami: "14 శుక్రవారము",
        Pradosh: "11 మంగళవారము, 27 గురువారము",
        Astami: "07 శుక్రవారము, 22 శనివారము",
        Navam: "08 శనివారము, 23 ఆదివారము",
        data: {
          Amavasya: "29",
          Chaturthi: "17",
          Ekadasi: "10,25",
          Good: "1,14,21,28",
          Pradosham: "11,27",
          Purnima: "14",
          Astami: "07,22",
          Navami: "08,23",
        },
      },
      {
        Amav: "27 ఆదివారము",
        Chatur: "16 బుధవారము",
        Ekadas: "08 మంగళవారము, 24 గురువారము",
        Good: "",
        Pournami: "12 శనివారము",
        Pradosh: "10 గురువారము, 25 శుక్రవారము",
        Astami: "05 శనివారము, 21 సోమవారము",
        Navam: "06 ఆదివారము, 22 మంగళవారము",
        data: {
          Amavasya: "27",
          Chaturthi: "16",
          Ekadasi: "08,24",
          Good: "2,4,10,12,13,16,19,26",
          Pradosham: "10,25",
          Purnima: "12",
          Astami: "05,21",
          Navami: "06,22",
        },
      },
      {
        Amav: " 27 మంగళవారము",
        Chatur: "16 శుక్రవారము",
        Ekadas: "08 గురువారము, 23 శుక్రవారము",
        Good: "",
        Pournami: "12 సోమవారము",
        Pradosh: "10 శనివారము, 24 శనివారము",
        Astami: "05 సోమవారము, 20 మంగళవారము",
        Navam: "06 మంగళవారము, 21 బుధవారము",
        data: {
          Amavasya: "27",
          Chaturthi: "16",
          Ekadasi: "08,23",
          Good: "3,12,13,19,25,26",
          Pradosham: "10,24",
          Purnima: "12",
          Astami: "05,20",
          Navami: "06,21",
        },
      },
      {
        Amav: "25 బుధవారము",
        Chatur: "14 శనివారము",
        Ekadas: "06 శుక్రవారము, 21 శనివారము",
        Good: "",
        Pournami: "11 బుధవారము",
        Pradosh: "08 ఆదివారము, 23 సోమవారము",
        Astami: "03 మంగళవారము, 19 గురువారము",
        Navam: "04 బుధవారము, 20 శుక్రవారము",
        data: {
          Amavasya: "25",
          Chaturthi: "14",
          Ekadasi: "06,21",
          Good: "10,11,17,24",
          Pradosham: "08,23",
          Purnima: "11",
          Astami: "03,19",
          Navami: "04,20",
        },
      },
      {
        Amav: "24 గురువారము",
        Chatur: "14 సోమవారము",
        Ekadas: "06 ఆదివారము, 21 సోమవారము",
        Good: "",
        Pournami: "10 గురువారము",
        Pradosh: "08 మంగళవారము, 22 మంగళవారము",
        Astami: "03 గురువారము, 18 శుక్రవారము",
        Navam: "04 శుక్రవారము, 19 శనివారము",
        data: {
          Amavasya: "24",
          Chaturthi: "14",
          Ekadasi: "06,21",
          Good: "10,16,24",
          Pradosham: "08,22",
          Purnima: "10",
          Astami: "03,18",
          Navami: "04,19",
        },
      },
      {
        Amav: " 23 శనివారము",
        Chatur: "12 మంగళవారము",
        Ekadas: "05 మంగళవారము, 19 మంగళవారము",
        Good: "",
        Pournami: "09 శనివారము",
        Pradosh: "06 బుధవారము, 20 బుధవారము",
        Astami: "02 శనివారము, 16 శనివారము, 31 ఆదివారము",
        Navam: "03 ఆదివారము, 17 ఆదివారము",
        data: {
          Amavasya: "23",
          Chaturthi: "12",
          Ekadasi: "05,19",
          Good: "8,9,11,12,15,19,23,31",
          Pradosham: "06,20",
          Purnima: "09",
          Astami: "02,16,31",
          Navami: "03,17",
        },
      },
      {
        Amav: "21 ఆదివారము",
        Chatur: "10 బుధవారము",
        Ekadas: "03 బుధవారము, 17 బుధవారము",
        Good: "",
        Pournami: "07 ఆదివారము",
        Pradosh: "05 శుక్రవారము, 19 శుక్రవారము",
        Astami: "14 ఆదివారము, 30 మంగళవారము",
        Navam: "01 సోమవారము, 15 సోమవారము",
        data: {
          Amavasya: "21",
          Chaturthi: "10",
          Ekadasi: "03,17",
          Good: "6,7,13,21,26",
          Pradosham: "05,19",
          Purnima: "07",
          Astami: "14,30",
          Navami: "01,15",
        },
      },
      {
        Amav: "21 మంగళవారము",
        Chatur: "10 శుక్రవారము",
        Ekadas: "03 శుక్రవారము, 17 శుక్రవారము",
        Good: "",
        Pournami: "07 మంగళవారము",
        Pradosh: "04 శనివారము, 18 శనివారము",
        Astami: "14 మంగళవారము, 30 గురువారము",
        Navam: "01 బుధవారము, 15 బుధవారము, 31 శుక్రవారము",
        data: {
          Amavasya: "21",
          Chaturthi: "10",
          Ekadasi: "03,17",
          Good: "9,10,24,25,28",
          Pradosham: "04,18",
          Purnima: "07",
          Astami: "14,30",
          Navami: "01,15,31",
        },
      },
      {
        Amav: "19 బుధవారము",
        Chatur: "08 శనివారము",
        Ekadas: "01 శనివారము, 15 శనివారము",
        Good: "",
        Pournami: "05 బుధవారము",
        Pradosh: "03 సోమవారము, 17 సోమవారము",
        Astami: "12 బుధవారము, 28 శుక్రవారము",
        Navam: "13 గురువారము, 29 శనివారము",
        data: {
          Amavasya: "19",
          Chaturthi: "08",
          Ekadasi: "01,15",
          Good: " - ",
          Pradosham: "03,17",
          Purnima: "05",
          Astami: "12,28",
          Navami: "13,29",
        },
      },
      {
        Amav: "19 శుక్రవారము",
        Chatur: "07 ఆదివారము",
        Ekadas: "01 సోమవారము, 15 సోమవారము, 30 మంగళవారము",
        Good: "",
        Pournami: "04 గురువారము",
        Pradosh: "02 మంగళవారము, 17 బుధవారము",
        Astami: "12 శుక్రవారము, 28 ఆదివారము",
        Navam: "13 శనివారము, 29 సోమవారము",
        data: {
          Amavasya: "19",
          Chaturthi: "07",
          Ekadasi: "01,15,30",
          Good: "7,8,22,23,26",
          Pradosham: "02,17",
          Purnima: "04",
          Astami: "12,28",
          Navami: "13,29",
        },
      },
    ],
  ],
  EventHolidayUpdated: [
    [
      {
        date: "2024-01-01",
        title: "ఆంగ్ల సంవత్సరాది",
      },
      {
        date: "2024-01-14",
        title: "భోగి",
      },
      {
        date: "2024-01-15",
        title: "మకర సంక్రాంతి",
      },
      {
        date: "2024-01-16",
        title: "కనుమ",
      },
      {
        date: "2024-01-26",
        title: "గణతంత్ర దినోత్సవం",
      },
      {
        date: "2024-03-08",
        title: "మహాశివరాత్రి",
      },
      {
        date: "2024-03-25",
        title: "హోళీ",
      },
      {
        date: "2024-03-29",
        title: "గుడ్ ఫ్రైడే",
      },
      {
        date: "2024-04-05",
        title: "బాబూ జగజ్జీవన్ రామ్ జయంతి",
      },
      {
        date: "2024-04-09",
        title: "శ్రీ క్రోధి నామ సంవత్సరం, ఉగాది",
      },
      {
        date: "2024-04-10",
        title: "రంజాన్, ఈద్ అల్ ఫితర్",
      },
      {
        date: "2024-04-14",
        title: "అంబేడ్కర్ జయంతి",
      },
      {
        date: "2024-04-17",
        title: "శ్రీరామ నవమి",
      },
      {
        date: "2024-05-01",
        title: "కార్మికుల దినోత్సవం",
      },
      {
        date: "2024-06-17",
        title: "బక్రీదు",
      },
      {
        date: "2024-07-17",
        title: "మొహర్రం",
      },
      {
        date: "2024-08-15",
        title: "భారత స్వాతంత్య్ర దినోత్సవం",
      },
      {
        date: "2024-08-19",
        title: "రక్షా బంధన్",
      },
      {
        date: "2024-08-26",
        title: "శ్రీ కృష్ణ జన్మాష్టమి",
      },
      {
        date: "2024-09-05",
        title: "ఉపాధ్యాయుల దినోత్సవం",
      },
      {
        date: "2024-09-07",
        title: "సిద్ధి వినాయక చవితి",
      },
      {
        date: "2024-09-16",
        title: "మిలాద్ ఉన్ నబీ",
      },
      {
        date: "2024-10-02",
        title: "గాంధీ జయంతి",
      },
      {
        date: "2024-10-11",
        title: "దుర్గాష్టమి",
      },
      {
        date: "2024-10-12",
        title: "మహా నవమి, విజయ దశమి",
      },
      {
        date: "2024-11-01",
        title: "దీపావళి",
      },
      {
        date: "2024-11-14",
        title: "నెహ్రూ జయంతి, బాలల దినోత్సవం",
      },
      {
        date: "2024-12-25",
        title: "క్రిస్టమస్",
      },
    ],
    [
      {
        date: "2025-01-01",
        title: "ఆంగ్ల సంవత్సరాది",
      },
      {
        date: "2025-01-13",
        title: "భోగి",
      },
      {
        date: "2025-01-14",
        title: "మకర సంక్రాంతి",
      },
      {
        date: "2025-01-15",
        title: "కనుమ",
      },
      {
        date: "2025-01-26",
        title: "గణతంత్ర దినోత్సవం",
      },
      {
        date: "2025-02-26",
        title: "మహాశివరాత్రి",
      },
      {
        date: "2025-03-08",
        title: "అంతర్జాతీయ మహిళా దినోత్సవం",
      },
      {
        date: "2025-03-14",
        title: "హోళీ",
      },
      {
        date: "2025-03-30",
        title: "ఉగాది, శ్రీ విశ్వావసు నామ సంవత్సరం",
      },
      {
        date: "2025-03-31",
        title: "రంజాన్, ఈద్ అల్ ఫితర్",
      },
      {
        date: "2025-04-05",
        title: "బాబూ జగజ్జీవన్ రామ్ జయంతి",
      },
      {
        date: "2025-04-06",
        title: "శ్రీరామ నవమి",
      },
      {
        date: "2025-04-14",
        title: "అంబేడ్కర్ జయంతి",
      },
      {
        date: "2025-04-18",
        title: "గుడ్ ఫ్రైడే",
      },
      {
        date: "2025-05-01",
        title: "కార్మికుల దినోత్సవం",
      },
      {
        date: "2025-06-07",
        title: "బక్రీద్",
      },
      {
        date: "2025-07-06",
        title: "మొహర్రం",
      },
      {
        date: "2025-08-09",
        title: "రక్షా బంధన్",
      },
      {
        date: "2025-08-15",
        title: "భారత స్వాతంత్య్ర దినోత్సవం",
      },
      {
        date: "2025-08-16",
        title: "శ్రీ కృష్ణ జన్మాష్టమి",
      },
      {
        date: "2025-08-27",
        title: "సిద్ధి వినాయక చవితి",
      },
      {
        date: "2025-09-05",
        title: "ఉపాధ్యాయుల దినోత్సవం, మిలాద్ ఉన్-నబీ",
      },
      {
        date: "2025-09-30",
        title: "దుర్గాష్టమి",
      },
      {
        date: "2025-10-01",
        title: "మహా నవమి",
      },
      {
        date: "2025-10-02",
        title: "విజయ దశమి, గాంధీ జయంతి",
      },
      {
        date: "2025-10-21",
        title: "దీపావళి",
      },
      {
        date: "2025-11-14",
        title: "నెహ్రూ జయంతి, బాలల దినోత్సవం",
      },
      {
        date: "2025-12-25",
        title: "క్రిస్టమస్",
      },
    ],
  ], 
 
  
}