import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Navbar from '../navbar';
import sideGridRightImage from '../../assets/images/sideGridRight.webp';
import Pandugavisistatha from '../bhagavathgita/pandugavisistatha';

const Pandugapramukyata = () => {
  const location = useLocation();
  const { festival } = location.state || {};

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (!festival) {
    return <div><Pandugavisistatha /></div>;
  }

  return (
    <>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              transform: 'rotateY(180deg)',
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3 center">
            <div className="row">
              <section>
                <div className="container">
                  <div className="row">
                    <div style={{ marginBottom: '35px' }}></div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 schedule-card-3">
                      <div className="carousel-item-content">
                        <h3>{festival.title}</h3>
                      </div>
                    </div>
                    <div style={{ marginBottom: '35px' }}></div>
                    {festival.CatID && Array.isArray(festival.CatID) && (
                      <div>
                        {festival.CatID.map((item, index) => (
                          <div key={index}>
                            <h6>{item.title}</h6>
                            <p>{item.content}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pandugapramukyata;



